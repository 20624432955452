import {RouteMeta, RouteLocationNormalized} from 'vue-router';
import Cookies from 'js-cookie';
import config from '@/config';
const {title, cookieExpires} = config;

export const TOKEN_KEY = 'token';

export const setToken = (token: string): void => {
    Cookies.set(TOKEN_KEY, token, {expires: cookieExpires || 1});
};

export const getToken = (): string => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) return token;
    else return '';
};

export const getEncrypt = (): string => {
    const token = Cookies.get('encrypt');
    if (token) return token;
    else return '';
};

export const showTitle = (item: RouteLocationNormalized): unknown => {
    const {title} = item.meta as RouteMeta;
    if (!title) return;
    return title;
};

export const getRouteTitleHandled = (route: RouteLocationNormalized): RouteLocationNormalized => {
    const router = {...route};
    const meta = {...route.meta};
    let title: unknown = '';
    if (meta.title) {
        if (typeof meta.title === 'function') {
            meta.__titleIsFunction__ = true;
            title = meta.title(router);
        } else title = meta.title;
    }
    meta.title = title;
    router.meta = meta;
    return router;
};

export const setTitle = (routeItem: RouteLocationNormalized): void => {
    const handledRoute = getRouteTitleHandled(routeItem);
    // const pageTitle = showTitle(handledRoute, vm)
    const pageTitle = showTitle(handledRoute);
    const resTitle = pageTitle ? `${title} - ${pageTitle}` : title;
    window.document.title = resTitle;
};

export const getPhoneVersions = () => {
    const u = navigator.userAgent;
    //alert(u);
    const up = navigator.userAgent.toLowerCase();
    return {
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
        xiaomi: up.indexOf('xiaomi') > -1, //小米
        huawei: up.indexOf('huawei') > -1, //华为
        oppo: up.indexOf('heytap') > -1, //OPPO
        vivo: up.indexOf('vivobrowser') > -1, //vivo
    };
};

export const captureVideoCover = (video: File) => {
    return new Promise((resolve, reject) => {
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(video);
        videoElement.currentTime = 1;
        videoElement.addEventListener('loadeddata', () => {
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            // @ts-ignore
            canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            const base64 = canvas.toDataURL('image/jpeg');
            resolve(base64);
        });
        videoElement.addEventListener('error', err => {
            reject(err);
        });
    });
};

/**
 * @description 将base64转换为文件
 * @param base64 base64字符串
 * @param filename 文件名
 * */
export const base64toFile = (base64: string, filename: string) => {
    const arr = base64.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
};

/**
 * @description 两点的经纬度距离计算,单位为米
 * */
export const calcDistance = (currentPoint: {lat: number; lng: number}, targetPoint: {lat: number; lng: number}) => {
    const EARTH_RADIUS = 6378137.0;
    const rad = (d: number) => {
        return (d * Math.PI) / 180.0;
    };
    const radLat1 = rad(currentPoint.lat);
    const radLat2 = rad(targetPoint.lat);
    const a = radLat1 - radLat2;
    const b = rad(currentPoint.lng) - rad(targetPoint.lng);
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    return s;
};

export const wechatUpload = () => {
    // @ts-ignore
    wx.chooseImage({
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res: any) {
            const localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
            console.log(localIds);
        },
    });
};

/** 时间格式 以毫秒为单位*/

export const format = (timestamp: number) => {
    const date = new Date(timestamp);
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';

    const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
};

/**计算显示时间 */

export const time2Stamp = (timestamp: string): string => {
    if (!timestamp) return '';

    function zeroize(num: number) {
        return (String(num).length == 1 ? '0' : '') + num;
    }
    const curTimestamp = parseInt((new Date().getTime() / 1000).toString()); //当前时间戳

    const timestampDiff = curTimestamp - Math.round(Number(timestamp) / 1000); // 参数时间戳与当前时间戳相差秒数

    const curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
    const tmDate = new Date(Number(timestamp)); // 参数时间戳转换成的日期对象

    const Y = tmDate.getFullYear(),
        m = tmDate.getMonth() + 1,
        d = tmDate.getDate();
    const H = tmDate.getHours(),
        i = tmDate.getMinutes(),
        s = tmDate.getSeconds();

    // while (timestampDiff > 1296000) {
    //     timestampDiff = timestampDiff - 1296000;
    // }
    // if (timestampDiff < 60) {
    //     // 一分钟以内
    //     return '刚刚';
    // } else if (timestampDiff < 3600) {
    //     // 一小时前之内
    //     return Math.floor(timestampDiff / 60) + '分钟前';
    // } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
    //     return zeroize(H) + ':' + zeroize(i);
    // } else {
    if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
        return formatTime(tmDate); //, 'yyyy-MM-dd hh:mm:ss');
    } else {
        const newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        const lastDate = new Date((curTimestamp - 86400 * 2) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
            return '昨天';
        } else if (lastDate.getFullYear() == Y && lastDate.getMonth() + 1 == m && lastDate.getDate() == d) {
            return '前天';
        } else if (timestampDiff < 345600) {
            return '3天前';
        } else if (timestampDiff < 432000) {
            return '4天前';
        } else if (timestampDiff < 518400) {
            return '5天前';
        } else if (timestampDiff < 604800) {
            return '6天前';
        } else if (timestampDiff < 1296000) {
            return '7天前';
        } else {
            // while (timestampDiff > 1296000) {
            //     timestampDiff = timestampDiff - 1296000;
            // }
            // return time2Stamp(timestampDiff);
            return '大于7天';
        }
        //  else if( timestampDiff < 2592000){
        //    return '15天前'
        //  }else {
        //    return '一个月前'
        //  }
    }
};

export const formatTime = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return (
        [year, formatNumber(month), formatNumber(day)].join('-') +
        ' ' +
        [formatNumber(hour), formatNumber(minute), formatNumber(second)].join(':')
    );
};
export const formatNumber = (n: number) => {
    let value = n.toString();
    value = value[1] ? n.toString() : '0' + n.toString();
    return value;
};
