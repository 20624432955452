import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {routers} from '@/router/routers';
import {setTitle} from '@/libs/utils';
import userStoreModule from '@/store/modules/user';
import config from '@/config';
import {login} from '@/api/miscellaneous';
import {Dialog} from 'vant';
import Cookies from 'js-cookie';

const routes: Array<RouteRecordRaw> = routers;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

/**
 * 路由守卫鉴权页面
 * */
router.beforeEach(async (to, from, next) => {
    setTitle(to);
    /**
     * TODO 微信登录拦截去登录 未实现
     * */
    if (to.meta.isLogin) {
        console.log(from, to, '---from --to--isLogin');
        if (userStoreModule.isLogin) {
            if (userStoreModule.isGetUserInfo) {
                next();
            } else {
                await userStoreModule.getUserInfo();
                next();
            }
        } else {
            console.log(from, to, '---from --to -nologin');
            if (to.query.code) {
                const res = await login({code: to.query.code as string});
                const {token, encrypt, webOpenId} = res;
                userStoreModule.setToken(token);
                userStoreModule.setEncrypt(encrypt);
                userStoreModule.setOpend(webOpenId);
                await userStoreModule.getUserInfo();
                // if (RouteAuthentication.includes(to.name as string)) {
                delete to.query.code;
                // }
                next(to);
            } else {
                // if (RouteAuthentication.includes(to.name as string)) {
                //9xGUjRLdA0enpM706zPREA
                const query = Object.values(to.query).join('split');
                const redirectUri = encodeURIComponent(`${config.webHost}${to.path}`);
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.wechat.appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${query}#wechat_redirect`;
                // } else {
                //     next();
                // }
            }
            next();
        }
    } else {
        // next({path: '/ogin', query: {redirect: to.fullPath}});
        next();
        // next();
    }
});

router.afterEach(to => {
    /*if (to.meta.isSignIn && !Cookies.get('isSignIn')) {
        Dialog.alert({
            title: '签到',
            message: '签到最少可获取5，最多可获取30个苗豆',
            closeOnClickOverlay: true,
        }).then(() => {
            // on confirm
            router.push({name: 'CheckIn'});
        });
    }*/
    wx.updateTimelineShareData({
        // title: '苗搭档-求购列表', // 分享标题
        title: `苗搭档-${to.meta.title}`, // 分享标题
        link: `${config.webHost}${to.path}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: 'https://mdd-static.oss-cn-hangzhou.aliyuncs.com/app/h5/seek_logo_new.png', // 分享图标
        success: function () {
            // 设置成功
        },
    });
    wx.updateAppMessageShareData({
        // title: '苗搭档-求购列表', // 分享标题
        title: `苗搭档-${to.meta.title}`, // 分享标题
        desc: '【海量求购】良心推荐，优质求购信息，大人速来批阅！', // 分享描述
        link: `${config.webHost}${to.path}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: 'https://mdd-static.oss-cn-hangzhou.aliyuncs.com/app/h5/seek_logo_new.png', // 分享图标
        success: function () {
            // 设置成功
        },
    });
    // LoadingBar.finish()
    window.scrollTo(0, 0);
});

export default router;
