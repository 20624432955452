
import {Options, Vue} from 'vue-class-component';
import {getPhoneVersions} from '@/libs/utils';
import {getWechatSDKSign} from '@/api/miscellaneous';
import {Dialog} from 'vant';

@Options({
    name: 't',
})
export default class t extends Vue {
    private pageType = 'weapp';
    private pagePath = 'pages/tabBar/index/index';
    private webSystem: any;

    public created(): void {
        //window.location.href = `miaodadang://itemDetail?id=6995574702471918592`;
        this.webSystem = getPhoneVersions();
        //alert(this.webSystem.vivo);

        let pageType = this.$route.query.pageType;
        this.pagePath = (this.$route.query.url as string) || `pages/tabBar/index/index`;
        this.pageType = (pageType as string) || 'weapp';
        //alert(this.pageType);
        if (this.pageType == 'weapp') {
            this.initWechatJSSDK();
        } else if (this.pageType == 'openApp') {
            this.jumpToApp();
        } else {
            this.openApp();
        }
    }

    // public mounted(): void {}
    private initWechatJSSDK(): void {
        getWechatSDKSign({url: location.href.split('#')[0]}).then(res => {
            const {appId, timestamp, nonceStr, signature} = res;
            wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature, // 必填，签名
                jsApiList: ['chooseImage', 'previewImage'], // 必填，需要使用的 JS 接口列表
                openTagList: ['wx-open-launch-weapp'], // 可选，需要使用的开放标签列表
            });
        });
    }

    private jumpToApp(): void {
        if (this.webSystem.weixin) {
            //微信浏览器
            this.pageType = 'weapp';
            this.initWechatJSSDK();
            this.$toast({message: '检测为微信浏览器，3秒后将自动跳转小程序', duration: 3500});
        } else 
        if (this.webSystem.ios) {
            //外部浏览器苹果
            window.location.href = 'https://apps.apple.com/cn/app/%E8%8B%97%E6%90%AD%E6%A1%A3/id1481798461?uo=4';
        } else if (this.webSystem.oppo) {
            //外部OPPO,跳OPPO应用市场
            this.$toast({message: 'oppo', duration: 3500});
            window.location.href = 'oppomarket://details?packagename=io.dcloud.UNIF9C091A';
        } else if (this.webSystem.xiaomi) {
            this.$toast({message: 'xiaomi', duration: 3500});
            window.location.href = 'mimarket://details?id=io.dcloud.UNIF9C091A';
        } else if (this.webSystem.huawei) {
            this.$toast({message: 'huawei', duration: 3500});
            window.location.href = 'appmarket://details?id=io.dcloud.UNIF9C091A';
        } else if (this.webSystem.vivo) {
            this.$toast({message: 'vivo', duration: 3500});
            window.location.href = 'vivomarket://details?id=io.dcloud.UNIF9C091A';
        } else {
            //应用宝
            window.location.href =
                'https://a.app.qq.com/o/simple.jsp?pkgname=io.dcloud.UNIF9C091A&channel=0002160650432d595942&fromcase=60001';
        }
    }

    private openApp() {
        let {url, query} = this.$route.query;
        const timer = setTimeout(() => {
            // alert('请先安装秒搭党APP');
            Dialog.alert({
                title: '提示',
                message: '请先安装苗搭档APP',
            }).then(() => {
                this.jumpToApp();
            });
        }, 2500);
        window.location.href = `miaodadang://${url}?${query}`;

        const visibilitychange = function () {
            //@ts-ignore
            const tag = document.hidden || document.webkitHidden;
            tag && clearTimeout(timer);
        };

        document.addEventListener('visibilitychange', visibilitychange, false);
        document.addEventListener('webkitvisibilitychange', visibilitychange, false);
        window.addEventListener(
            'pagehide',
            function () {
                clearTimeout(timer);
            },
            false,
        );
    }
}
