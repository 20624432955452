import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "CityPicker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanPicker = _resolveComponent("VanPicker")!
  const _component_VanPopup = _resolveComponent("VanPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VanPopup, {
      show: $props.modelValue,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($props.modelValue) = $event)),
      position: "bottom",
      onClose: $setup.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VanPicker, {
          title: "标题",
          columns: $setup.cityData,
          "columns-field-names": {text:"label"},
          onConfirm: $setup.confirm
        }, null, 8, ["columns"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}