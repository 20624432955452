import axios, { AxiosPromise, AxiosRequestConfig, AxiosInstance } from "axios";

// import store from '@/store'
// import CryptoJS from 'crypto-js'
// import Config from '@/config'
// import {Message} from 'view-design';
import Schema, { ValidateError } from "async-validator";
// import {dataDecrypted} from '@/libs/utils';
import { Dialog, Toast } from "vant";
import { ToastWrapperInstance } from "vant/lib/toast/types";
import { getEncrypt, getToken } from "@/libs/utils";
import { getWechatAppUrl } from "@/api/miscellaneous";
import Cookies from "js-cookie";
import SeedlingBeanNotEnough from "@/components/SeedlingBeanNotEnough";
import router from "@/router";
import config from "@/config";
import { useRoute } from "vue-router";

// const KEY = CryptoJS.enc.Utf8.parse(Config.dataOffset)

// const addErrorLog = errorInfo => {
//     const {statusText, status, request: {responseURL}} = errorInfo
//     let info = {
//         type: 'ajax',
//         code: status,
//         mes: statusText,
//         url: responseURL
//     }
//     if (!responseURL.includes('save_error_logger')) store.dispatch('addErrorLog', info)
// }

interface HttpRequestConfig {
  baseURL: string;
  headers?: any;
}

interface Options extends AxiosRequestConfig {
  debug?: boolean;
  validator?: Schema;
  isShowLoading?: boolean;
}

class HttpRequest {
  private baseUrl: string;
  private queue: string[] = [];
  private loading: ToastWrapperInstance | null = null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getInsideConfig(): HttpRequestConfig {
    const config: HttpRequestConfig = {
      baseURL: this.baseUrl,
      headers: {
        authorization: getToken() || "",
        source: "h5",
        encrypt: getEncrypt() || "",
      },
    };
    return config;
  }

  destroy(url: string): void {
    const index = this.queue.indexOf(url);
    if (index > -1) {
      this.queue.splice(index, 1);
    }
    if (!this.queue.length) {
      this.loading && this.loading.clear();
      this.loading = null;
    }
  }

  interceptors(instance: AxiosInstance, url: string, options: Options): void {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 添加全局的loading...
        options.isShowLoading =
          options.isShowLoading === undefined ? true : options.isShowLoading;
        if (!Object.keys(this.queue).length && options.isShowLoading) {
          // Spin.show() // 不建议开启，因为界面不友好
          this.queue.push(options.url || "");
          // this.loading = Toast.loading({
          //     message: '正在加载数据...',
          // });
        }

        const sty = `color:#fff;background:#19be6b;border-radius:2px;padding:2px 5px`;
        if (options.debug)
          console.log("%c" + "axios---Config", sty, { ...options, ...{} });
        // this.queue[url] = true;

        return config;
      },
      <Err>(error: Err) => {
        this.destroy(options.url as string);
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res: any) => {
        options.isShowLoading =
          options.isShowLoading === undefined ? true : options.isShowLoading;
        if (options.isShowLoading) {
          this.loading?.clear();
        }
        if (
          [
            `v2/oss/ocr/signature`,
            `v2/oss/ocr/status`,
            `v2/oss/ocr/signature/video/m3u8`,
          ].includes(res.config.url)
        ) {
          return Promise.resolve(res.data);
        }
        this.destroy(options.url as string);

        const { data, code } = res.data;
        if (code === 0) {
          return Promise.resolve(data);
        } else {
          if (code == 401) {
            Cookies.remove("token");
            Cookies.remove("encrypt");

            // Dialog.confirm({
            //     title: '提示',
            //     message: '登录已过期，请重新登录',
            //     closeOnClickOverlay: false,
            //     showCancelButton: false,
            // }).then(() => {
            const query = Object.values(
              Object.fromEntries(
                window.location.search
                  .replace("?", "")
                  .split("&")
                  .map((item) => item.split("="))
              )
            ).join("split");
            console.log(`${window.location}`, query, "-----------------url");
            const redirectUri = encodeURIComponent(
              `${config.webHost}${window.location.pathname}`
            );
            console.log(redirectUri, "-----red");
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.wechat.appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${query}#wechat_redirect`;
            // });

            return;
          }
          if (![104, 105, 120, 121, 107, 410,403].includes(code)) {
            Toast.fail(res.data.message);
          }
          if (code === 410) {
            this.loading?.clear();
            //@ts-ignore
            SeedlingBeanNotEnough.show();
          }
          return Promise.reject(res.data);
        }
      },
      (error: any) => {
        this.destroy(url);
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url },
          };
        }
        switch (errorInfo.data.code) {
          case 403:
            break;
          default:
            Toast.fail(errorInfo.data.message);
            break;
        }
        
        return Promise.reject(error);
      }
    );
  }

  request(options: Options) {
    return new Promise((resolve) => {
      const instance = axios.create();
      options = Object.assign(this.getInsideConfig(), options);

      this.interceptors(instance, options.url as string, options);
      if (options.validator) {
        options.validator.validate(
          options.data,
          (errors: ValidateError[] | null) => {
            if (errors) {
              // Message.error(errors[0].message);
              Toast(errors[0].message);
              return;
            } else {
              return resolve(instance(options));
            }
          }
        );
      } else {
        return resolve(instance(options));
      }
    });
  }
}

export default HttpRequest;
