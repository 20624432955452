
import { Options, Vue } from 'vue-class-component';
import { getProductDetail, getRecommendList, getContact, login, getCommonList, getIsFollow, getHasCard } from '@/api/miscellaneous';
import { Toast } from 'vant';
import moment from 'moment';
import config from '@/config';
import { calcDistance } from '@/libs/utils';
import userStoreModule from '@/store/modules/user';
import RestrictDialog from '@/components/RestrictDialog.vue';
import ShopHeader from '@/components/ShopHeader.vue';
import VipTag from '@/components/VipTag.vue';
import ListItemAd from '@/components/ListItemAd.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

@Options({
    name: 'BuyDetail',
    components: { RestrictDialog, ShopHeader, VipTag, ListItemAd },
})
export default class BuyDetail extends Vue {
    private detail: any = {};
    private restrictShow = false;
    private restrictCode = '';
    private tel = '';
    private isShowTel = false;
    private isShowOpen = false;
    private loading = false;
    private finished = false;

    private isRealName = false;

    private IsBaojia = false;

    private isNotFollow = false;

    private getListData = {
        page: 1,
        size: 10,
        keyword: '',
    };
    private route = useRoute();
    private list = <any>[];

    mounted(): void {
        let { id, state } = this.$route.query;
        this.getDetail((state || id) as string);
        this.isFollow();
        this.getHasCard();
    }

    private getHasCard = () => {
        getHasCard().then(res => {
            this.isRealName = !res;
        });
    }

    private search = () => {
        //refresh();
    };

    private isFollow() {
        const userId = userStoreModule.userDetail.user.id;
        getIsFollow(userId).then((res: any) => {
            this.isNotFollow = !res.subscripte;
        });
    }

    private distance = (point: { lat: number; lon: number }) => {
        if (userStoreModule.userDetail.location) {
            let { lat, lon } = userStoreModule.userDetail.location;
            const dis = calcDistance({ lat: point.lat, lng: point.lon }, { lat, lng: lon });
            return dis > 1000 ? (dis / 1000).toFixed(2) + 'km' : dis.toFixed(2) + 'm';
        } else {
            return '';
        }
    };

    private login(): void {
        let { code, id, state } = this.$route.query;
        if (!code) {
            /**
             * 微信登录Code授权
             * 重定向页面还是这个页面，通过$roter.query.code 判断是否是重定向回来的
             * */
            console.log(window.location.pathname, '-------pathname');
            const redirectUri = encodeURIComponent(`${config.webHost}${window.location.pathname}`);
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.wechat.appid
                }&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${id || 'STATE'}#wechat_redirect`;
        } else {
            login({ code: code as string }).then(res => {
                let { token, encrypt } = res;
                userStoreModule.setToken(token);
                userStoreModule.setEncrypt(encrypt);
                this.getDetail((state || id) as string);
            });
        }
    }

    private getDetail(itemid?: string): void {
        let id = itemid || (this.$route.query.id as string) || '';
        getProductDetail({ id }).then(res => {
            this.detail = res;
            this.getListData.keyword = res.categoryName;
            this.getList();
        });
    }

    private async getList(): Promise<void> {
        getCommonList(this.getListData).then(res => {
            // this.getListData.page++;
            // this.loading = false;
            // this.finished = this.getListData.page * this.getListData.size >= res.totalCount;
            // this.list = [...this.list, ...res.items];
        });
        const res: any = await getCommonList(this.getListData);
        this.getListData.page++;
        this.loading = false;
        this.finished = this.getListData.page * this.getListData.size >= res.totalCount;
        this.list = [...this.list, ...res.items];
    }
    private getRecommendList(categoryId: string): void {
        getRecommendList({ categoryId }).then(res => {
            this.list = res.items;
        });
    }
    private likemore() {
        this.$router.push({ name: 'WantToBuy', query: { keyword: this.detail.categoryName, categoryId: this.detail?.categoryId } });
    }
    private more() {
        this.$router.push({ name: 'WantToBuy' });
    }

    private refresh(item: any): void {
        this.$router.push({ name: 'BuyDetail', query: { id: item.id } });
        this.getDetail(item.id);
    }

    private showRestrictDialog(): void {
        getHasCard().then(res => {
            this.isRealName = !res;
            if (!res) {
                this.isRealName = true;
                return;
            } else {
                this.getContact();
            }
        });
        
    }

    private toQuotedPrice(): void {
        getHasCard().then(res => {
            this.isRealName = !res;
            if (!res) {
                this.isRealName = true;
                return;
            } else {
                if (this.detail.state === 4) Toast('');
                else {
                    this.$router.push({ name: 'QuotedPrice', query: { id: this.$route.query.id } });
                }
            }
        });

    }

    private getContact(): void {
        let id = (this.$route.query.id as string) || '';
        getContact({ id })
            .then(res => {
                this.tel = res;
                this.isShowTel = true;
            })
            .catch(e => {
                let { code } = e;
                if ([104, 107].includes(code)) {
                    this.restrictCode = code;
                    this.restrictShow = true;
                } else if (code == 779) {
                    this.IsBaojia = true;
                }
            });
    }
    private test(): void {
        this.tel = '19858743131';
        this.isShowTel = true;
    }
    private cancel(): void {
        this.tel = '';
        this.isShowTel = false;
    }

    private cancelRealName(): void {
        this.isRealName = false;
    }

    private gotoRealName(): void {
        this.$router.push({ name: 'RealName', query: { id: this.$route.query.id, detailType: 'single', back: 'true' } });
    }

    private cancelBaoJia(): void {
        this.IsBaojia = false;
    }

    private gotoBaoJia(): void {
        if (this.detail.state === 4) Toast('');
        else {
            this.$router.push({ name: 'QuotedPrice', query: { id: this.$route.query.id, detailType: 'single', back: 'true' } });
        }
    }

    private call(): void {
        window.location.href = 'tel:' + this.tel;
    }

    private timeToNow(time: string): string {
        return moment(time).fromNow();
    }
    private time2Stamp(timestamp: string) {
        if (!timestamp) return '';

        function zeroize(num: number) {
            return (String(num).length == 1 ? '0' : '') + num;
        }
        const curTimestamp = parseInt((new Date().getTime() / 1000).toString()); //当前时间戳

        let timestampDiff = curTimestamp - Math.round(Number(timestamp) / 1000); // 参数时间戳与当前时间戳相差秒数

        const curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
        const tmDate = new Date(Number(timestamp)); // 参数时间戳转换成的日期对象

        const Y = tmDate.getFullYear(),
            m = tmDate.getMonth() + 1,
            d = tmDate.getDate();
        const H = tmDate.getHours(),
            i = tmDate.getMinutes(),
            s = tmDate.getSeconds();

        while (timestampDiff > 1296000) {
            timestampDiff = timestampDiff - 1296000;
        }
        if (timestampDiff < 60) {
            // 一分钟以内
            return '刚刚';
        } else if (timestampDiff < 3600) {
            // 一小时前之内
            return Math.floor(timestampDiff / 60) + '分钟前';
        } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
            return zeroize(H) + ':' + zeroize(i);
        } else {
            const newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
            const lastDate = new Date((curTimestamp - 86400 * 2) * 1000); // 参数中的时间戳加一天转换成的日期对象
            if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
                return '昨天';
            } else if (lastDate.getFullYear() == Y && lastDate.getMonth() + 1 == m && lastDate.getDate() == d) {
                return '前天';
            } else if (timestampDiff < 345600) {
                return '3天前';
            } else if (timestampDiff < 432000) {
                return '4天前';
            } else if (timestampDiff < 518400) {
                return '5天前';
            } else if (timestampDiff < 604800) {
                return '6天前';
            } else if (timestampDiff < 1296000) {
                return '7天前';
            } else {
                return '大于7天';
            }
        }
    }
}
