
import {Image as VanImage} from 'vant/lib/image';
import {Popup as VanPopup} from 'vant/lib/popup';
import {Button as VanButton} from 'vant/lib/button';
import router from '@/router';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'SeedlingBeanNotEnough',
    components: {VanImage, VanPopup, VanButton},
    data() {
        return {
            isShow: false,
        };
    },
    setup() {
        const jumpToWeCaht = async () => {
            window.location.href = window.location.origin + '/RechargeSeedlingBean';
        };
        const jumpToWeCahtVip = async () => {
            window.location.href =
                window.location.origin +
                `/OpenMembership?vipfrom=${encodeURIComponent((router.currentRoute.value.meta.title || '未知页面') + ',苗豆余额不足')}`;
        };
        return {
            jumpToWeCaht,
            jumpToWeCahtVip,
        };
    },
});
