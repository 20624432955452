import {Module, VuexModule, Mutation, getModule, Action} from 'vuex-module-decorators';
import store from '@/store';
import {getEncrypt, getToken, setToken, TOKEN_KEY} from '@/libs/utils';
import Cookies from 'js-cookie';
import {getUserInfo} from '@/api/miscellaneous';
import BindingPhone from '@/components/bindingPhone';

@Module({name: 'user', store, dynamic: true})
class User extends VuexModule {
    public isLogin = !!getToken();
    public token = getToken();
    public encrypt = getEncrypt();
    public openId = localStorage.getItem('openid') || '';
    public userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    public userDetail: any = {};
    public isGetUserInfo = false;
    @Mutation
    setToken(token: string) {
        setToken(token);
        this.token = token;
        this.isLogin = true;
    }

    @Mutation
    setEncrypt(encrypt: string) {
        Cookies.set('encrypt', encrypt);
        this.encrypt = encrypt;
    }
    @Mutation
    setOpend(openid: string) {
        localStorage.setItem('openid', openid);
        this.openId = openid;
    }
    @Mutation
    setUserInfo(data: any) {
        this.userInfo = data;
    }
    @Mutation
    setUserDetail(data: any) {
        this.userDetail = data;
        this.isGetUserInfo = true;
    }

    @Action({rawError: true})
    getUserInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            getUserInfo()
                .then((res: any) => {
                    this.context.commit('setUserDetail', res);
                    localStorage.setItem('userDetail', JSON.stringify(res));
                    if (!res.user.mobile) {
                        BindingPhone.show();
                    }
                    // localStorage.setItem('openid', JSON.stringify(res.data));
                    resolve(res);
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }
}
const userStoreModule = getModule(User);
export default userStoreModule;
