import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cd53de0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "Agreement"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (Object.keys($setup.detail).length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "content",
          innerHTML: $setup.detail.headlineContents
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}