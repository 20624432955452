import {getUploadVideoSign, videoTranscoding} from '@/api/miscellaneous';
import axios from 'axios';
import {Toast} from 'vant';
import {fetchFile, createFFmpeg} from '@ffmpeg/ffmpeg';

export const uploadVideo = async (data: {type: string; obj: any}): Promise<any> => {
    const {name} = data.obj.file;
    const a_suffix = name.split('.');
    const ExtName = a_suffix[a_suffix.length - 1];

    const signData: any = await getUploadVideoSign({type: 'supply'});
    const url = `https://${signData.host}`;
    const formData = new FormData();
    const fileName = `${signData.key}${signData.randomId}.${ExtName}`;
    formData.append('policy', signData.policy);
    formData.append('OSSAccessKeyId', signData.accessid);
    formData.append('signature', signData.signature);
    formData.append('success_action_status', '200');
    formData.append('key', fileName);
    formData.append('file', data.obj.file);

    const uploadRes = await axios.post(url, formData, {
        withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data;charset=utf-8',
        },
    });
    if (uploadRes.status === 200) {
        await videoTranscoding({uploadPath: fileName, host: signData.host, fileName: signData.randomId, ExtName, Type: 'supplies'});
        return {
            url: `${signData.key}${signData.randomId}.m3u8`,
        };
    } else {
        Toast.fail('视频上传失败');
    }
};
