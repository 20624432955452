import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ebdb4fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vipTag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanImage = _resolveComponent("VanImage")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VanImage, {
      src: $setup.props.type == 3 ? require("@/assets/img/silver.png") : $setup.props.type == 6 ? require("@/assets/img/glod.png") : require("@/assets/img/crown.png")
    }, null, 8, ["src"]),
    _createElementVNode("span", {
      class: "vipTime",
      style: _normalizeStyle({borderColor: $setup.props.type == 6 ? "#F4D8B1" : "#8c05bc", color: $setup.props.type == 6 ? "#C27E2D" : "#8c05bc"})
    }, _toDisplayString($setup.props.year) + "年", 5)
  ], 512)), [
    [_vShow, $setup.props.type != 1 ]
  ])
}