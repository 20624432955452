type Province =
    | '全国'
    | '北京'
    | '天津省'
    | '河北省'
    | '山西省'
    | '内蒙古自治区'
    | '辽宁省'
    | '吉林省'
    | '黑龙江省'
    | '上海市'
    | '江苏省'
    | '浙江省'
    | '安徽省'
    | '福建省'
    | '江西省'
    | '山东省'
    | '河南省'
    | '湖北省'
    | '湖南省'
    | '广东省'
    | '广西壮族自治区'
    | '海南省'
    | '重庆市'
    | '四川省'
    | '贵州省'
    | '云南省'
    | '西藏自治区'
    | '陕西省'
    | '甘肃省'
    | '青海省'
    | '宁夏回族自治区'
    | '新疆维吾尔自治区'
    | '台湾省'
    | '香港特别行政区'
    | '澳门特别行政区';

export interface AreaProvinceItem {
    text: Province;
    id: string;
}

const areaProvince: AreaProvinceItem[] = [
    {text: '全国', id: '0'},
    {text: '北京', id: '6550688903064453120'},
    {text: '天津省', id: '6550688903068647440'},
    {text: '河北省', id: '6550688903072841744'},
    {text: '山西省', id: '6550688903106396175'},
    {text: '内蒙古自治区', id: '6550688903131561986'},
    {text: '辽宁省', id: '6550688903148339201'},
    {text: '吉林省', id: '6550688903165116422'},
    {text: '黑龙江省', id: '6550688903177699332'},
    {text: '上海市', id: '6550688903198670859'},
    {text: '江苏省', id: '6550688903202865152'},
    {text: '浙江省', id: '6550688903219642380'},
    {text: '安徽省', id: '6550688903236419598'},
    {text: '福建省', id: '6550688903257391130'},
    {text: '江西省', id: '6550688903274168334'},
    {text: '山东省', id: '6550688903295139840'},
    {text: '河南省', id: '6550688903324499975'},
    {text: '湖北省', id: '6550688903349665802'},
    {text: '湖南省', id: '6550688903366443032'},
    {text: '广东省', id: '6550688903387414539'},
    {text: '广西壮族自治区', id: '6550688903408386054'},
    {text: '海南省', id: '6550688903425163281'},
    {text: '重庆市', id: '6550688903433551881'},
    {text: '四川省', id: '6550688903441940493'},
    {text: '贵州省', id: '6550688903475494916'},
    {text: '云南省', id: '6550688903488077837'},
    {text: '西藏自治区', id: '6550688903509049364'},
    {text: '陕西省', id: '6550688903521632271'},
    {text: '甘肃省', id: '6550688903538409483'},
    {text: '青海省', id: '6550688903550992413'},
    {text: '宁夏回族自治区', id: '6550688903563575296'},
    {text: '新疆维吾尔自治区', id: '6550688903567769604'},
    {text: '台湾省', id: '6550688903584546835'},
    {text: '香港特别行政区', id: '6550688903592935442'},
    {text: '澳门特别行政区', id: '6550688903592935445'},
];

export default areaProvince;
