import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanUploader = _resolveComponent("VanUploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.isWeChat)
      ? (_openBlock(), _createBlock(_component_VanUploader, _normalizeProps(_mergeProps({ key: 0 }, $setup.props)), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 16))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "upload",
          onClick: $setup.choseImage
        }, [
          _renderSlot(_ctx.$slots, "default")
        ]))
  ]))
}