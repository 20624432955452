import axios from '@/libs/api.request';
import http from 'axios';
import {Toast} from 'vant';

export const getOssSignature = (data: {count: number; type: string}): Promise<any> => {
    return axios.request({
        url: 'v2/oss/ocr/signature',
        // url: 'login/code',
        // url: 'login/code',
        method: 'GET',
        params: data,
        debug: false,
        isShowLoading: false,
    });
};
export const updateImg = (data: any[]): Promise<any> => {
    return axios.request({
        url: 'v2/oss/ocr/status',
        method: 'post',
        data,
        debug: false,
        isShowLoading: false,
    });
};

export const upload = async (data: {type: string; obj: any}) => {
    try {
        const res = await getOssSignature({count: 1, type: data.type});
        const {host, policy, accessid, signature, dir} = res;
        const url = `https://${host}`;
        const formData = new FormData();
        formData.append('policy', policy);
        formData.append('OSSAccessKeyId', accessid);
        formData.append('signature', signature);
        formData.append('success_action_status', '200');

        formData.append('key', dir[0]);
        formData.append('file', data.obj.file);
        const {status} = await http.post(url, formData, {
            withCredentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (status === 200) {
            const imgStatus = await updateImg([dir[0]]);
            if (imgStatus[0] && imgStatus[0].errors.length == 0) {
                return {
                    img: data.obj.content,
                    url: imgStatus[0].key,
                };
            } else {
                throw imgStatus[0] ? imgStatus[0].errors[0] : '图片上传失败';
            }
        }
    } catch (e) {
        // Toast.fail('图片上传失败');
        // @ts-ignore
        Toast.fail(e);
        throw e;
    }
};
// /oss/ocr/signature
