
import {getShopDetail, getUserDetail} from '@/api/miscellaneous';
import {useRouter, useRoute} from 'vue-router';
import MyHeader from '@/components/MyHeader.vue';
import {Options, Vue} from 'vue-class-component';
import {reactive, watch, ref} from 'vue';

@Options({
    components: {
        MyHeader,
    },
})
export default class ThenStore extends Vue {
    private detail: any = {};
    private router = useRouter();
    private route = useRoute();
    private id = ref();
    private userInfo = {};
    private isShowPotential = false;
    private isShowOpen = false;
    mounted(): void {
        this.getShopData();
    }
    activated(): void {
        this.getShopData();
    }

    private getShopData = () => {
        getShopDetail({id: this.route.query?.id as string}).then(res => {
            this.detail = res;
        });
        getUserDetail().then((res: any) => {
            //res.avatarUrl = config.imgHost + '/' + res.avatarUrl;
            this.userInfo = res;
        });
    };

    private showProspect = (): void => {
        this.isShowPotential = true;
    };
    private sharefriend = (): void => {
        this.isShowOpen = true;
    };
    private toedit = (): void => {
        this.router.push({
            name: 'EditShop',
            query: {id: this.detail?.id},
        });
    };
}
