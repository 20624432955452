import BindingPhone from './bindingPhone.vue';
import {createApp, h, getCurrentInstance, nextTick} from 'vue';

// interface SeedingBeanNotEnough {
//     show: (options: any) => void;
// }
// @ts-ignore
BindingPhone.newInstance = (properties: any) => {
    const _props = properties || {};

    // @ts-ignore

    const container = document.createElement('div');
    document.body.appendChild(container);
    // @ts-ignore
    let _instance = null;
    const Instance = createApp({
        data() {
            return {
                isShow: false,
            };
        },
        render() {
            return h(BindingPhone, {
                isShow: this.isShow,
                ref: 'modal',
            });
        },
        beforeUnmount() {
            Instance.unmount();
            document.body.removeChild(container);
            // this.onRemove();
        },
        created() {
            _instance = getCurrentInstance();
        },
    });
    Instance.mount(container);
    // @ts-ignore

    return {
        show() {
            // modal.isShow = true;

            nextTick(() => {
                // @ts-ignore
                const modal = _instance.refs.modal;
                modal.isShow = true;
            });
        },
    };
};

let modalInstance: unknown;

function getModalInstance() {
    modalInstance =
        modalInstance ||
        // @ts-ignore
        BindingPhone.newInstance({
            // closable: false,
            // maskClosable: false,
            // footerHide: true,
            // // render: render,
            // lockScroll,
        });

    return modalInstance;
}

function confirm(options: any) {
    // const render = 'render' in options ? options.render : undefined;
    // const lockScroll = 'lockScroll' in options ? options.lockScroll : true;
    const instance = getModalInstance();
    // @ts-ignore
    instance.show(options);
}
// @ts-ignore
BindingPhone.show = confirm;

export default BindingPhone;
