import Schema, {RuleItem, Rules} from 'async-validator';

type Rule = RuleItem | RuleItem[];

interface CheckDataItem {
    name: string;
    rule: Rule;
}

type Field = string | undefined;

class Validator<CheckData> {
    private readonly rule: Rules = {};
    private fieldsName: Partial<Record<keyof CheckData, string>> = {};

    constructor(data: Record<keyof CheckData, CheckDataItem>) {
        const handleData = Object.entries<CheckDataItem>(data).map(([key, rule]) => {
            this.fieldsName[key as keyof CheckData] = rule.name;
            return [key, rule.rule];
        });
        this.rule = Object.fromEntries(handleData);
    }

    public validator(): Schema {
        const schema = new Schema(this.rule);
        this.messageConvertChinese(schema);
        return schema;
    }

    private getField(str: Field): string {
        if (!str) return `未知字段`;
        const fields = str.split('.'),
            field = fields[0];
        const fieldsArr = str.split('.'),
            name = this.fieldsName[field as keyof CheckData];
        fieldsArr.splice(0, 1);
        const fieldAfterString = fieldsArr.map((el: string) => (isNaN(Number(el)) ? `的${el}` : `的第${Number(el) + 1}（条/个）`)).join('');
        return name + fieldAfterString;
    }

    private messageConvertChinese(schema: Schema): void {
        const typesToString = (...arg: [Field, string | undefined]): string => {
            const [field, type] = arg,
                name = this.getField(field);
            return `${name}的值错误：类型不是${type}`;
            return ``;
        };
        schema.messages({
            required: (...age) => {
                const [field] = age,
                    name = this.getField(field);
                return `${name}是必要字段`;
            },
            string: {
                len: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度必须是${len}位`;
                },
                min: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度不能小于${len}位`;
                },
                max: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度不能大于${len}位`;
                },
            },
            number: {
                len: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的值错误：值必须是${len}`;
                },
                min: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的值错误：值不能小于${len}`;
                },
                max: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的值错误：值不能大于${len}`;
                },
                range: (...age) => {
                    const [field, min, max] = age,
                        name = this.getField(field);
                    return `${name}的值错误：值只能在${min}~${max}之间`;
                },
            },
            array: {
                len: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度必须是${len}`;
                },
                min: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度不能小于${len}`;
                },
                max: (...age) => {
                    const [field, len] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度不能大于${len}`;
                },
                range: (...age) => {
                    const [field, min, max] = age,
                        name = this.getField(field);
                    return `${name}的长度错误：长度只能在${min}~${max}之间`;
                },
            },
            // pattern: {
            //     mismatch: (...age) => {
            //         return ``;
            //     },
            // },
            types: {
                string: typesToString,
                number: typesToString,
                array: typesToString,
                object: typesToString,
                method: typesToString,
                date: typesToString,
                boolean: typesToString,
                integer: typesToString,
                float: typesToString,
                regexp: typesToString,
                email: typesToString,
                url: typesToString,
                hex: typesToString,
            },
        });
    }
}

// interface TestData {
//     str: string;
//     num?: number | string;
//     arr?: any[];
//     obj?: {[key: string]: any};
// }
//
// const vali = new Validator<TestData>({
//     str: {
//         name: '字符',
//         rule: {
//             type: 'string',
//             required: true,
//         },
//     },
//     num: {
//         name: '数值',
//         rule: {
//             type: 'number',
//             required: true,
//             min: 3,
//             max: 5,
//         },
//     },
//     arr: {
//         name: '数组',
//         rule: {
//             type: 'array',
//             required: true,
//             max: 2,
//             min: 1,
//             defaultField: {
//                 type: 'number',
//             },
//         },
//     },
//     obj: {
//         name: '键值对',
//         rule: {
//             type: 'object',
//             required: true,
//             fields: {
//                 test: {
//                     type: 'number',
//                 },
//                 test2: {
//                     type: 'object',
//                     fields: {
//                         test21: {
//                             type: 'number',
//                             required: true,
//                         },
//                     },
//                 },
//             },
//         },
//     },
// });
//
// const testData: TestData = {
//     str: 'string',
//     num: 4,
//     arr: [4, 4],
//     obj: {test: 1, test2: {test21: 21}},
// };
//
// vali.validator().validate(testData, (err, values) => {
//     console.log(err, values);
// });

export default Validator;
