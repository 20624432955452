
import {Field as VanField, Toast} from 'vant';
import {Popup as VanPopup} from 'vant/lib/popup';
import {Button as VanButton} from 'vant/lib/button';
import {bindingPhone, sendBindingCode} from '@/api/miscellaneous';
import {ref, defineComponent} from 'vue';
import userStoreModule from '@/store/modules/user';

export default defineComponent({
    name: 'BindingPhone',
    components: {VanField, VanPopup, VanButton},
    data() {
        return {
            isShow: false,
            timer: 60,
            data: {
                mobile: '',
                code: '',
            },
        };
    },

    methods: {
        sendCode() {
            if (this.data.mobile == '') {
                Toast('请输入手机号');
                return;
            }
            sendBindingCode({mobile: this.data.mobile}).then(res => {
                Toast.success('发送成功');
                const countDown = () => {
                    if (this.timer > 0) {
                        this.timer--;
                        setTimeout(countDown, 1000);
                    } else {
                        this.timer = 60;
                    }
                };
                countDown();
            });
        },
        bind() {
            bindingPhone(this.data).then(res => {
                userStoreModule.setToken(res as string);
                userStoreModule.getUserInfo();
                Toast.success('绑定成功');
                this.isShow = false;
            });
        },
    },
});
