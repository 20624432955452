import {ref} from 'vue';
import {getSupplyDetail, getSpecsById} from '@/api/miscellaneous';
export const editHook = async (id: string) => {
    const detail = ref();

    const getDetail = async () => {
        const res = await getSupplyDetail({id});
        detail.value = res;
    };
    id && (await getDetail());
    const {minPrice, maxPrice, photos, profile, quality, quantity, title, categoryId} = detail.value;
    let lists: any[],
        photo: string[] = [];
    const attributeObject = await getSpecsById({id: categoryId});
    const handleImages = () => {
        lists = [
            {
                attributeObject,
                name: title,
                imgList: photos.map((el: {value: any}) => ({img: el.value})),
            },
        ];
        photo = photos.map((el: {key: any}) => el.key);
    };

    handleImages();
    const data = {minPrice, maxPrice, photos: photo, profile, quality: String(quality), quantity: Number(quantity), title, categoryId};

    return {
        detail,
        data,
        // @ts-ignore
        lists,
    };
};
