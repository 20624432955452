import axios from '@/libs/api.request';

export const getTodayWantToBuyOfPush = (data: {page: number; size: number}): Promise<any> => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: 'v2/procurement/purchases/today/purchase/list',
        method: 'GET',
        params: {
            from,
            size,
        },
        debug: false,
    });
};
export const getTodayProcessingOfPush = (data: {page: number; size: number}): Promise<any> => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: 'v2/procurement/purchases/today/process/list',
        method: 'GET',
        params: {
            from,
            size,
        },
        debug: false,
    });
};
