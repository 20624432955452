import axios from '@/libs/api.request';
import {
    publishProcessingValidator,
    publishSingleDataValidator,
    quotedPriceParams,
    ReleaseEngineer,
    releaseEngineerValidator,
    releaseWantToBuyValidator,
    supplyData,
} from '@/api/miscellaneous/validate';
import {SupplyData, PurchasesData} from '@/views/ReleaseSupply/type';
import userStoreModule from '@/store/modules/user';
import {getEncrypt, getToken} from '@/libs/utils';

interface IDCardRequest{
    name:string;
    idcard:string;
}

export const getHasCard = (): Promise<any> => {
    return axios.request({
        url: `/v2/procurement/purchases/hascard`,
        method: 'GET',
        debug: false,
    });
};
/**
 * @description: 实名认证(文字信息)
 * @param {object} params
 * @return {*}
 */
export const postIdCardInfo = (data:IDCardRequest) : Promise<any>  => {
    return axios.request({
        url: 'v2/seedling/ocr/idcard',
        method: 'POST',
        data,
        debug: false,
    });
};

export const getShoInfo = (): Promise<any> => {
    return axios.request({
        url: '/v2/seedling/my/information/update',
        method: 'GET',
        params: {},
        debug: true,
    });
};

export const getWechatSDKSign = (data: {url: string}): Promise<any> => {
    return axios.request({
        url: 'v1/subscripte/wechat/sign',
        method: 'GET',
        params: data,
        debug: false,
    });
};

export const getTestWechatSDKSign = (data: {url: string}): Promise<any> => {
    return axios.request({
        url: 'http://127.0.0.1:3000/config',
        method: 'GET',
        params: data,
        debug: false,
    });
};

export const login = (data: {code: string}): Promise<any> => {
    console.log(data,'---data');
    return axios.request({
        url: 'v1/subscripte/login/code',
        // url: 'login/code',
        // url: 'login/code',
        method: 'GET',
        params: data,
        debug: false,
        isShowLoading: false,
    });
};

export const getSetting = (data: {token: string}): Promise<any> => {
    return axios.request({
        url: 'v1/subscripte/setting',
        // url: 'setting',
        // url: 'setting',
        // params: data,
        method: 'GET',
        debug: false,
    });
};

interface SettingData {
    mobile: string;
    nickName: string;
    receivePurchase: string;
    receiveProcess: string;
    categoryTitle: string[];
    regional: string[];
    token: string;
}

export const setting = (data: SettingData): Promise<any> => {
    return axios.request({
        url: 'v1/subscripte/setting',
        method: 'PUT',
        data,
        debug: false,
    });
};

interface RegisterData {
    mobile: string;
    nickName: string;
    webOpenId: string;
    encrypt: string;
    receivePurchase: string;
    categoryTitle: string[];
    regional: string[];
}

export const register = (data: RegisterData): Promise<any> => {
    return axios.request({
        url: 'v1/subscripte/login/bind/mobile',
        method: 'POST',
        data,
        debug: false,
    });
};

interface getProductData {
    page: number;
    size: number;
    lat: number;
    lon: number;
    areaId: string;
    keyword: string;
    categoryId: string;
    specifications: string;
}

export const getProduct = (data: getProductData): Promise<any> => {
    const from = (data.page - 1) * data.size;
    return axios.request({
        url: 'v2/procurement/purchases/search',
        method: 'GET',
        params: {...data, ...{from}},
        debug: false,
    });
};
export const getProductDetail = (data: {id: string}): Promise<any> => {
    return axios.request({
        // url: 'v2/procurement/purchases/search',
        url: `/v2/procurement/purchases/quotation/${data.id}/order`,
        method: 'GET',
        debug: false,
    });
};
export const getRecommendList = (data: {categoryId: string}): Promise<any> => {
    return axios.request({
        url: `/v2/procurement/purchases/search`,
        method: 'GET',
        params: {
            from: 0,
            size: 15,
            categoryId: data.categoryId,
        },
        debug: false,
    });
};
export const ckeckedVariety = (data: {name: string}): Promise<any> => {
    return axios.request({
        url: `/v1/subscripte/setting/SelectCategories`,
        method: 'GET',
        params: data,
        debug: false,
    });
};

interface GetDealWithList {
    page: number;
    size: number;
    areaId?: string;
    keyword?: string;
    form?: number;
    categoryId?: string;
}
export const getDealWithList = (data: GetDealWithList): Promise<any> => {
    const from = (data.page - 1) * data.size;
    return axios.request({
        url: `/v2/seedling/supplies/process/search`,
        method: 'GET',
        params: {...data, ...{from}},
        debug: false,
    });
};

export const getDealWithDetail = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `/v2/seedling/supplies/shows/process/${data.id}`,
        method: 'GET',
        // params: {...data, ...{from}},
        debug: false,
    });
};

export const getPermissionData = (): Promise<any> => {
    return axios.request({
        url: `/v2/seedling/json/object`,
        method: 'GET',
        params: {jsonUrl: 'https://mdd-static.oss-cn-hangzhou.aliyuncs.com/phone_limit_modal_data.json'},
        debug: false,
    });
};

export const getQuotedPriceDetail = (data: {id: string}): Promise<any> => {
    return axios.request({
        // url: `/v2/procurement/projects/quotation/${data.id}`,
        url: `/v2/procurement/purchases/quotation/${data.id}/order`,
        method: 'GET',
        debug: false,
    });
};
export const getContact = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `v2/procurement/purchases/${data.id}/contact`,
        method: 'POST',
        debug: true,
    });
};
export const getUserContact = (data: {userid: string}): Promise<any> => {
    return axios.request({
        url: `v2/procurement/purchases/contact`,
        method: 'POST',
        data,
        debug: false,
    });
};
export const getProcessingContact = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `v2/seedling/supplies/process/${data.id}/contact`,
        method: 'POST',
        debug: false,
    });
};
export const getEngineerContact = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `v2//procurement/projects/${data.id}/contact`,
        method: 'POST',
        debug: false,
    });
};

export const dealWithConcat = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `/v2/seedling/supplies/process/${data.id}/contact`,
        method: 'POST',
        debug: true,
    });
};

export interface QuotedPriceData {
    profile: string;
    price: string;
    photos: string[];
    payTerm: string;
    areaId: string;
    address: string;
    categoryId: string;
    location: number[];
    quantity: string;
    video: string[];
}

export const quotedPrice = (id: string, data: QuotedPriceData): Promise<any> => {
    return axios.request({
        url: `/v2/procurement/purchases/quotation/${id}/creat`,
        method: 'POST',
        data,
        debug: false,
        validator: quotedPriceParams.validator(),
    });
};
export interface GetWechatAppUrl {
    url?: string;
    query?: string;
}

export const getWechatAppUrl = (data: GetWechatAppUrl): Promise<any> => {
    return axios.request({
        url: `/v1/subscripte/setting/GetUrlSecheme`,
        method: 'GET',
        params: data,
        debug: false,
    });
};
export const getLocation = (): Promise<any> => {
    return axios.request({
        url: `https://restapi.amap.com/v3/ip`,
        method: 'GET',
        params: {key: '406dd52be837c909e2aca21bfc19ff6d'},
        debug: false,
    });
};
export const getQuotedPriceList = (data: {id: string; purchaseid: string}): Promise<any> => {
    return axios.request({
        url: `v1/subscripte/purchases/pullpurchase`,
        method: 'GET',
        params: data,
        debug: false,
    });
};
export const getEngineeringDetail = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `v2/procurement/projects/${data.id}`,
        method: 'GET',
        debug: false,
    });
};
export const getEngineeringCategory = (data: {id: string}): Promise<any> => {
    return axios.request({
        url: `v2/procurement/quotation/project/${data.id}/category`,
        method: 'GET',
        debug: false,
    });
};

export interface EngineerRecommendList {
    id: string;
    page: number;
    size: number;
    categoryId: string;
}
export const getEngineerRecommendList = (data: EngineerRecommendList): Promise<any> => {
    return axios.request({
        url: `v2/procurement/projects/quotation/${data.id}/orders`,
        method: 'GET',
        params: {
            from: (data.page - 1) * data.size,
            size: data.size,
            categoryId: data.categoryId,
        },
        debug: false,
    });
};
export const getEngineerList = (data: {page: number; size: number}): Promise<any> => {
    return axios.request({
        url: `v2/procurement/projects/search`,
        method: 'GET',
        params: {
            from: (data.page - 1) * data.size,
            size: data.size,
            keyword: '',
        },
        debug: false,
    });
};

export const textParse = (data: {keywords: string}) => {
    return axios.request({
        url: `v2/seedling/categories/purchase/parse`,
        params: data,
        method: 'GET',
        debug: false,
    });
};

export const getDetailImage = (data: {id: string}) => {
    return axios.request({
        url: `v2/procurement/purchases/${data.id}/suppliers`,
        method: 'GET',
        debug: false,
    });
};
export const searchCategories = (data: {name: string; pageSize: number; pageNum: number}) => {
    const {name, pageNum, pageSize: size} = data;
    const params = {
        name,
        from: (pageNum - 1) * size,
        size,
        isParent: 0,
    };
    return axios.request({
        url: `v2/seedling/categories/search`,
        params,
        method: 'GET',
        debug: false,
    });
};
export const getUploadVideoSign = (data: {type: string}) => {
    return axios.request({
        url: `v2/oss/ocr/signature/video/m3u8`,
        params: data,
        method: 'GET',
        debug: false,
        isShowLoading: false,
    });
};

export const getShortUrlSecheme = () => {
    return axios.request({
        url: `v1/subscripte/setting/GetShortUrlSecheme`,
        method: 'GET',
        debug: false,
    });
};

interface PurchasesReport {
    supply: boolean;
    single: boolean;
    reportTerm: string;
    content: string;
    photos: string[];
}
export const purchasesReport = (data: PurchasesReport) => {
    return axios.request({
        url: `v2/procurement/purchases/reports`,
        method: 'POST',
        data,
        debug: false,
    });
};
export const getReportType = () => {
    return axios.request({
        url: `v2/procurement/purchases/report_term`,
        method: 'GET',
        debug: false,
    });
};

export const publishSupply = (data: SupplyData) => {
    return axios.request({
        url: `v2/seedling/supplies/create`,
        method: 'POST',
        data,
        validator: supplyData.validator(),
        debug: false,
    });
};

export const updateSupply = (data: SupplyData, id: string) => {
    return axios.request({
        url: `v2/seedling/supplies/${id}/update`,
        method: 'PUT',
        data,
        validator: supplyData.validator(),
        debug: false,
    });
};

export const getUserInfo = () => {
    return axios.request({
        url: `v2/seedling/my/information`,
        method: 'GET',
        debug: false,
        isShowLoading: false,
    });
};
export const getVarietisList = (data: {page: number; size: number; id: string}) => {
    const {id, page, size} = data;
    const params = {
        from: (page - 1) * size,
        size,
        id,
    };
    return axios.request({
        url: `v2/seedling/categories/search/category`,
        method: 'GET',
        params,
        debug: false,
    });
};
export const getHotVarietisList = () => {
    return axios.request({
        url: `v2/seedling/categories/commonly`,
        method: 'GET',
        debug: false,
    });
};

export const getIsFollow = (userId: string) => {
    return axios.request({
        url: `v1/subscripte/setting/${userId}/get`,
        method: 'GET',
        debug: false,
    });
};

export const getPushProcessingList = (data: {id: string; purchaseid: string}) => {
    return axios.request({
        url: `v1/subscripte/purchases/pullprocess`,
        method: 'GET',
        params: data,
        debug: false,
    });
};
export const getPushProcessingDetail = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/supplies/shows/process/${data.id}`,
        method: 'GET',
        debug: false,
    });
};
export const getPushProcessingImage = (data: {id: string}) => {
    return axios.request({
        url: `v2/procurement/purchases/process/${data.id}/shop`,
        method: 'GET',
        debug: false,
    });
};
export const getSeedlingBeanNum = () => {
    return axios.request({
        url: `v2/seedling/my/integral`,
        method: 'GET',
        headers: {
            SourceType: 'PointRecharge',
            authorization: getToken() || '',
            source: 'h5',
            encrypt: getEncrypt() || '',
        },
        debug: false,
    });
};
export const getSeedlingBeanList = () => {
    return axios.request({
        url: `v2/seedling/json/array`,
        method: 'GET',
        params: {
            jsonUrl: 'https://mdd-static.oss-cn-hangzhou.aliyuncs.com/recharges.json',
        },
        debug: false,
    });
};
export const getSeedlingBeanRecharge = (data: {points: number; price: number}) => {
    return axios.request({
        url: `v2/payment/point/recharge`,
        method: 'POST',
        data,
        debug: false,
    });
};

export const WechatSeedlingBeanRecharge = (data: {id: string}) => {
    return axios.request({
        url: `v2/payment/point/recharge/${data.id}/pay/wechat/mp`,
        method: 'POST',
        params: {
            openid: userStoreModule.openId,
        },
        debug: false,
    });
};

export const getVipList = () => {
    return axios.request({
        url: `v2/seedling/json/array`,
        method: 'GET',
        headers: {
            SourceType: 'VipRecharge',
            authorization: getToken() || '',
            source: 'h5',
            encrypt: getEncrypt() || '',
        },
        params: {
            jsonUrl: 'https://mdd-static.oss-cn-hangzhou.aliyuncs.com/Members.json',
        },
        debug: false,
    });
};

export const WechatVipPay = (data: any) => {
    return axios.request({
        url: `v2/payment/vip/orders`,
        method: 'POST',
        data,
        debug: false,
    });
};
export const VipPay = (data: any) => {
    return axios.request({
        url: `v2/payment/vip/orders/${data.id}/pay/wechat/mp`,
        method: 'POST',
        headers: {
            authorization: getToken() || '',
            source: 'h5',
            encrypt: getEncrypt() || '',
        },
        params: {
            openid: userStoreModule.openId,
        },
        debug: false,
    });
};

interface GetSupplyListParams {
    page: number;
    size: number;
    lat: number;
    lon: number;
    keyword: string;
    areaId: string;
    categoryId: string;
}
export const getSupplyList = (data: GetSupplyListParams) => {
    console.log(data,'-----data');
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/supplies/search`,
        method: 'GET',
        params: {...data, ...{from}},
        debug: true,
    });
};
export const getSpecsById = (data: {id: string}) => {
    return axios.request({
        url: `v2//seedling/categories/${data.id}/attributes`,
        method: 'GET',
        debug: false,
    });
};
export const getSupplyDetail = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/supplies/shows/${data.id}`,
        method: 'GET',
        debug: false,
    });
};
export const getSupplyTel = (data: {id: string}) => {
    return axios.request({
        url: `v2//seedling/supplies/${data.id}/contact`,
        method: 'POST',
        debug: false,
    });
};
export const getMineAdd = () => {
    return axios.request({
        url: `v2/advertisement/page/seedling/region`,
        method: 'GET',
        params: {productId: 0, keywords: '广告位用勿动_求购is我的页中间'},
        debug: false,
    });
};
export const getListAdd = (data: {keywords: string}) => {
    return axios.request({
        url: `v2/advertisement/page/seedling/region`,
        method: 'GET',
        params: {productId: 2, keywords: data.keywords},
        debug: false,
    });
};

export const getIsFollowShop = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/shops/${data.id}/followed`,
        method: 'GET',
        debug: false,
    });
};
export const getShopDetail = (data: {id: string}) => {
    return axios.request({
        url: `v2//seedling/shops/shows/${data.id}`,
        method: 'GET',
        debug: false,
    });
};
export const getShopVarieties = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/shops/supplies/${data.id}`,
        method: 'GET',
        params: {quality: 0},
        debug: false,
    });
};

interface GetShopSupplyList {
    id: string;
    page: number;
    size: number;
    categoryId: string;
    quality: number;
    upper: boolean;
}

export const getShopSupplyList = (data: GetShopSupplyList) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2//seedling/supplies/other/${data.id}`,
        method: 'GET',
        params: {
            ...data,
            ...{from},
        },
        debug: false,
    });
};
export const getShopTel = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/shops/${data.id}/contact`,
        method: 'POST',
        debug: false,
    });
};
export const buyTextParse = (data: {keywords: string}) => {
    return axios.request({
        url: `v2/seedling/categories/purchase/parse`,
        method: 'GET',
        params: data,
        debug: false,
    });
};

export const getSupplyHotList = () => {
    return axios.request({
        url: `v2/seedling/supplies/area/top/category`,
        method: 'GET',
        debug: false,
    });
};
export const getUserDetail = () => {
    return axios.request({
        url: `v2/seedling/my/details`,
        method: 'GET',
        debug: true,
    });
};
export const getWantToBuyHotList = () => {
    return axios.request({
        url: `v2/procurement/purchases/area/top/category`,
        method: 'GET',
        debug: false,
    });
};
export const searchVarieties = (data: {name: string; page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/categories/search/home`,
        method: 'GET',
        params: {...data, ...{from}},
        debug: false,
    });
};
export const publishSingle = (data: PurchasesData) => {
    return axios.request({
        url: `v2/procurement/purchases/create`,
        method: 'POST',
        data,
        validator: publishSingleDataValidator.validator(),
        debug: false,
    });
};
export const sendBindingCode = (data: {mobile: string}) => {
    return axios.request({
        url: `v2/seedling/code/mobile`,
        method: 'POST',
        data: {
            ...data,
            ...{
                verification: 'bind_code',
            },
        },
        debug: false,
    });
};

export const bindingPhone = (data: {mobile: string; code: string}) => {
    return axios.request({
        url: `v2/seedling/login/mobile/bind`,
        method: 'PUT',
        data,
        debug: false,
    });
};
export const getMyWantToBuyList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/procurement/purchases/my`,
        method: 'GET',
        params: {...data, ...{from}},
        debug: false,
    });
};

export const getMyOfferPriceList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/procurement/purchases/quotation/snapshot/own`,
        method: 'GET',
        params: {...data, ...{from}},
        debug: false,
    });
};

export const getMyReceivedPriceList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/procurement/purchases/quotations/received`,
        method: 'GET',
        params: {...data, ...{from}},
        debug: false,
    });
};
export const getQuotationOfWantToBuyList = (data: {page: number; size: number; id: string}) => {
    const {page, size, id} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/procurement/purchases/${id}/quotations`,
        method: 'GET',
        params: {from, id, size},
        debug: false,
    });
};
export const getAdBannerList = () => {
    return axios.request({
        url: `v2/procurement/purchases/my/suppliers`,
        method: 'GET',
        debug: false,
    });
};
export const adoptQuotation = (data: {id: string}) => {
    return axios.request({
        url: `v2/procurement/purchases/quotation/${data.id}/accept`,
        method: 'PUT',
        debug: false,
    });
};
export const refuseQuotation = (data: {id: string}) => {
    return axios.request({
        url: `v2/procurement/purchases/quotation/${data.id}/reject`,
        method: 'PUT',
        debug: false,
    });
};
export const getQuotationTel = (data: {id: string}) => {
    return axios.request({
        url: `v2/procurement/quotation/purchase/${data.id}/contact`,
        method: 'POST',
        debug: false,
    });
};
export const getRelatedPurchaseList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/recommend/products`,
        method: 'GET',
        params: {
            ...data,
            ...{from},
        },
        debug: false,
    });
};
export const getRelatedProcessList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/recommend/supply/process`,
        method: 'GET',
        params: {
            ...data,
            ...{from},
        },
        debug: false,
    });
};
export const getMyQuoteList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/procurement/seller/quotation`,
        method: 'GET',
        params: {
            ...data,
            ...{from},
        },
        debug: false,
    });
};
export const getBanner = () => {
    return axios.request({
        url: `v2/advertisement/carousel/home/seedling/up`,
        method: 'GET',
        params: {
            systemos: 'android',
        },
        debug: false,
    });
};
export const getNoticeList = () => {
    return axios.request({
        url: `v2/advertisement/broadcast/home/pages`,
        method: 'GET',
        params: {from: 0, size: 20},
        debug: false,
    });
};
export const getPopularRecommen = () => {
    return axios.request({
        url: `v2/advertisement/top/supply`,
        method: 'GET',
        params: {from: 0, size: 20},
        debug: false,
    });
};
export const getHomeCategory = () => {
    return axios.request({
        url: `v2/seedling/categories/commonly/home`,
        method: 'GET',
        params: {from: 0, size: 20},
        debug: false,
    });
};
export const getHomeList = (data: {page: number; size: number; keyword: string}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/supplies/search`,
        method: 'GET',
        params: {
            ...data,
            ...{from, upper: true},
        },
        debug: false,
    });
};
export const getCommonList = (data: {page: number; size: number; keyword: string}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/supplies/commonsearch`,
        method: 'GET',
        params: {
            ...data,
            ...{from, upper: true, vipRank: 1, opt: '>'},
        },
        debug: false,
    });
};
export const getMySupplyList = (data: {page: number; size: number; categoryId: string}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/supplies/my`,
        method: 'GET',
        params: {
            ...data,
            ...{from, upper: true, quality: ''},
        },
        debug: false,
    });
};
export const mySupplyTopping = (data: {id: string; upper: boolean}) => {
    return axios.request({
        url: `v2/seedling/supplies/${data.id}/upper`,
        method: 'post',
        data: {
            onSale: !data.upper,
        },
        debug: false,
    });
};

export const supplyUpperAndLower = (data: {id: string; online: boolean}) => {
    return axios.request({
        url: `v2/seedling/supplies/${data.id}/onsale`,
        method: 'post',
        data: {
            onSale: !data.online,
        },
        debug: false,
    });
};
export const refreshSupply = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/supplies/${data.id}/refresh`,
        method: 'post',
        debug: false,
    });
};
export const quotedPriceSupply = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/supplies/category/${data.id}/list`,
        method: 'GET',
        debug: false,
    });
};

interface ReleaseProcessing {
    categoryId: number;
    profile: string;
    areaId: string;
    address: string;
    photos: string;
}

export const releaseProcessing = (data: ReleaseProcessing) => {
    return axios.request({
        url: `v2/seedling/supplies/process/create`,
        method: 'POST',
        data,
        validator: publishProcessingValidator.validator(),
        debug: false,
    });
};

export const getEngineeringOffer = (data: {id: string|undefined; orderId: string|undefined}) => {
    return axios.request({
        url: `v2/procurement/projects/quotation/${data.id}/order/${data.orderId}`,
        method: 'GET',
        debug: false,
    });
};
export const uploadDocument = (data: {file: File}) => {
    const formData = new FormData();
    formData.append('file', data.file);
    return axios.request({
        url: `v2/seedling/ocr/document?id=${new Date().valueOf()}`,
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization: getToken() || '',
            source: 'h5',
            encrypt: getEncrypt() || '',
        },
        debug: false,
        isShowLoading: false,
    });
};
export const releaseEngineer = (data: ReleaseEngineer) => {
    return axios.request({
        url: `v2/procurement/projects/create`,
        method: 'POST',
        data: data,
        debug: false,
        validator: releaseEngineerValidator.validator(),
    });
};
export const subscribeCategory = (data: {id: string}) => {
    return axios.request({
        url: `v1/subscripte/setting/category/add`,
        method: 'PUT',
        params: {categoryId: data.id},
        debug: false,
    });
};
export const releaseWantToBuy = (data: {content: string}) => {
    return axios.request({
        url: `v4/wechat/wechat/purchasefront/save`,
        method: 'POST',
        data: {
            ...data,
            ...{
                userId: userStoreModule.userDetail.user.id,
            },
        },
        validator: releaseWantToBuyValidator.validator(),
        debug: false,
    });
};

export const prospectList = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/supplies/visit/list`,
        method: 'GET',
        params: {
            ...data,
            ...{from},
        },
        debug: false,
    });
};


export const videoTranscoding = (data: {uploadPath: string; host: string; fileName: string; ExtName: string; Type: string}) => {
    return axios.request({
        url: `v2/seedling/ocr/video/resolve`,
        method: 'Post',
        data,
        debug: false,
    });
};

export const getPoster = (data: {id: string; type: string}) => {
    return axios.request({
        url: `v2/seedling/share/shop/${data.id}/poster?type=${data.type}`,
        method: 'GET',
        debug: false,
    });
};

export const getQuestionnaire = () => {
    return axios.request({
        url: `v2/seedling/share/question/survey/point_convert_vip`,
        method: 'GET',
        debug: false,
    });
};
export const submitQuestionnaire = (data: {id: string; mobile: string}) => {
    return axios.request({
        url: `v2/seedling/share/${data.id}/question/survey`,
        method: 'POST',
        params: {mobile: data.mobile},
        debug: false,
    });
};
export const delMyWantToBuy = (data: {id: string}) => {
    return axios.request({
        url: `v2/procurement/purchases/${data.id}/my`,
        method: 'DELETE',
        debug: false,
    });
};
export const delMySupply = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/supplies/${data.id}/my`,
        method: 'DELETE',
        debug: false,
    });
};
export const getAgreement = (data: {id: string}) => {
    return axios.request({
        url: `v2/seedling/headline`,
        method: 'GET',
        params: {headlineId: data.id},
        debug: false,
    });
};

export const signIn = () => {
    return axios.request({
        url: `v2/seedling/my/user/usersignin`,
        method: 'POST',
        debug: false,
    });
};
export const signList = () => {
    return axios.request({
        url: `v2/seedling/my/user/getusersigninpage`,
        method: 'POST',
        debug: false,
    });
};
export const getMyMiaodou = () => {
    return axios.request({
        url: `v2/seedling/my/integral`,
        method: 'GET',
        debug: false,
    });
};
export const getTaskStatus = () => {
    return axios.request({
        url: `v2/seedling/my/integral/stat`,
        method: 'GET',
        debug: false,
    });
};
export const getMiaodouRecord = (data: {page: number; size: number}) => {
    const {page, size} = data;
    const from = (page - 1) * size;
    return axios.request({
        url: `v2/seedling/my/integral/shows`,
        method: 'GET',
        params: {
            ...data,
            ...{from},
        },
        debug: false,
    });
};
