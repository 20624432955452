export interface CityData {
    label: string;
    value: string;
    children?: CityData[];
}

const cityData: CityData[] = [
    {
        label: '北京市',
        value: '6550688903064453120',
        children: [
            {
                label: '北京市',
                value: '6550688903068647424',
                children: [
                    {
                        label: '东城区',
                        value: '6550688903068647425',
                    },
                    {
                        label: '西城区',
                        value: '6550688903068647426',
                    },
                    {
                        label: '朝阳区',
                        value: '6550688903068647427',
                    },
                    {
                        label: '丰台区',
                        value: '6550688903068647428',
                    },
                    {
                        label: '石景山区',
                        value: '6550688903068647429',
                    },
                    {
                        label: '海淀区',
                        value: '6550688903068647430',
                    },
                    {
                        label: '门头沟区',
                        value: '6550688903068647431',
                    },
                    {
                        label: '房山区',
                        value: '6550688903068647432',
                    },
                    {
                        label: '通州区',
                        value: '6550688903068647433',
                    },
                    {
                        label: '顺义区',
                        value: '6550688903068647434',
                    },
                    {
                        label: '昌平区',
                        value: '6550688903068647435',
                    },
                    {
                        label: '大兴区',
                        value: '6550688903068647436',
                    },
                    {
                        label: '怀柔区',
                        value: '6550688903068647437',
                    },
                    {
                        label: '密云区',
                        value: '6550688903068647438',
                    },
                    {
                        label: '延庆区',
                        value: '6550688903068647439',
                    },
                ],
            },
        ],
    },
    {
        label: '天津市',
        value: '6550688903068647440',
        children: [
            {
                label: '天津市',
                value: '6550688903068647441',
                children: [
                    {
                        label: '和平区',
                        value: '6550688903072841728',
                    },
                    {
                        label: '河东区',
                        value: '6550688903072841729',
                    },
                    {
                        label: '河西区',
                        value: '6550688903072841730',
                    },
                    {
                        label: '南开区',
                        value: '6550688903072841731',
                    },
                    {
                        label: '河北区',
                        value: '6550688903072841732',
                    },
                    {
                        label: '红桥区',
                        value: '6550688903072841733',
                    },
                    {
                        label: '东丽区',
                        value: '6550688903072841734',
                    },
                    {
                        label: '西青区',
                        value: '6550688903072841735',
                    },
                    {
                        label: '津南区',
                        value: '6550688903072841736',
                    },
                    {
                        label: '北辰区',
                        value: '6550688903072841737',
                    },
                    {
                        label: '武清区',
                        value: '6550688903072841738',
                    },
                    {
                        label: '宝坻区',
                        value: '6550688903072841739',
                    },
                    {
                        label: '宁河区',
                        value: '6550688903072841740',
                    },
                    {
                        label: '静海区',
                        value: '6550688903072841741',
                    },
                    {
                        label: '滨海新区',
                        value: '6550688903072841742',
                    },
                    {
                        label: '蓟州区',
                        value: '6550688903072841743',
                    },
                ],
            },
        ],
    },
    {
        label: '河北省',
        value: '6550688903072841744',
        children: [
            {
                label: '石家庄市',
                value: '6550688903072841745',
                children: [
                    {
                        label: '长安区',
                        value: '6550688903072841746',
                    },
                    {
                        label: '裕华区',
                        value: '6550688903072841747',
                    },
                    {
                        label: '桥西区',
                        value: '6550688903072841748',
                    },
                    {
                        label: '新华区',
                        value: '6550688903072841749',
                    },
                    {
                        label: '藁城区',
                        value: '6550688903072841750',
                    },
                    {
                        label: '鹿泉区',
                        value: '6550688903072841751',
                    },
                    {
                        label: '栾城区',
                        value: '6550688903072841752',
                    },
                    {
                        label: '井陉矿区',
                        value: '6550688903077036032',
                    },
                    {
                        label: '井陉县',
                        value: '6550688903077036033',
                    },
                    {
                        label: '正定县',
                        value: '6550688903077036034',
                    },
                    {
                        label: '栾城县',
                        value: '6550688903077036035',
                    },
                    {
                        label: '行唐县',
                        value: '6550688903077036036',
                    },
                    {
                        label: '灵寿县',
                        value: '6550688903077036037',
                    },
                    {
                        label: '高邑县',
                        value: '6550688903077036038',
                    },
                    {
                        label: '深泽县',
                        value: '6550688903077036039',
                    },
                    {
                        label: '赞皇县',
                        value: '6550688903077036040',
                    },
                    {
                        label: '无极县',
                        value: '6550688903077036041',
                    },
                    {
                        label: '平山县',
                        value: '6550688903077036042',
                    },
                    {
                        label: '元氏县',
                        value: '6550688903077036043',
                    },
                    {
                        label: '赵县',
                        value: '6550688903077036044',
                    },
                    {
                        label: '辛集市',
                        value: '6550688903077036045',
                    },
                    {
                        label: '晋州市',
                        value: '6550688903077036046',
                    },
                    {
                        label: '新乐市',
                        value: '6550688903077036047',
                    },
                ],
            },
            {
                label: '唐山市',
                value: '6550688903077036048',
                children: [
                    {
                        label: '路南区',
                        value: '6550688903077036049',
                    },
                    {
                        label: '路北区',
                        value: '6550688903077036050',
                    },
                    {
                        label: '古冶区',
                        value: '6550688903077036051',
                    },
                    {
                        label: '开平区',
                        value: '6550688903077036052',
                    },
                    {
                        label: '丰润区',
                        value: '6550688903077036053',
                    },
                    {
                        label: '丰南区',
                        value: '6550688903077036054',
                    },
                    {
                        label: '曹妃甸区',
                        value: '6550688903077036055',
                    },
                    {
                        label: '遵化市',
                        value: '6550688903077036056',
                    },
                    {
                        label: '迁安市',
                        value: '6550688903077036057',
                    },
                    {
                        label: '滦县',
                        value: '6550688903077036058',
                    },
                    {
                        label: '滦南县',
                        value: '6550688903077036059',
                    },
                    {
                        label: '乐亭县',
                        value: '6550688903081230336',
                    },
                    {
                        label: '迁西县',
                        value: '6550688903081230337',
                    },
                    {
                        label: '玉田县',
                        value: '6550688903081230338',
                    },
                ],
            },
            {
                label: '秦皇岛市',
                value: '6550688903081230339',
                children: [
                    {
                        label: '海港区',
                        value: '6550688903081230340',
                    },
                    {
                        label: '山海关区',
                        value: '6550688903081230341',
                    },
                    {
                        label: '北戴河区',
                        value: '6550688903081230342',
                    },
                    {
                        label: '抚宁区',
                        value: '6550688903081230343',
                    },
                    {
                        label: '昌黎县',
                        value: '6550688903081230344',
                    },
                    {
                        label: '卢龙县',
                        value: '6550688903081230345',
                    },
                    {
                        label: '青龙满族自治县',
                        value: '6550688903081230346',
                    },
                ],
            },
            {
                label: '邯郸市',
                value: '6550688903081230347',
                children: [
                    {
                        label: '邯山区',
                        value: '6550688903081230348',
                    },
                    {
                        label: '丛台区',
                        value: '6550688903081230349',
                    },
                    {
                        label: '复兴区',
                        value: '6550688903081230350',
                    },
                    {
                        label: '峰峰矿区',
                        value: '6550688903081230351',
                    },
                    {
                        label: '肥乡区',
                        value: '6550688903081230352',
                    },
                    {
                        label: '永年区',
                        value: '6550688903081230353',
                    },
                    {
                        label: '成安县',
                        value: '6550688903081230354',
                    },
                    {
                        label: '大名县',
                        value: '6550688903081230355',
                    },
                    {
                        label: '涉县',
                        value: '6550688903081230356',
                    },
                    {
                        label: '磁县',
                        value: '6550688903081230357',
                    },
                    {
                        label: '临漳县',
                        value: '6550688903081230358',
                    },
                    {
                        label: '邱县',
                        value: '6550688903081230359',
                    },
                    {
                        label: '鸡泽县',
                        value: '6550688903081230360',
                    },
                    {
                        label: '广平县',
                        value: '6550688903081230361',
                    },
                    {
                        label: '馆陶县',
                        value: '6550688903081230362',
                    },
                    {
                        label: '魏县',
                        value: '6550688903081230363',
                    },
                    {
                        label: '曲周县',
                        value: '6550688903081230364',
                    },
                    {
                        label: '武安市',
                        value: '6550688903085424640',
                    },
                ],
            },
            {
                label: '邢台市',
                value: '6550688903085424641',
                children: [
                    {
                        label: '桥东区',
                        value: '6550688903085424642',
                    },
                    {
                        label: '桥西区',
                        value: '6550688903085424643',
                    },
                    {
                        label: '邢台县',
                        value: '6550688903085424644',
                    },
                    {
                        label: '临城县',
                        value: '6550688903085424645',
                    },
                    {
                        label: '内丘县',
                        value: '6550688903085424646',
                    },
                    {
                        label: '柏乡县',
                        value: '6550688903085424647',
                    },
                    {
                        label: '隆尧县',
                        value: '6550688903085424648',
                    },
                    {
                        label: '任县',
                        value: '6550688903085424649',
                    },
                    {
                        label: '南和县',
                        value: '6550688903085424650',
                    },
                    {
                        label: '宁晋县',
                        value: '6550688903085424651',
                    },
                    {
                        label: '巨鹿县',
                        value: '6550688903085424652',
                    },
                    {
                        label: '新河县',
                        value: '6550688903085424653',
                    },
                    {
                        label: '广宗县',
                        value: '6550688903085424654',
                    },
                    {
                        label: '平乡县',
                        value: '6550688903085424655',
                    },
                    {
                        label: '威县',
                        value: '6550688903085424656',
                    },
                    {
                        label: '清河县',
                        value: '6550688903085424657',
                    },
                    {
                        label: '临西县',
                        value: '6550688903085424658',
                    },
                    {
                        label: '南宫市',
                        value: '6550688903085424659',
                    },
                    {
                        label: '沙河市',
                        value: '6550688903085424660',
                    },
                ],
            },
            {
                label: '保定市',
                value: '6550688903085424661',
                children: [
                    {
                        label: '竞秀区',
                        value: '6550688903085424662',
                    },
                    {
                        label: '莲池区',
                        value: '6550688903085424663',
                    },
                    {
                        label: '满城区',
                        value: '6550688903085424664',
                    },
                    {
                        label: '清苑区',
                        value: '6550688903085424665',
                    },
                    {
                        label: '徐水区',
                        value: '6550688903089618944',
                    },
                    {
                        label: '涞水县',
                        value: '6550688903089618945',
                    },
                    {
                        label: '阜平县',
                        value: '6550688903089618946',
                    },
                    {
                        label: '定兴县',
                        value: '6550688903089618947',
                    },
                    {
                        label: '唐县',
                        value: '6550688903089618948',
                    },
                    {
                        label: '高阳县',
                        value: '6550688903089618949',
                    },
                    {
                        label: '容城县',
                        value: '6550688903089618950',
                    },
                    {
                        label: '涞源县',
                        value: '6550688903089618951',
                    },
                    {
                        label: '望都县',
                        value: '6550688903089618952',
                    },
                    {
                        label: '安新县',
                        value: '6550688903089618953',
                    },
                    {
                        label: '易县',
                        value: '6550688903089618954',
                    },
                    {
                        label: '曲阳县',
                        value: '6550688903089618955',
                    },
                    {
                        label: '蠡县',
                        value: '6550688903089618956',
                    },
                    {
                        label: '顺平县',
                        value: '6550688903089618957',
                    },
                    {
                        label: '博野县',
                        value: '6550688903089618958',
                    },
                    {
                        label: '雄县',
                        value: '6550688903089618959',
                    },
                    {
                        label: '涿州市',
                        value: '6550688903089618960',
                    },
                    {
                        label: '定州市',
                        value: '6550688903089618961',
                    },
                    {
                        label: '安国市',
                        value: '6550688903089618962',
                    },
                    {
                        label: '高碑店市',
                        value: '6550688903089618963',
                    },
                ],
            },
            {
                label: '张家口市',
                value: '6550688903089618964',
                children: [
                    {
                        label: '桥东区',
                        value: '6550688903089618965',
                    },
                    {
                        label: '桥西区',
                        value: '6550688903093813248',
                    },
                    {
                        label: '宣化区',
                        value: '6550688903093813249',
                    },
                    {
                        label: '下花园区',
                        value: '6550688903093813250',
                    },
                    {
                        label: '万全区',
                        value: '6550688903093813251',
                    },
                    {
                        label: '崇礼区',
                        value: '6550688903093813252',
                    },
                    {
                        label: '张北县',
                        value: '6550688903093813253',
                    },
                    {
                        label: '康保县',
                        value: '6550688903093813254',
                    },
                    {
                        label: '沽源县',
                        value: '6550688903093813255',
                    },
                    {
                        label: '尚义县',
                        value: '6550688903093813256',
                    },
                    {
                        label: '蔚县',
                        value: '6550688903093813257',
                    },
                    {
                        label: '阳原县',
                        value: '6550688903093813258',
                    },
                    {
                        label: '怀安县',
                        value: '6550688903093813259',
                    },
                    {
                        label: '怀来县',
                        value: '6550688903093813260',
                    },
                    {
                        label: '涿鹿县',
                        value: '6550688903093813261',
                    },
                    {
                        label: '赤城县',
                        value: '6550688903093813262',
                    },
                ],
            },
            {
                label: '承德市',
                value: '6550688903093813263',
                children: [
                    {
                        label: '双桥区',
                        value: '6550688903093813264',
                    },
                    {
                        label: '双滦区',
                        value: '6550688903093813265',
                    },
                    {
                        label: '鹰手营子矿区',
                        value: '6550688903093813266',
                    },
                    {
                        label: '承德县',
                        value: '6550688903098007552',
                    },
                    {
                        label: '兴隆县',
                        value: '6550688903098007553',
                    },
                    {
                        label: '平泉县',
                        value: '6550688903098007554',
                    },
                    {
                        label: '滦平县',
                        value: '6550688903098007555',
                    },
                    {
                        label: '隆化县',
                        value: '6550688903098007556',
                    },
                    {
                        label: '丰宁满族自治县',
                        value: '6550688903098007557',
                    },
                    {
                        label: '宽城满族自治县',
                        value: '6550688903098007558',
                    },
                    {
                        label: '围场满族蒙古族自治县',
                        value: '6550688903098007559',
                    },
                ],
            },
            {
                label: '沧州市',
                value: '6550688903098007560',
                children: [
                    {
                        label: '新华区',
                        value: '6550688903098007561',
                    },
                    {
                        label: '运河区',
                        value: '6550688903098007562',
                    },
                    {
                        label: '沧县',
                        value: '6550688903098007563',
                    },
                    {
                        label: '青县',
                        value: '6550688903098007564',
                    },
                    {
                        label: '东光县',
                        value: '6550688903098007565',
                    },
                    {
                        label: '海兴县',
                        value: '6550688903098007566',
                    },
                    {
                        label: '盐山县',
                        value: '6550688903098007567',
                    },
                    {
                        label: '肃宁县',
                        value: '6550688903098007568',
                    },
                    {
                        label: '南皮县',
                        value: '6550688903102201856',
                    },
                    {
                        label: '吴桥县',
                        value: '6550688903102201857',
                    },
                    {
                        label: '献县',
                        value: '6550688903102201858',
                    },
                    {
                        label: '孟村回族自治县',
                        value: '6550688903102201859',
                    },
                    {
                        label: '泊头市',
                        value: '6550688903102201860',
                    },
                    {
                        label: '任丘市',
                        value: '6550688903102201861',
                    },
                    {
                        label: '黄骅市',
                        value: '6550688903102201862',
                    },
                    {
                        label: '河间市',
                        value: '6550688903102201863',
                    },
                ],
            },
            {
                label: '廊坊市',
                value: '6550688903102201864',
                children: [
                    {
                        label: '广阳区',
                        value: '6550688903102201865',
                    },
                    {
                        label: '安次区',
                        value: '6550688903102201866',
                    },
                    {
                        label: '固安县',
                        value: '6550688903102201867',
                    },
                    {
                        label: '永清县',
                        value: '6550688903102201868',
                    },
                    {
                        label: '香河县',
                        value: '6550688903102201869',
                    },
                    {
                        label: '大城县',
                        value: '6550688903102201870',
                    },
                    {
                        label: '文安县',
                        value: '6550688903102201871',
                    },
                    {
                        label: '大厂回族自治县',
                        value: '6550688903106396160',
                    },
                    {
                        label: '霸州市',
                        value: '6550688903106396161',
                    },
                    {
                        label: '三河市',
                        value: '6550688903106396162',
                    },
                ],
            },
            {
                label: '衡水市',
                value: '6550688903106396163',
                children: [
                    {
                        label: '桃城区',
                        value: '6550688903106396164',
                    },
                    {
                        label: '冀州区',
                        value: '6550688903106396165',
                    },
                    {
                        label: '枣强县',
                        value: '6550688903106396166',
                    },
                    {
                        label: '武邑县',
                        value: '6550688903106396167',
                    },
                    {
                        label: '武强县',
                        value: '6550688903106396168',
                    },
                    {
                        label: '饶阳县',
                        value: '6550688903106396169',
                    },
                    {
                        label: '安平县',
                        value: '6550688903106396170',
                    },
                    {
                        label: '故城县',
                        value: '6550688903106396171',
                    },
                    {
                        label: '景县',
                        value: '6550688903106396172',
                    },
                    {
                        label: '阜城县',
                        value: '6550688903106396173',
                    },
                    {
                        label: '深州市',
                        value: '6550688903106396174',
                    },
                ],
            },
        ],
    },
    {
        label: '山西省',
        value: '6550688903106396175',
        children: [
            {
                label: '太原市',
                value: '6550688903106396176',
                children: [
                    {
                        label: '小店区',
                        value: '6550688903106396177',
                    },
                    {
                        label: '迎泽区',
                        value: '6550688903106396178',
                    },
                    {
                        label: '杏花岭区',
                        value: '6550688903106396179',
                    },
                    {
                        label: '尖草坪区',
                        value: '6550688903106396180',
                    },
                    {
                        label: '万柏林区',
                        value: '6550688903106396181',
                    },
                    {
                        label: '晋源区',
                        value: '6550688903106396182',
                    },
                    {
                        label: '清徐县',
                        value: '6550688903106396183',
                    },
                    {
                        label: '阳曲县',
                        value: '6550688903106396184',
                    },
                    {
                        label: '娄烦县',
                        value: '6550688903110590464',
                    },
                    {
                        label: '古交市',
                        value: '6550688903110590465',
                    },
                ],
            },
            {
                label: '大同市',
                value: '6550688903110590466',
                children: [
                    {
                        label: '城区',
                        value: '6550688903110590467',
                    },
                    {
                        label: '矿区',
                        value: '6550688903110590468',
                    },
                    {
                        label: '南郊区',
                        value: '6550688903110590469',
                    },
                    {
                        label: '新荣区',
                        value: '6550688903110590470',
                    },
                    {
                        label: '阳高县',
                        value: '6550688903110590471',
                    },
                    {
                        label: '天镇县',
                        value: '6550688903110590472',
                    },
                    {
                        label: '广灵县',
                        value: '6550688903110590473',
                    },
                    {
                        label: '灵丘县',
                        value: '6550688903110590474',
                    },
                    {
                        label: '浑源县',
                        value: '6550688903110590475',
                    },
                    {
                        label: '左云县',
                        value: '6550688903110590476',
                    },
                    {
                        label: '大同县',
                        value: '6550688903110590477',
                    },
                ],
            },
            {
                label: '阳泉市',
                value: '6550688903110590478',
                children: [
                    {
                        label: '城区',
                        value: '6550688903110590479',
                    },
                    {
                        label: '矿区',
                        value: '6550688903110590480',
                    },
                    {
                        label: '郊区',
                        value: '6550688903114784768',
                    },
                    {
                        label: '平定县',
                        value: '6550688903114784769',
                    },
                    {
                        label: '盂县',
                        value: '6550688903114784770',
                    },
                ],
            },
            {
                label: '长治市',
                value: '6550688903114784771',
                children: [
                    {
                        label: '城区',
                        value: '6550688903114784772',
                    },
                    {
                        label: '郊区',
                        value: '6550688903114784773',
                    },
                    {
                        label: '长治县',
                        value: '6550688903114784774',
                    },
                    {
                        label: '襄垣县',
                        value: '6550688903114784775',
                    },
                    {
                        label: '屯留县',
                        value: '6550688903114784776',
                    },
                    {
                        label: '平顺县',
                        value: '6550688903114784777',
                    },
                    {
                        label: '黎城县',
                        value: '6550688903114784778',
                    },
                    {
                        label: '壶关县',
                        value: '6550688903114784779',
                    },
                    {
                        label: '长子县',
                        value: '6550688903114784780',
                    },
                    {
                        label: '武乡县',
                        value: '6550688903114784781',
                    },
                    {
                        label: '沁县',
                        value: '6550688903114784782',
                    },
                    {
                        label: '沁源县',
                        value: '6550688903114784783',
                    },
                    {
                        label: '潞城市',
                        value: '6550688903114784784',
                    },
                ],
            },
            {
                label: '晋城市',
                value: '6550688903114784785',
                children: [
                    {
                        label: '城区',
                        value: '6550688903114784786',
                    },
                    {
                        label: '沁水县',
                        value: '6550688903114784787',
                    },
                    {
                        label: '阳城县',
                        value: '6550688903114784788',
                    },
                    {
                        label: '陵川县',
                        value: '6550688903114784789',
                    },
                    {
                        label: '泽州县',
                        value: '6550688903114784790',
                    },
                    {
                        label: '高平市',
                        value: '6550688903114784791',
                    },
                ],
            },
            {
                label: '朔州市',
                value: '6550688903114784792',
                children: [
                    {
                        label: '朔城区',
                        value: '6550688903118979072',
                    },
                    {
                        label: '平鲁区',
                        value: '6550688903118979073',
                    },
                    {
                        label: '山阴县',
                        value: '6550688903118979074',
                    },
                    {
                        label: '应县',
                        value: '6550688903118979075',
                    },
                    {
                        label: '右玉县',
                        value: '6550688903118979076',
                    },
                    {
                        label: '怀仁县',
                        value: '6550688903118979077',
                    },
                ],
            },
            {
                label: '忻州市',
                value: '6550688903118979078',
                children: [
                    {
                        label: '忻府区',
                        value: '6550688903118979079',
                    },
                    {
                        label: '原平市',
                        value: '6550688903118979080',
                    },
                    {
                        label: '定襄县',
                        value: '6550688903118979081',
                    },
                    {
                        label: '五台县',
                        value: '6550688903118979082',
                    },
                    {
                        label: '代县',
                        value: '6550688903118979083',
                    },
                    {
                        label: '繁峙县',
                        value: '6550688903118979084',
                    },
                    {
                        label: '宁武县',
                        value: '6550688903118979085',
                    },
                    {
                        label: '静乐县',
                        value: '6550688903118979086',
                    },
                    {
                        label: '神池县',
                        value: '6550688903118979087',
                    },
                    {
                        label: '五寨县',
                        value: '6550688903118979088',
                    },
                    {
                        label: '岢岚县',
                        value: '6550688903118979089',
                    },
                    {
                        label: '河曲县',
                        value: '6550688903118979090',
                    },
                    {
                        label: '保德县',
                        value: '6550688903118979091',
                    },
                    {
                        label: '偏关县',
                        value: '6550688903118979092',
                    },
                ],
            },
            {
                label: '吕梁市',
                value: '6550688903118979093',
                children: [
                    {
                        label: '离石区',
                        value: '6550688903118979094',
                    },
                    {
                        label: '孝义市',
                        value: '6550688903118979095',
                    },
                    {
                        label: '汾阳市',
                        value: '6550688903118979096',
                    },
                    {
                        label: '文水县',
                        value: '6550688903118979097',
                    },
                    {
                        label: '交城县',
                        value: '6550688903118979098',
                    },
                    {
                        label: '兴县',
                        value: '6550688903118979099',
                    },
                    {
                        label: '临县',
                        value: '6550688903118979100',
                    },
                    {
                        label: '柳林县',
                        value: '6550688903123173376',
                    },
                    {
                        label: '石楼县',
                        value: '6550688903123173377',
                    },
                    {
                        label: '岚县',
                        value: '6550688903123173378',
                    },
                    {
                        label: '方山县',
                        value: '6550688903123173379',
                    },
                    {
                        label: '中阳县',
                        value: '6550688903123173380',
                    },
                    {
                        label: '交口县',
                        value: '6550688903123173381',
                    },
                ],
            },
            {
                label: '晋中市',
                value: '6550688903123173382',
                children: [
                    {
                        label: '榆次区',
                        value: '6550688903123173383',
                    },
                    {
                        label: '介休市',
                        value: '6550688903123173384',
                    },
                    {
                        label: '榆社县',
                        value: '6550688903123173385',
                    },
                    {
                        label: '左权县',
                        value: '6550688903123173386',
                    },
                    {
                        label: '和顺县',
                        value: '6550688903123173387',
                    },
                    {
                        label: '昔阳县',
                        value: '6550688903123173388',
                    },
                    {
                        label: '寿阳县',
                        value: '6550688903123173389',
                    },
                    {
                        label: '太谷县',
                        value: '6550688903123173390',
                    },
                    {
                        label: '祁县',
                        value: '6550688903123173391',
                    },
                    {
                        label: '平遥县',
                        value: '6550688903123173392',
                    },
                    {
                        label: '灵石县',
                        value: '6550688903123173393',
                    },
                ],
            },
            {
                label: '临汾市',
                value: '6550688903123173394',
                children: [
                    {
                        label: '尧都区',
                        value: '6550688903123173395',
                    },
                    {
                        label: '侯马市',
                        value: '6550688903127367680',
                    },
                    {
                        label: '霍州市',
                        value: '6550688903127367681',
                    },
                    {
                        label: '曲沃县',
                        value: '6550688903127367682',
                    },
                    {
                        label: '翼城县',
                        value: '6550688903127367683',
                    },
                    {
                        label: '襄汾县',
                        value: '6550688903127367684',
                    },
                    {
                        label: '洪洞县',
                        value: '6550688903127367685',
                    },
                    {
                        label: '古县',
                        value: '6550688903127367686',
                    },
                    {
                        label: '安泽县',
                        value: '6550688903127367687',
                    },
                    {
                        label: '浮山县',
                        value: '6550688903127367688',
                    },
                    {
                        label: '吉县',
                        value: '6550688903127367689',
                    },
                    {
                        label: '乡宁县',
                        value: '6550688903127367690',
                    },
                    {
                        label: '蒲县',
                        value: '6550688903127367691',
                    },
                    {
                        label: '大宁县',
                        value: '6550688903127367692',
                    },
                    {
                        label: '永和县',
                        value: '6550688903127367693',
                    },
                    {
                        label: '隰县',
                        value: '6550688903127367694',
                    },
                    {
                        label: '汾西县',
                        value: '6550688903127367695',
                    },
                ],
            },
            {
                label: '运城市',
                value: '6550688903127367696',
                children: [
                    {
                        label: '盐湖区',
                        value: '6550688903127367697',
                    },
                    {
                        label: '永济市',
                        value: '6550688903127367698',
                    },
                    {
                        label: '河津市',
                        value: '6550688903127367699',
                    },
                    {
                        label: '芮城县',
                        value: '6550688903127367700',
                    },
                    {
                        label: '临猗县',
                        value: '6550688903127367701',
                    },
                    {
                        label: '万荣县',
                        value: '6550688903127367702',
                    },
                    {
                        label: '新绛县',
                        value: '6550688903127367703',
                    },
                    {
                        label: '稷山县',
                        value: '6550688903127367704',
                    },
                    {
                        label: '闻喜县',
                        value: '6550688903127367705',
                    },
                    {
                        label: '夏县',
                        value: '6550688903127367706',
                    },
                    {
                        label: '绛县',
                        value: '6550688903127367707',
                    },
                    {
                        label: '平陆县',
                        value: '6550688903131561984',
                    },
                    {
                        label: '垣曲县',
                        value: '6550688903131561985',
                    },
                ],
            },
        ],
    },
    {
        label: '内蒙古',
        value: '6550688903131561986',
        children: [
            {
                label: '呼和浩特市',
                value: '6550688903131561987',
                children: [
                    {
                        label: '新城区',
                        value: '6550688903131561988',
                    },
                    {
                        label: '回民区',
                        value: '6550688903131561989',
                    },
                    {
                        label: '玉泉区',
                        value: '6550688903131561990',
                    },
                    {
                        label: '赛罕区',
                        value: '6550688903131561991',
                    },
                    {
                        label: '土默特左旗',
                        value: '6550688903131561992',
                    },
                    {
                        label: '托克托县',
                        value: '6550688903131561993',
                    },
                    {
                        label: '和林格尔县',
                        value: '6550688903131561994',
                    },
                    {
                        label: '清水河县',
                        value: '6550688903131561995',
                    },
                    {
                        label: '武川县',
                        value: '6550688903131561996',
                    },
                ],
            },
            {
                label: '包头市',
                value: '6550688903131561997',
                children: [
                    {
                        label: '东河区',
                        value: '6550688903131561998',
                    },
                    {
                        label: '昆都仑区',
                        value: '6550688903131561999',
                    },
                    {
                        label: '青山区',
                        value: '6550688903131562000',
                    },
                    {
                        label: '石拐区',
                        value: '6550688903131562001',
                    },
                    {
                        label: '白云鄂博矿区',
                        value: '6550688903131562002',
                    },
                    {
                        label: '九原区',
                        value: '6550688903131562003',
                    },
                    {
                        label: '土默特右旗',
                        value: '6550688903131562004',
                    },
                    {
                        label: '固阳县',
                        value: '6550688903131562005',
                    },
                    {
                        label: '达尔罕茂明安联合旗',
                        value: '6550688903131562006',
                    },
                ],
            },
            {
                label: '乌海市',
                value: '6550688903131562007',
                children: [
                    {
                        label: '海勃湾区',
                        value: '6550688903131562008',
                    },
                    {
                        label: '海南区',
                        value: '6550688903131562009',
                    },
                    {
                        label: '乌达区',
                        value: '6550688903131562010',
                    },
                ],
            },
            {
                label: '赤峰市',
                value: '6550688903131562011',
                children: [
                    {
                        label: '红山区',
                        value: '6550688903131562012',
                    },
                    {
                        label: '元宝山区',
                        value: '6550688903131562013',
                    },
                    {
                        label: '松山区',
                        value: '6550688903135756288',
                    },
                    {
                        label: '阿鲁科尔沁旗',
                        value: '6550688903135756289',
                    },
                    {
                        label: '巴林左旗',
                        value: '6550688903135756290',
                    },
                    {
                        label: '巴林右旗',
                        value: '6550688903135756291',
                    },
                    {
                        label: '林西县',
                        value: '6550688903135756292',
                    },
                    {
                        label: '克什克腾旗',
                        value: '6550688903135756293',
                    },
                    {
                        label: '翁牛特旗',
                        value: '6550688903135756294',
                    },
                    {
                        label: '喀喇沁旗',
                        value: '6550688903135756295',
                    },
                    {
                        label: '宁城县',
                        value: '6550688903135756296',
                    },
                    {
                        label: '敖汉旗',
                        value: '6550688903135756297',
                    },
                ],
            },
            {
                label: '呼伦贝尔市',
                value: '6550688903135756298',
                children: [
                    {
                        label: '海拉尔区',
                        value: '6550688903135756299',
                    },
                    {
                        label: '扎赉诺尔区',
                        value: '6550688903135756300',
                    },
                    {
                        label: '满洲里市',
                        value: '6550688903135756301',
                    },
                    {
                        label: '扎兰屯市',
                        value: '6550688903135756302',
                    },
                    {
                        label: '牙克石市',
                        value: '6550688903135756303',
                    },
                    {
                        label: '根河市',
                        value: '6550688903135756304',
                    },
                    {
                        label: '额尔古纳市',
                        value: '6550688903135756305',
                    },
                    {
                        label: '阿荣旗',
                        value: '6550688903135756306',
                    },
                    {
                        label: '莫力达瓦达斡尔族自治旗',
                        value: '6550688903135756307',
                    },
                    {
                        label: '鄂伦春自治旗',
                        value: '6550688903135756308',
                    },
                    {
                        label: '鄂温克族自治旗',
                        value: '6550688903135756309',
                    },
                    {
                        label: '新巴尔虎右旗',
                        value: '6550688903135756310',
                    },
                    {
                        label: '新巴尔虎左旗',
                        value: '6550688903135756311',
                    },
                    {
                        label: '陈巴尔虎旗',
                        value: '6550688903135756312',
                    },
                ],
            },
            {
                label: '兴安盟',
                value: '6550688903135756313',
                children: [
                    {
                        label: '乌兰浩特市',
                        value: '6550688903135756314',
                    },
                    {
                        label: '阿尔山市',
                        value: '6550688903135756315',
                    },
                    {
                        label: '科尔沁右翼前旗',
                        value: '6550688903135756316',
                    },
                    {
                        label: '科尔沁右翼中旗',
                        value: '6550688903139950592',
                    },
                    {
                        label: '扎赉特旗',
                        value: '6550688903139950593',
                    },
                    {
                        label: '突泉县',
                        value: '6550688903139950594',
                    },
                ],
            },
            {
                label: '通辽市',
                value: '6550688903139950595',
                children: [
                    {
                        label: '科尔沁区',
                        value: '6550688903139950596',
                    },
                    {
                        label: '霍林郭勒市',
                        value: '6550688903139950597',
                    },
                    {
                        label: '科尔沁左翼中旗',
                        value: '6550688903139950598',
                    },
                    {
                        label: '科尔沁左翼后旗',
                        value: '6550688903139950599',
                    },
                    {
                        label: '开鲁县',
                        value: '6550688903139950600',
                    },
                    {
                        label: '库伦旗',
                        value: '6550688903139950601',
                    },
                    {
                        label: '奈曼旗',
                        value: '6550688903139950602',
                    },
                    {
                        label: '扎鲁特旗',
                        value: '6550688903139950603',
                    },
                ],
            },
            {
                label: '锡林郭勒盟',
                value: '6550688903139950604',
                children: [
                    {
                        label: '二连浩特市',
                        value: '6550688903139950605',
                    },
                    {
                        label: '锡林浩特市',
                        value: '6550688903139950606',
                    },
                    {
                        label: '阿巴嘎旗',
                        value: '6550688903139950607',
                    },
                    {
                        label: '苏尼特左旗',
                        value: '6550688903139950608',
                    },
                    {
                        label: '苏尼特右旗',
                        value: '6550688903139950609',
                    },
                    {
                        label: '东乌珠穆沁旗',
                        value: '6550688903139950610',
                    },
                    {
                        label: '西乌珠穆沁旗',
                        value: '6550688903139950611',
                    },
                    {
                        label: '太仆寺旗',
                        value: '6550688903139950612',
                    },
                    {
                        label: '镶黄旗',
                        value: '6550688903139950613',
                    },
                    {
                        label: '正镶白旗',
                        value: '6550688903139950614',
                    },
                    {
                        label: '正蓝旗',
                        value: '6550688903139950615',
                    },
                    {
                        label: '多伦县',
                        value: '6550688903139950616',
                    },
                ],
            },
            {
                label: '乌兰察布市',
                value: '6550688903139950617',
                children: [
                    {
                        label: '集宁区',
                        value: '6550688903139950618',
                    },
                    {
                        label: '丰镇市',
                        value: '6550688903139950619',
                    },
                    {
                        label: '卓资县',
                        value: '6550688903139950620',
                    },
                    {
                        label: '化德县',
                        value: '6550688903144144896',
                    },
                    {
                        label: '商都县',
                        value: '6550688903144144897',
                    },
                    {
                        label: '兴和县',
                        value: '6550688903144144898',
                    },
                    {
                        label: '凉城县',
                        value: '6550688903144144899',
                    },
                    {
                        label: '察哈尔右翼前旗',
                        value: '6550688903144144900',
                    },
                    {
                        label: '察哈尔右翼中旗',
                        value: '6550688903144144901',
                    },
                    {
                        label: '察哈尔右翼后旗',
                        value: '6550688903144144902',
                    },
                    {
                        label: '四子王旗',
                        value: '6550688903144144903',
                    },
                ],
            },
            {
                label: '鄂尔多斯市',
                value: '6550688903144144904',
                children: [
                    {
                        label: '东胜区',
                        value: '6550688903144144905',
                    },
                    {
                        label: '康巴什区',
                        value: '6550688903144144906',
                    },
                    {
                        label: '达拉特旗',
                        value: '6550688903144144907',
                    },
                    {
                        label: '准格尔旗',
                        value: '6550688903144144908',
                    },
                    {
                        label: '鄂托克前旗',
                        value: '6550688903144144909',
                    },
                    {
                        label: '鄂托克旗',
                        value: '6550688903144144910',
                    },
                    {
                        label: '杭锦旗',
                        value: '6550688903144144911',
                    },
                    {
                        label: '伊金霍洛旗',
                        value: '6550688903144144912',
                    },
                ],
            },
            {
                label: '巴彦淖尔市',
                value: '6550688903144144913',
                children: [
                    {
                        label: '临河区',
                        value: '6550688903144144914',
                    },
                    {
                        label: '五原县',
                        value: '6550688903144144915',
                    },
                    {
                        label: '磴口县',
                        value: '6550688903144144916',
                    },
                    {
                        label: '乌拉特前旗',
                        value: '6550688903144144917',
                    },
                    {
                        label: '乌拉特中旗',
                        value: '6550688903144144918',
                    },
                    {
                        label: '乌拉特后旗',
                        value: '6550688903144144919',
                    },
                    {
                        label: '杭锦后旗',
                        value: '6550688903144144920',
                    },
                ],
            },
            {
                label: '阿拉善盟',
                value: '6550688903144144921',
                children: [
                    {
                        label: '阿拉善左旗',
                        value: '6550688903144144922',
                    },
                    {
                        label: '阿拉善右旗',
                        value: '6550688903144144923',
                    },
                    {
                        label: '额济纳旗',
                        value: '6550688903148339200',
                    },
                ],
            },
        ],
    },
    {
        label: '辽宁省',
        value: '6550688903148339201',
        children: [
            {
                label: '沈阳市',
                value: '6550688903148339202',
                children: [
                    {
                        label: '和平区',
                        value: '6550688903148339203',
                    },
                    {
                        label: '沈河区',
                        value: '6550688903148339204',
                    },
                    {
                        label: '大东区',
                        value: '6550688903148339205',
                    },
                    {
                        label: '皇姑区',
                        value: '6550688903148339206',
                    },
                    {
                        label: '铁西区',
                        value: '6550688903148339207',
                    },
                    {
                        label: '苏家屯区',
                        value: '6550688903148339208',
                    },
                    {
                        label: '浑南区',
                        value: '6550688903148339209',
                    },
                    {
                        label: '沈北新区',
                        value: '6550688903148339210',
                    },
                    {
                        label: '于洪区',
                        value: '6550688903148339211',
                    },
                    {
                        label: '辽中区',
                        value: '6550688903148339212',
                    },
                    {
                        label: '康平县',
                        value: '6550688903148339213',
                    },
                    {
                        label: '法库县',
                        value: '6550688903148339214',
                    },
                    {
                        label: '新民市',
                        value: '6550688903148339215',
                    },
                ],
            },
            {
                label: '大连市',
                value: '6550688903148339216',
                children: [
                    {
                        label: '中山区',
                        value: '6550688903148339217',
                    },
                    {
                        label: '西岗区',
                        value: '6550688903148339218',
                    },
                    {
                        label: '沙河口区',
                        value: '6550688903148339219',
                    },
                    {
                        label: '甘井子区',
                        value: '6550688903148339220',
                    },
                    {
                        label: '旅顺口区',
                        value: '6550688903148339221',
                    },
                    {
                        label: '金州区',
                        value: '6550688903148339222',
                    },
                    {
                        label: '普兰店区',
                        value: '6550688903148339223',
                    },
                    {
                        label: '瓦房店市',
                        value: '6550688903148339224',
                    },
                    {
                        label: '庄河市',
                        value: '6550688903148339225',
                    },
                    {
                        label: '长海县',
                        value: '6550688903148339226',
                    },
                ],
            },
            {
                label: '鞍山市',
                value: '6550688903148339227',
                children: [
                    {
                        label: '铁东区',
                        value: '6550688903148339228',
                    },
                    {
                        label: '铁西区',
                        value: '6550688903152533504',
                    },
                    {
                        label: '立山区',
                        value: '6550688903152533505',
                    },
                    {
                        label: '千山区',
                        value: '6550688903152533506',
                    },
                    {
                        label: '台安县',
                        value: '6550688903152533507',
                    },
                    {
                        label: '岫岩满族自治县',
                        value: '6550688903152533508',
                    },
                    {
                        label: '海城市',
                        value: '6550688903152533509',
                    },
                ],
            },
            {
                label: '抚顺市',
                value: '6550688903152533510',
                children: [
                    {
                        label: '新抚区',
                        value: '6550688903152533511',
                    },
                    {
                        label: '东洲区',
                        value: '6550688903152533512',
                    },
                    {
                        label: '望花区',
                        value: '6550688903152533513',
                    },
                    {
                        label: '顺城区',
                        value: '6550688903152533514',
                    },
                    {
                        label: '抚顺县',
                        value: '6550688903152533515',
                    },
                    {
                        label: '新宾满族自治县',
                        value: '6550688903152533516',
                    },
                    {
                        label: '清原满族自治县',
                        value: '6550688903152533517',
                    },
                ],
            },
            {
                label: '本溪市',
                value: '6550688903152533518',
                children: [
                    {
                        label: '平山区',
                        value: '6550688903152533519',
                    },
                    {
                        label: '溪湖区',
                        value: '6550688903152533520',
                    },
                    {
                        label: '明山区',
                        value: '6550688903152533521',
                    },
                    {
                        label: '南芬区',
                        value: '6550688903152533522',
                    },
                    {
                        label: '本溪满族自治县',
                        value: '6550688903152533523',
                    },
                    {
                        label: '桓仁满族自治县',
                        value: '6550688903152533524',
                    },
                ],
            },
            {
                label: '丹东市',
                value: '6550688903152533525',
                children: [
                    {
                        label: '元宝区',
                        value: '6550688903152533526',
                    },
                    {
                        label: '振兴区',
                        value: '6550688903152533527',
                    },
                    {
                        label: '振安区',
                        value: '6550688903152533528',
                    },
                    {
                        label: '宽甸满族自治县',
                        value: '6550688903152533529',
                    },
                    {
                        label: '东港市',
                        value: '6550688903152533530',
                    },
                    {
                        label: '凤城市',
                        value: '6550688903156727808',
                    },
                ],
            },
            {
                label: '锦州市',
                value: '6550688903156727809',
                children: [
                    {
                        label: '古塔区',
                        value: '6550688903156727810',
                    },
                    {
                        label: '凌河区',
                        value: '6550688903156727811',
                    },
                    {
                        label: '太和区',
                        value: '6550688903156727812',
                    },
                    {
                        label: '黑山县',
                        value: '6550688903156727813',
                    },
                    {
                        label: '义县',
                        value: '6550688903156727814',
                    },
                    {
                        label: '凌海市',
                        value: '6550688903156727815',
                    },
                    {
                        label: '北镇市',
                        value: '6550688903156727816',
                    },
                ],
            },
            {
                label: '营口市',
                value: '6550688903156727817',
                children: [
                    {
                        label: '站前区',
                        value: '6550688903156727818',
                    },
                    {
                        label: '西市区',
                        value: '6550688903156727819',
                    },
                    {
                        label: '鲅鱼圈区',
                        value: '6550688903156727820',
                    },
                    {
                        label: '老边区',
                        value: '6550688903156727821',
                    },
                    {
                        label: '盖州市',
                        value: '6550688903156727822',
                    },
                    {
                        label: '大石桥市',
                        value: '6550688903156727823',
                    },
                ],
            },
            {
                label: '阜新市',
                value: '6550688903156727824',
                children: [
                    {
                        label: '海州区',
                        value: '6550688903156727825',
                    },
                    {
                        label: '新邱区',
                        value: '6550688903156727826',
                    },
                    {
                        label: '太平区',
                        value: '6550688903156727827',
                    },
                    {
                        label: '清河门区',
                        value: '6550688903156727828',
                    },
                    {
                        label: '细河区',
                        value: '6550688903156727829',
                    },
                    {
                        label: '阜新蒙古族自治县',
                        value: '6550688903156727830',
                    },
                    {
                        label: '彰武县',
                        value: '6550688903156727831',
                    },
                ],
            },
            {
                label: '辽阳市',
                value: '6550688903156727832',
                children: [
                    {
                        label: '白塔区',
                        value: '6550688903156727833',
                    },
                    {
                        label: '文圣区',
                        value: '6550688903156727834',
                    },
                    {
                        label: '宏伟区',
                        value: '6550688903156727835',
                    },
                    {
                        label: '弓长岭区',
                        value: '6550688903160922112',
                    },
                    {
                        label: '太子河区',
                        value: '6550688903160922113',
                    },
                    {
                        label: '辽阳县',
                        value: '6550688903160922114',
                    },
                    {
                        label: '灯塔市',
                        value: '6550688903160922115',
                    },
                ],
            },
            {
                label: '盘锦市',
                value: '6550688903160922116',
                children: [
                    {
                        label: '双台子区',
                        value: '6550688903160922117',
                    },
                    {
                        label: '兴隆台区',
                        value: '6550688903160922118',
                    },
                    {
                        label: '大洼县',
                        value: '6550688903160922119',
                    },
                    {
                        label: '盘山县',
                        value: '6550688903160922120',
                    },
                ],
            },
            {
                label: '铁岭市',
                value: '6550688903160922121',
                children: [
                    {
                        label: '银州区',
                        value: '6550688903160922122',
                    },
                    {
                        label: '清河区',
                        value: '6550688903160922123',
                    },
                    {
                        label: '铁岭县',
                        value: '6550688903160922124',
                    },
                    {
                        label: '西丰县',
                        value: '6550688903160922125',
                    },
                    {
                        label: '昌图县',
                        value: '6550688903160922126',
                    },
                    {
                        label: '调兵山市',
                        value: '6550688903160922127',
                    },
                    {
                        label: '开原市',
                        value: '6550688903160922128',
                    },
                ],
            },
            {
                label: '朝阳市',
                value: '6550688903160922129',
                children: [
                    {
                        label: '双塔区',
                        value: '6550688903160922130',
                    },
                    {
                        label: '龙城区',
                        value: '6550688903160922131',
                    },
                    {
                        label: '朝阳县',
                        value: '6550688903160922132',
                    },
                    {
                        label: '建平县',
                        value: '6550688903160922133',
                    },
                    {
                        label: '喀喇沁左翼蒙古族自治县',
                        value: '6550688903160922134',
                    },
                    {
                        label: '北票市',
                        value: '6550688903160922135',
                    },
                    {
                        label: '凌源市',
                        value: '6550688903160922136',
                    },
                ],
            },
            {
                label: '葫芦岛市',
                value: '6550688903160922137',
                children: [
                    {
                        label: '连山区',
                        value: '6550688903165116416',
                    },
                    {
                        label: '龙港区',
                        value: '6550688903165116417',
                    },
                    {
                        label: '南票区',
                        value: '6550688903165116418',
                    },
                    {
                        label: '绥中县',
                        value: '6550688903165116419',
                    },
                    {
                        label: '建昌县',
                        value: '6550688903165116420',
                    },
                    {
                        label: '兴城市',
                        value: '6550688903165116421',
                    },
                ],
            },
        ],
    },
    {
        label: '吉林省',
        value: '6550688903165116422',
        children: [
            {
                label: '长春市',
                value: '6550688903165116423',
                children: [
                    {
                        label: '南关区',
                        value: '6550688903165116424',
                    },
                    {
                        label: '宽城区',
                        value: '6550688903165116425',
                    },
                    {
                        label: '朝阳区',
                        value: '6550688903165116426',
                    },
                    {
                        label: '二道区',
                        value: '6550688903165116427',
                    },
                    {
                        label: '绿园区',
                        value: '6550688903165116428',
                    },
                    {
                        label: '双阳区',
                        value: '6550688903165116429',
                    },
                    {
                        label: '九台区',
                        value: '6550688903165116430',
                    },
                    {
                        label: '农安县',
                        value: '6550688903165116431',
                    },
                    {
                        label: '榆树市',
                        value: '6550688903165116432',
                    },
                    {
                        label: '德惠市',
                        value: '6550688903165116433',
                    },
                ],
            },
            {
                label: '吉林市',
                value: '6550688903169310720',
                children: [
                    {
                        label: '昌邑区',
                        value: '6550688903169310721',
                    },
                    {
                        label: '龙潭区',
                        value: '6550688903169310722',
                    },
                    {
                        label: '船营区',
                        value: '6550688903169310723',
                    },
                    {
                        label: '丰满区',
                        value: '6550688903169310724',
                    },
                    {
                        label: '永吉县',
                        value: '6550688903169310725',
                    },
                    {
                        label: '蛟河市',
                        value: '6550688903169310726',
                    },
                    {
                        label: '桦甸市',
                        value: '6550688903169310727',
                    },
                    {
                        label: '舒兰市',
                        value: '6550688903169310728',
                    },
                    {
                        label: '磐石市',
                        value: '6550688903169310729',
                    },
                ],
            },
            {
                label: '四平市',
                value: '6550688903169310730',
                children: [
                    {
                        label: '铁西区',
                        value: '6550688903169310731',
                    },
                    {
                        label: '铁东区',
                        value: '6550688903169310732',
                    },
                    {
                        label: '梨树县',
                        value: '6550688903169310733',
                    },
                    {
                        label: '伊通满族自治县',
                        value: '6550688903169310734',
                    },
                    {
                        label: '公主岭市',
                        value: '6550688903169310735',
                    },
                    {
                        label: '双辽市',
                        value: '6550688903169310736',
                    },
                ],
            },
            {
                label: '辽源市',
                value: '6550688903169310737',
                children: [
                    {
                        label: '龙山区',
                        value: '6550688903169310738',
                    },
                    {
                        label: '西安区',
                        value: '6550688903169310739',
                    },
                    {
                        label: '东丰县',
                        value: '6550688903169310740',
                    },
                    {
                        label: '东辽县',
                        value: '6550688903169310741',
                    },
                ],
            },
            {
                label: '通化市',
                value: '6550688903169310742',
                children: [
                    {
                        label: '东昌区',
                        value: '6550688903169310743',
                    },
                    {
                        label: '二道江区',
                        value: '6550688903169310744',
                    },
                    {
                        label: '通化县',
                        value: '6550688903169310745',
                    },
                    {
                        label: '辉南县',
                        value: '6550688903169310746',
                    },
                    {
                        label: '柳河县',
                        value: '6550688903173505024',
                    },
                    {
                        label: '梅河口市',
                        value: '6550688903173505025',
                    },
                    {
                        label: '集安市',
                        value: '6550688903173505026',
                    },
                ],
            },
            {
                label: '白山市',
                value: '6550688903173505027',
                children: [
                    {
                        label: '浑江区',
                        value: '6550688903173505028',
                    },
                    {
                        label: '江源区',
                        value: '6550688903173505029',
                    },
                    {
                        label: '抚松县',
                        value: '6550688903173505030',
                    },
                    {
                        label: '靖宇县',
                        value: '6550688903173505031',
                    },
                    {
                        label: '长白朝鲜族自治县',
                        value: '6550688903173505032',
                    },
                    {
                        label: '临江市',
                        value: '6550688903173505033',
                    },
                ],
            },
            {
                label: '松原市',
                value: '6550688903173505034',
                children: [
                    {
                        label: '宁江区',
                        value: '6550688903173505035',
                    },
                    {
                        label: '前郭尔罗斯蒙古族自治县',
                        value: '6550688903173505036',
                    },
                    {
                        label: '长岭县',
                        value: '6550688903173505037',
                    },
                    {
                        label: '乾安县',
                        value: '6550688903173505038',
                    },
                    {
                        label: '扶余市',
                        value: '6550688903173505039',
                    },
                ],
            },
            {
                label: '白城市',
                value: '6550688903173505040',
                children: [
                    {
                        label: '洮北区',
                        value: '6550688903173505041',
                    },
                    {
                        label: '镇赉县',
                        value: '6550688903173505042',
                    },
                    {
                        label: '通榆县',
                        value: '6550688903173505043',
                    },
                    {
                        label: '洮南市',
                        value: '6550688903173505044',
                    },
                    {
                        label: '大安市',
                        value: '6550688903173505045',
                    },
                ],
            },
            {
                label: '延边朝鲜族自治州',
                value: '6550688903173505046',
                children: [
                    {
                        label: '延吉市',
                        value: '6550688903173505047',
                    },
                    {
                        label: '图们市',
                        value: '6550688903173505048',
                    },
                    {
                        label: '敦化市',
                        value: '6550688903173505049',
                    },
                    {
                        label: '珲春市',
                        value: '6550688903173505050',
                    },
                    {
                        label: '龙井市',
                        value: '6550688903177699328',
                    },
                    {
                        label: '和龙市',
                        value: '6550688903177699329',
                    },
                    {
                        label: '汪清县',
                        value: '6550688903177699330',
                    },
                    {
                        label: '安图县',
                        value: '6550688903177699331',
                    },
                ],
            },
        ],
    },
    {
        label: '黑龙江省',
        value: '6550688903177699332',
        children: [
            {
                label: '哈尔滨市',
                value: '6550688903177699333',
                children: [
                    {
                        label: '道里区',
                        value: '6550688903177699334',
                    },
                    {
                        label: '南岗区',
                        value: '6550688903177699335',
                    },
                    {
                        label: '道外区',
                        value: '6550688903177699336',
                    },
                    {
                        label: '松北区',
                        value: '6550688903177699337',
                    },
                    {
                        label: '香坊区',
                        value: '6550688903177699338',
                    },
                    {
                        label: '阿成区',
                        value: '6550688903177699339',
                    },
                    {
                        label: '平房区',
                        value: '6550688903177699340',
                    },
                    {
                        label: '呼兰区',
                        value: '6550688903177699341',
                    },
                    {
                        label: '双城区',
                        value: '6550688903177699342',
                    },
                    {
                        label: '依兰县',
                        value: '6550688903177699343',
                    },
                    {
                        label: '方正县',
                        value: '6550688903177699344',
                    },
                    {
                        label: '宾县',
                        value: '6550688903177699345',
                    },
                    {
                        label: '巴彦县',
                        value: '6550688903177699346',
                    },
                    {
                        label: '木兰县',
                        value: '6550688903177699347',
                    },
                    {
                        label: '通河县',
                        value: '6550688903177699348',
                    },
                    {
                        label: '延寿县',
                        value: '6550688903177699349',
                    },
                    {
                        label: '尚志市',
                        value: '6550688903177699350',
                    },
                    {
                        label: '五常市',
                        value: '6550688903177699351',
                    },
                ],
            },
            {
                label: '齐齐哈尔市',
                value: '6550688903177699352',
                children: [
                    {
                        label: '龙沙区',
                        value: '6550688903177699353',
                    },
                    {
                        label: '建华区',
                        value: '6550688903177699354',
                    },
                    {
                        label: '铁锋区',
                        value: '6550688903177699355',
                    },
                    {
                        label: '昂昂溪区',
                        value: '6550688903177699356',
                    },
                    {
                        label: '富拉尔基区',
                        value: '6550688903181893632',
                    },
                    {
                        label: '碾子山区',
                        value: '6550688903181893633',
                    },
                    {
                        label: '梅里斯达斡尔族区',
                        value: '6550688903181893634',
                    },
                    {
                        label: '龙江县',
                        value: '6550688903181893635',
                    },
                    {
                        label: '依安县',
                        value: '6550688903181893636',
                    },
                    {
                        label: '泰来县',
                        value: '6550688903181893637',
                    },
                    {
                        label: '甘南县',
                        value: '6550688903181893638',
                    },
                    {
                        label: '富裕县',
                        value: '6550688903181893639',
                    },
                    {
                        label: '克山县',
                        value: '6550688903181893640',
                    },
                    {
                        label: '克东县',
                        value: '6550688903181893641',
                    },
                    {
                        label: '拜泉县',
                        value: '6550688903181893642',
                    },
                    {
                        label: '讷河市',
                        value: '6550688903181893643',
                    },
                ],
            },
            {
                label: '鸡西市',
                value: '6550688903181893644',
                children: [
                    {
                        label: '鸡冠区',
                        value: '6550688903181893645',
                    },
                    {
                        label: '恒山区',
                        value: '6550688903181893646',
                    },
                    {
                        label: '滴道区',
                        value: '6550688903181893647',
                    },
                    {
                        label: '梨树区',
                        value: '6550688903181893648',
                    },
                    {
                        label: '城子河区',
                        value: '6550688903181893649',
                    },
                    {
                        label: '麻山区',
                        value: '6550688903181893650',
                    },
                    {
                        label: '鸡东县',
                        value: '6550688903181893651',
                    },
                    {
                        label: '虎林市',
                        value: '6550688903181893652',
                    },
                    {
                        label: '密山市',
                        value: '6550688903181893653',
                    },
                ],
            },
            {
                label: '鹤岗市',
                value: '6550688903181893654',
                children: [
                    {
                        label: '向阳区',
                        value: '6550688903181893655',
                    },
                    {
                        label: '工农区',
                        value: '6550688903181893656',
                    },
                    {
                        label: '南山区',
                        value: '6550688903181893657',
                    },
                    {
                        label: '兴安区',
                        value: '6550688903181893658',
                    },
                    {
                        label: '东山区',
                        value: '6550688903181893659',
                    },
                    {
                        label: '兴山区',
                        value: '6550688903186087936',
                    },
                    {
                        label: '萝北县',
                        value: '6550688903186087937',
                    },
                    {
                        label: '绥滨县',
                        value: '6550688903186087938',
                    },
                ],
            },
            {
                label: '双鸭山市',
                value: '6550688903186087939',
                children: [
                    {
                        label: '尖山区',
                        value: '6550688903186087940',
                    },
                    {
                        label: '岭东区',
                        value: '6550688903186087941',
                    },
                    {
                        label: '四方台区',
                        value: '6550688903186087942',
                    },
                    {
                        label: '宝山区',
                        value: '6550688903186087943',
                    },
                    {
                        label: '集贤县',
                        value: '6550688903186087944',
                    },
                    {
                        label: '友谊县',
                        value: '6550688903186087945',
                    },
                    {
                        label: '宝清县',
                        value: '6550688903186087946',
                    },
                    {
                        label: '饶河县',
                        value: '6550688903186087947',
                    },
                ],
            },
            {
                label: '大庆市',
                value: '6550688903186087948',
                children: [
                    {
                        label: '萨尔图区',
                        value: '6550688903186087949',
                    },
                    {
                        label: '龙凤区',
                        value: '6550688903186087950',
                    },
                    {
                        label: '让胡路区',
                        value: '6550688903186087951',
                    },
                    {
                        label: '红岗区',
                        value: '6550688903186087952',
                    },
                    {
                        label: '大同区',
                        value: '6550688903186087953',
                    },
                    {
                        label: '肇州县',
                        value: '6550688903186087954',
                    },
                    {
                        label: '肇源县',
                        value: '6550688903186087955',
                    },
                    {
                        label: '林甸县',
                        value: '6550688903186087956',
                    },
                    {
                        label: '杜尔伯特蒙古族自治县',
                        value: '6550688903186087957',
                    },
                ],
            },
            {
                label: '伊春市',
                value: '6550688903186087958',
                children: [
                    {
                        label: '伊春区',
                        value: '6550688903186087959',
                    },
                    {
                        label: '南岔区',
                        value: '6550688903186087960',
                    },
                    {
                        label: '友好区',
                        value: '6550688903190282240',
                    },
                    {
                        label: '西林区',
                        value: '6550688903190282241',
                    },
                    {
                        label: '翠峦区',
                        value: '6550688903190282242',
                    },
                    {
                        label: '新青区',
                        value: '6550688903190282243',
                    },
                    {
                        label: '美溪区',
                        value: '6550688903190282244',
                    },
                    {
                        label: '金山屯区',
                        value: '6550688903190282245',
                    },
                    {
                        label: '五营区',
                        value: '6550688903190282246',
                    },
                    {
                        label: '乌马河区',
                        value: '6550688903190282247',
                    },
                    {
                        label: '汤旺河区',
                        value: '6550688903190282248',
                    },
                    {
                        label: '带岭区',
                        value: '6550688903190282249',
                    },
                    {
                        label: '乌伊岭区',
                        value: '6550688903190282250',
                    },
                    {
                        label: '红星区',
                        value: '6550688903190282251',
                    },
                    {
                        label: '上甘岭区',
                        value: '6550688903190282252',
                    },
                    {
                        label: '嘉荫县',
                        value: '6550688903190282253',
                    },
                    {
                        label: '铁力市',
                        value: '6550688903190282254',
                    },
                ],
            },
            {
                label: '佳木斯市',
                value: '6550688903190282255',
                children: [
                    {
                        label: '向阳区',
                        value: '6550688903190282256',
                    },
                    {
                        label: '前进区',
                        value: '6550688903190282257',
                    },
                    {
                        label: '东风区',
                        value: '6550688903190282258',
                    },
                    {
                        label: '郊区',
                        value: '6550688903190282259',
                    },
                    {
                        label: '桦南县',
                        value: '6550688903190282260',
                    },
                    {
                        label: '桦川县',
                        value: '6550688903190282261',
                    },
                    {
                        label: '汤原县',
                        value: '6550688903190282262',
                    },
                    {
                        label: '抚远市',
                        value: '6550688903190282263',
                    },
                    {
                        label: '同江市',
                        value: '6550688903190282264',
                    },
                    {
                        label: '富锦市',
                        value: '6550688903190282265',
                    },
                ],
            },
            {
                label: '七台河市',
                value: '6550688903190282266',
                children: [
                    {
                        label: '新兴区',
                        value: '6550688903190282267',
                    },
                    {
                        label: '桃山区',
                        value: '6550688903194476544',
                    },
                    {
                        label: '茄子河区',
                        value: '6550688903194476545',
                    },
                    {
                        label: '勃利县',
                        value: '6550688903194476546',
                    },
                ],
            },
            {
                label: '牡丹江市',
                value: '6550688903194476547',
                children: [
                    {
                        label: '东安区',
                        value: '6550688903194476548',
                    },
                    {
                        label: '阳明区',
                        value: '6550688903194476549',
                    },
                    {
                        label: '爱民区',
                        value: '6550688903194476550',
                    },
                    {
                        label: '西安区',
                        value: '6550688903194476551',
                    },
                    {
                        label: '东宁市',
                        value: '6550688903194476552',
                    },
                    {
                        label: '林口县',
                        value: '6550688903194476553',
                    },
                    {
                        label: '绥芬河市',
                        value: '6550688903194476554',
                    },
                    {
                        label: '海林市',
                        value: '6550688903194476555',
                    },
                    {
                        label: '宁安市',
                        value: '6550688903194476556',
                    },
                    {
                        label: '穆棱市',
                        value: '6550688903194476557',
                    },
                ],
            },
            {
                label: '黑河市',
                value: '6550688903194476558',
                children: [
                    {
                        label: '爱辉区',
                        value: '6550688903194476559',
                    },
                    {
                        label: '嫩江县',
                        value: '6550688903194476560',
                    },
                    {
                        label: '逊克县',
                        value: '6550688903194476561',
                    },
                    {
                        label: '孙吴县',
                        value: '6550688903194476562',
                    },
                    {
                        label: '北安市',
                        value: '6550688903194476563',
                    },
                    {
                        label: '五大连池市',
                        value: '6550688903194476564',
                    },
                ],
            },
            {
                label: '绥化市',
                value: '6550688903194476565',
                children: [
                    {
                        label: '北林区',
                        value: '6550688903194476566',
                    },
                    {
                        label: '安达市',
                        value: '6550688903194476567',
                    },
                    {
                        label: '肇东市',
                        value: '6550688903194476568',
                    },
                    {
                        label: '海伦市',
                        value: '6550688903194476569',
                    },
                    {
                        label: '望奎县',
                        value: '6550688903194476570',
                    },
                    {
                        label: '兰西县',
                        value: '6550688903194476571',
                    },
                    {
                        label: '青冈县',
                        value: '6550688903194476572',
                    },
                    {
                        label: '庆安县',
                        value: '6550688903198670848',
                    },
                    {
                        label: '明水县',
                        value: '6550688903198670849',
                    },
                    {
                        label: '绥棱县',
                        value: '6550688903198670850',
                    },
                ],
            },
            {
                label: '大兴安岭地区',
                value: '6550688903198670851',
                children: [
                    {
                        label: '呼玛县',
                        value: '6550688903198670852',
                    },
                    {
                        label: '塔河县',
                        value: '6550688903198670853',
                    },
                    {
                        label: '漠河县',
                        value: '6550688903198670854',
                    },
                    {
                        label: '加格达奇区',
                        value: '6550688903198670855',
                    },
                    {
                        label: '松岭区',
                        value: '6550688903198670856',
                    },
                    {
                        label: '新林区',
                        value: '6550688903198670857',
                    },
                    {
                        label: '呼中区',
                        value: '6550688903198670858',
                    },
                ],
            },
        ],
    },
    {
        label: '上海市',
        value: '6550688903198670859',
        children: [
            {
                label: '上海市',
                value: '6550688903198670860',
                children: [
                    {
                        label: '黄浦区',
                        value: '6550688903198670861',
                    },
                    {
                        label: '徐汇区',
                        value: '6550688903198670862',
                    },
                    {
                        label: '长宁区',
                        value: '6550688903198670863',
                    },
                    {
                        label: '杨浦区',
                        value: '6550688903198670864',
                    },
                    {
                        label: '虹口区',
                        value: '6550688903198670865',
                    },
                    {
                        label: '闵行区',
                        value: '6550688903198670866',
                    },
                    {
                        label: '普陀区',
                        value: '6550688903198670867',
                    },
                    {
                        label: '宝山区',
                        value: '6550688903198670868',
                    },
                    {
                        label: '静安区',
                        value: '6550688903198670869',
                    },
                    {
                        label: '松江区',
                        value: '6550688903198670870',
                    },
                    {
                        label: '嘉定区',
                        value: '6550688903198670871',
                    },
                    {
                        label: '金山区',
                        value: '6550688903198670872',
                    },
                    {
                        label: '青浦区',
                        value: '6550688903198670873',
                    },
                    {
                        label: '奉贤区',
                        value: '6550688903198670874',
                    },
                    {
                        label: '浦东新区',
                        value: '6550688903198670875',
                    },
                    {
                        label: '崇明区',
                        value: '6550688903198670876',
                    },
                ],
            },
        ],
    },
    {
        label: '江苏省',
        value: '6550688903202865152',
        children: [
            {
                label: '南京市',
                value: '6550688903202865153',
                children: [
                    {
                        label: '玄武区',
                        value: '6550688903202865154',
                    },
                    {
                        label: '秦淮区',
                        value: '6550688903202865155',
                    },
                    {
                        label: '建邺区',
                        value: '6550688903202865156',
                    },
                    {
                        label: '鼓楼区',
                        value: '6550688903202865157',
                    },
                    {
                        label: '浦口区',
                        value: '6550688903202865158',
                    },
                    {
                        label: '栖霞区',
                        value: '6550688903202865159',
                    },
                    {
                        label: '雨花台区',
                        value: '6550688903202865160',
                    },
                    {
                        label: '江宁区',
                        value: '6550688903202865161',
                    },
                    {
                        label: '六合区',
                        value: '6550688903202865162',
                    },
                    {
                        label: '溧水区',
                        value: '6550688903202865163',
                    },
                    {
                        label: '高淳区',
                        value: '6550688903202865164',
                    },
                ],
            },
            {
                label: '无锡市',
                value: '6550688903202865165',
                children: [
                    {
                        label: '滨湖区',
                        value: '6550688903202865166',
                    },
                    {
                        label: '梁溪区',
                        value: '6550688903202865167',
                    },
                    {
                        label: '锡山区',
                        value: '6550688903202865168',
                    },
                    {
                        label: '惠山区',
                        value: '6550688903202865169',
                    },
                    {
                        label: '新吴区',
                        value: '6550688903202865170',
                    },
                    {
                        label: '江阴市',
                        value: '6550688903202865171',
                    },
                    {
                        label: '宜兴市',
                        value: '6550688903202865172',
                    },
                ],
            },
            {
                label: '徐州市',
                value: '6550688903202865173',
                children: [
                    {
                        label: '鼓楼区',
                        value: '6550688903202865174',
                    },
                    {
                        label: '云龙区',
                        value: '6550688903202865175',
                    },
                    {
                        label: '贾汪区',
                        value: '6550688903202865176',
                    },
                    {
                        label: '泉山区',
                        value: '6550688903202865177',
                    },
                    {
                        label: '铜山区',
                        value: '6550688903202865178',
                    },
                    {
                        label: '丰县',
                        value: '6550688903207059456',
                    },
                    {
                        label: '沛县',
                        value: '6550688903207059457',
                    },
                    {
                        label: '睢宁县',
                        value: '6550688903207059458',
                    },
                    {
                        label: '新沂市',
                        value: '6550688903207059459',
                    },
                    {
                        label: '邳州市',
                        value: '6550688903207059460',
                    },
                ],
            },
            {
                label: '常州市',
                value: '6550688903207059461',
                children: [
                    {
                        label: '天宁区',
                        value: '6550688903207059462',
                    },
                    {
                        label: '钟楼区',
                        value: '6550688903207059463',
                    },
                    {
                        label: '新北区',
                        value: '6550688903207059464',
                    },
                    {
                        label: '武进区',
                        value: '6550688903207059465',
                    },
                    {
                        label: '金坛区',
                        value: '6550688903207059466',
                    },
                    {
                        label: '溧阳市',
                        value: '6550688903207059467',
                    },
                ],
            },
            {
                label: '苏州市',
                value: '6550688903207059468',
                children: [
                    {
                        label: '姑苏区',
                        value: '6550688903207059469',
                    },
                    {
                        label: '虎丘区',
                        value: '6550688903207059470',
                    },
                    {
                        label: '吴中区',
                        value: '6550688903207059471',
                    },
                    {
                        label: '相城区',
                        value: '6550688903207059472',
                    },
                    {
                        label: '吴江区',
                        value: '6550688903207059473',
                    },
                    {
                        label: '常熟市',
                        value: '6550688903207059474',
                    },
                    {
                        label: '张家港市',
                        value: '6550688903207059475',
                    },
                    {
                        label: '昆山市',
                        value: '6550688903207059476',
                    },
                    {
                        label: '太仓市',
                        value: '6550688903207059477',
                    },
                ],
            },
            {
                label: '南通市',
                value: '6550688903207059478',
                children: [
                    {
                        label: '崇川区',
                        value: '6550688903207059479',
                    },
                    {
                        label: '港闸区',
                        value: '6550688903207059480',
                    },
                    {
                        label: '通州区',
                        value: '6550688903207059481',
                    },
                    {
                        label: '如东县',
                        value: '6550688903207059482',
                    },
                    {
                        label: '海安县',
                        value: '6550688903207059483',
                    },
                    {
                        label: '启东市',
                        value: '6550688903207059484',
                    },
                    {
                        label: '如皋市',
                        value: '6550688903211253760',
                    },
                    {
                        label: '海门市',
                        value: '6550688903211253761',
                    },
                ],
            },
            {
                label: '连云港市',
                value: '6550688903211253762',
                children: [
                    {
                        label: '连云区',
                        value: '6550688903211253763',
                    },
                    {
                        label: '赣榆区',
                        value: '6550688903211253764',
                    },
                    {
                        label: '新浦区',
                        value: '6550688903211253765',
                    },
                    {
                        label: '东海县',
                        value: '6550688903211253766',
                    },
                    {
                        label: '灌云县',
                        value: '6550688903211253767',
                    },
                    {
                        label: '灌南县',
                        value: '6550688903211253768',
                    },
                ],
            },
            {
                label: '淮安市',
                value: '6550688903211253769',
                children: [
                    {
                        label: '淮安区',
                        value: '6550688903211253770',
                    },
                    {
                        label: '清江浦区',
                        value: '6550688903211253771',
                    },
                    {
                        label: '淮阴区',
                        value: '6550688903211253772',
                    },
                    {
                        label: '洪泽区',
                        value: '6550688903211253773',
                    },
                    {
                        label: '涟水县',
                        value: '6550688903211253774',
                    },
                    {
                        label: '盱眙县',
                        value: '6550688903211253775',
                    },
                    {
                        label: '金湖县',
                        value: '6550688903211253776',
                    },
                ],
            },
            {
                label: '盐城市',
                value: '6550688903211253777',
                children: [
                    {
                        label: '亭湖区',
                        value: '6550688903211253778',
                    },
                    {
                        label: '大丰区',
                        value: '6550688903215448064',
                    },
                    {
                        label: '盐都区',
                        value: '6550688903215448065',
                    },
                    {
                        label: '响水县',
                        value: '6550688903215448066',
                    },
                    {
                        label: '滨海县',
                        value: '6550688903215448067',
                    },
                    {
                        label: '阜宁县',
                        value: '6550688903215448068',
                    },
                    {
                        label: '射阳县',
                        value: '6550688903215448069',
                    },
                    {
                        label: '建湖县',
                        value: '6550688903215448070',
                    },
                    {
                        label: '东台市',
                        value: '6550688903215448071',
                    },
                ],
            },
            {
                label: '扬州市',
                value: '6550688903215448072',
                children: [
                    {
                        label: '广陵区',
                        value: '6550688903215448073',
                    },
                    {
                        label: '江都区',
                        value: '6550688903215448074',
                    },
                    {
                        label: '邗江区',
                        value: '6550688903215448075',
                    },
                    {
                        label: '宝应县',
                        value: '6550688903215448076',
                    },
                    {
                        label: '仪征市',
                        value: '6550688903215448077',
                    },
                    {
                        label: '高邮市',
                        value: '6550688903215448078',
                    },
                ],
            },
            {
                label: '镇江市',
                value: '6550688903215448079',
                children: [
                    {
                        label: '京口区',
                        value: '6550688903215448080',
                    },
                    {
                        label: '润州区',
                        value: '6550688903215448081',
                    },
                    {
                        label: '丹徒区',
                        value: '6550688903215448082',
                    },
                    {
                        label: '丹阳市',
                        value: '6550688903215448083',
                    },
                    {
                        label: '扬中市',
                        value: '6550688903215448084',
                    },
                    {
                        label: '句容市',
                        value: '6550688903215448085',
                    },
                ],
            },
            {
                label: '泰州市',
                value: '6550688903215448086',
                children: [
                    {
                        label: '海陵区',
                        value: '6550688903219642368',
                    },
                    {
                        label: '高港区',
                        value: '6550688903219642369',
                    },
                    {
                        label: '姜堰区',
                        value: '6550688903219642370',
                    },
                    {
                        label: '兴化市',
                        value: '6550688903219642371',
                    },
                    {
                        label: '靖江市',
                        value: '6550688903219642372',
                    },
                    {
                        label: '泰兴市',
                        value: '6550688903219642373',
                    },
                ],
            },
            {
                label: '宿迁市',
                value: '6550688903219642374',
                children: [
                    {
                        label: '宿城区',
                        value: '6550688903219642375',
                    },
                    {
                        label: '宿豫区',
                        value: '6550688903219642376',
                    },
                    {
                        label: '沭阳县',
                        value: '6550688903219642377',
                    },
                    {
                        label: '泗阳县',
                        value: '6550688903219642378',
                    },
                    {
                        label: '泗洪县',
                        value: '6550688903219642379',
                    },
                ],
            },
        ],
    },
    {
        label: '浙江省',
        value: '6550688903219642380',
        children: [
            {
                label: '杭州市',
                value: '6550688903219642381',
                children: [
                    {
                        label: '上城区',
                        value: '6550688903219642382',
                    },
                    {
                        label: '下城区',
                        value: '6550688903219642383',
                    },
                    {
                        label: '江干区',
                        value: '6550688903219642384',
                    },
                    {
                        label: '拱墅区',
                        value: '6550688903219642385',
                    },
                    {
                        label: '西湖区',
                        value: '6550688903219642386',
                    },
                    {
                        label: '滨江区',
                        value: '6550688903219642387',
                    },
                    {
                        label: '余杭区',
                        value: '6550688903219642388',
                    },
                    {
                        label: '富阳区',
                        value: '6550688903219642389',
                    },
                    {
                        label: '萧山市',
                        value: '6550688903219642390',
                    },
                    {
                        label: '桐庐县',
                        value: '6550688903223836672',
                    },
                    {
                        label: '淳安县',
                        value: '6550688903223836673',
                    },
                    {
                        label: '建德市',
                        value: '6550688903223836674',
                    },
                    {
                        label: '临安市',
                        value: '6550688903223836675',
                    },
                ],
            },
            {
                label: '宁波市',
                value: '6550688903223836676',
                children: [
                    {
                        label: '海曙区',
                        value: '6550688903223836677',
                    },
                    {
                        label: '江北区',
                        value: '6550688903223836678',
                    },
                    {
                        label: '北仑区',
                        value: '6550688903223836679',
                    },
                    {
                        label: '镇海区',
                        value: '6550688903223836680',
                    },
                    {
                        label: '鄞州区',
                        value: '6550688903223836681',
                    },
                    {
                        label: '奉化区',
                        value: '6550688903223836682',
                    },
                    {
                        label: '象山县',
                        value: '6550688903223836683',
                    },
                    {
                        label: '宁海县',
                        value: '6550688903223836684',
                    },
                    {
                        label: '余姚市',
                        value: '6550688903223836685',
                    },
                    {
                        label: '慈溪市',
                        value: '6550688903223836686',
                    },
                ],
            },
            {
                label: '温州市',
                value: '6550688903223836687',
                children: [
                    {
                        label: '鹿城区',
                        value: '6550688903223836688',
                    },
                    {
                        label: '龙湾区',
                        value: '6550688903223836689',
                    },
                    {
                        label: '瓯海区',
                        value: '6550688903223836690',
                    },
                    {
                        label: '洞头区',
                        value: '6550688903223836691',
                    },
                    {
                        label: '永嘉县',
                        value: '6550688903223836692',
                    },
                    {
                        label: '平阳县',
                        value: '6550688903223836693',
                    },
                    {
                        label: '苍南县',
                        value: '6550688903223836694',
                    },
                    {
                        label: '文成县',
                        value: '6550688903223836695',
                    },
                    {
                        label: '泰顺县',
                        value: '6550688903223836696',
                    },
                    {
                        label: '瑞安市',
                        value: '6550688903223836697',
                    },
                    {
                        label: '乐清市',
                        value: '6550688903223836698',
                    },
                ],
            },
            {
                label: '嘉兴市',
                value: '6550688903228030976',
                children: [
                    {
                        label: '南湖区',
                        value: '6550688903228030977',
                    },
                    {
                        label: '秀洲区',
                        value: '6550688903228030978',
                    },
                    {
                        label: '嘉善县',
                        value: '6550688903228030979',
                    },
                    {
                        label: '海盐县',
                        value: '6550688903228030980',
                    },
                    {
                        label: '海宁市',
                        value: '6550688903228030981',
                    },
                    {
                        label: '平湖市',
                        value: '6550688903228030982',
                    },
                    {
                        label: '桐乡市',
                        value: '6550688903228030983',
                    },
                ],
            },
            {
                label: '湖州市',
                value: '6550688903228030984',
                children: [
                    {
                        label: '吴兴区',
                        value: '6550688903228030985',
                    },
                    {
                        label: '南浔区',
                        value: '6550688903228030986',
                    },
                    {
                        label: '德清县',
                        value: '6550688903228030987',
                    },
                    {
                        label: '长兴县',
                        value: '6550688903228030988',
                    },
                    {
                        label: '安吉县',
                        value: '6550688903228030989',
                    },
                ],
            },
            {
                label: '绍兴市',
                value: '6550688903228030990',
                children: [
                    {
                        label: '越城区',
                        value: '6550688903228030991',
                    },
                    {
                        label: '柯桥区',
                        value: '6550688903228030992',
                    },
                    {
                        label: '上虞区',
                        value: '6550688903228030993',
                    },
                    {
                        label: '新昌县',
                        value: '6550688903228030994',
                    },
                    {
                        label: '诸暨市',
                        value: '6550688903228030995',
                    },
                    {
                        label: '嵊州市',
                        value: '6550688903228030996',
                    },
                ],
            },
            {
                label: '金华市',
                value: '6550688903228030997',
                children: [
                    {
                        label: '婺城区',
                        value: '6550688903228030998',
                    },
                    {
                        label: '金东区',
                        value: '6550688903228030999',
                    },
                    {
                        label: '武义县',
                        value: '6550688903232225280',
                    },
                    {
                        label: '浦江县',
                        value: '6550688903232225281',
                    },
                    {
                        label: '磐安县',
                        value: '6550688903232225282',
                    },
                    {
                        label: '兰溪市',
                        value: '6550688903232225283',
                    },
                    {
                        label: '义乌市',
                        value: '6550688903232225284',
                    },
                    {
                        label: '东阳市',
                        value: '6550688903232225285',
                    },
                    {
                        label: '永康市',
                        value: '6550688903232225286',
                    },
                ],
            },
            {
                label: '衢州市',
                value: '6550688903232225287',
                children: [
                    {
                        label: '柯城区',
                        value: '6550688903232225288',
                    },
                    {
                        label: '衢江区',
                        value: '6550688903232225289',
                    },
                    {
                        label: '常山县',
                        value: '6550688903232225290',
                    },
                    {
                        label: '开化县',
                        value: '6550688903232225291',
                    },
                    {
                        label: '龙游县',
                        value: '6550688903232225292',
                    },
                    {
                        label: '江山市',
                        value: '6550688903232225293',
                    },
                ],
            },
            {
                label: '舟山市',
                value: '6550688903232225294',
                children: [
                    {
                        label: '定海区',
                        value: '6550688903232225295',
                    },
                    {
                        label: '普陀区',
                        value: '6550688903232225296',
                    },
                    {
                        label: '岱山县',
                        value: '6550688903232225297',
                    },
                    {
                        label: '嵊泗县',
                        value: '6550688903232225298',
                    },
                ],
            },
            {
                label: '台州市',
                value: '6550688903232225299',
                children: [
                    {
                        label: '椒江区',
                        value: '6550688903232225300',
                    },
                    {
                        label: '黄岩区',
                        value: '6550688903232225301',
                    },
                    {
                        label: '路桥区',
                        value: '6550688903232225302',
                    },
                    {
                        label: '玉环县',
                        value: '6550688903232225303',
                    },
                    {
                        label: '三门县',
                        value: '6550688903232225304',
                    },
                    {
                        label: '天台县',
                        value: '6550688903236419584',
                    },
                    {
                        label: '仙居县',
                        value: '6550688903236419585',
                    },
                    {
                        label: '温岭市',
                        value: '6550688903236419586',
                    },
                    {
                        label: '临海市',
                        value: '6550688903236419587',
                    },
                ],
            },
            {
                label: '丽水市',
                value: '6550688903236419588',
                children: [
                    {
                        label: '莲都区',
                        value: '6550688903236419589',
                    },
                    {
                        label: '龙泉市',
                        value: '6550688903236419590',
                    },
                    {
                        label: '青田县',
                        value: '6550688903236419591',
                    },
                    {
                        label: '云和县',
                        value: '6550688903236419592',
                    },
                    {
                        label: '庆元县',
                        value: '6550688903236419593',
                    },
                    {
                        label: '缙云县',
                        value: '6550688903236419594',
                    },
                    {
                        label: '遂昌县',
                        value: '6550688903236419595',
                    },
                    {
                        label: '松阳县',
                        value: '6550688903236419596',
                    },
                    {
                        label: '景宁畲族自治县',
                        value: '6550688903236419597',
                    },
                ],
            },
        ],
    },
    {
        label: '安徽省',
        value: '6550688903236419598',
        children: [
            {
                label: '合肥市',
                value: '6550688903236419599',
                children: [
                    {
                        label: '包河区',
                        value: '6550688903236419600',
                    },
                    {
                        label: '蜀山区',
                        value: '6550688903236419601',
                    },
                    {
                        label: '庐阳区',
                        value: '6550688903236419602',
                    },
                    {
                        label: '瑶海区',
                        value: '6550688903236419603',
                    },
                    {
                        label: '庐江县',
                        value: '6550688903236419604',
                    },
                    {
                        label: '长丰县',
                        value: '6550688903236419605',
                    },
                    {
                        label: '肥东县',
                        value: '6550688903236419606',
                    },
                    {
                        label: '肥西县',
                        value: '6550688903236419607',
                    },
                    {
                        label: '巢湖市',
                        value: '6550688903236419608',
                    },
                ],
            },
            {
                label: '芜湖市',
                value: '6550688903236419609',
                children: [
                    {
                        label: '镜湖区',
                        value: '6550688903240613888',
                    },
                    {
                        label: '弋江区',
                        value: '6550688903240613889',
                    },
                    {
                        label: '三山区',
                        value: '6550688903240613890',
                    },
                    {
                        label: '鸠江区',
                        value: '6550688903240613891',
                    },
                    {
                        label: '芜湖县',
                        value: '6550688903240613892',
                    },
                    {
                        label: '繁昌县',
                        value: '6550688903240613893',
                    },
                    {
                        label: '无为县',
                        value: '6550688903240613894',
                    },
                    {
                        label: '南陵县',
                        value: '6550688903240613895',
                    },
                ],
            },
            {
                label: '蚌埠市',
                value: '6550688903240613896',
                children: [
                    {
                        label: '龙子湖区',
                        value: '6550688903240613897',
                    },
                    {
                        label: '蚌山区',
                        value: '6550688903240613898',
                    },
                    {
                        label: '禹会区',
                        value: '6550688903240613899',
                    },
                    {
                        label: '淮上区',
                        value: '6550688903240613900',
                    },
                    {
                        label: '怀远县',
                        value: '6550688903240613901',
                    },
                    {
                        label: '五河县',
                        value: '6550688903240613902',
                    },
                    {
                        label: '固镇县',
                        value: '6550688903240613903',
                    },
                ],
            },
            {
                label: '淮南市',
                value: '6550688903240613904',
                children: [
                    {
                        label: '大通区',
                        value: '6550688903240613905',
                    },
                    {
                        label: '田家庵区',
                        value: '6550688903240613906',
                    },
                    {
                        label: '谢家集区',
                        value: '6550688903240613907',
                    },
                    {
                        label: '八公山区',
                        value: '6550688903240613908',
                    },
                    {
                        label: '潘集区',
                        value: '6550688903244808192',
                    },
                    {
                        label: '寿县',
                        value: '6550688903244808193',
                    },
                    {
                        label: '凤台县',
                        value: '6550688903244808194',
                    },
                ],
            },
            {
                label: '马鞍山市',
                value: '6550688903244808195',
                children: [
                    {
                        label: '博望区',
                        value: '6550688903244808196',
                    },
                    {
                        label: '花山区',
                        value: '6550688903244808197',
                    },
                    {
                        label: '雨山区',
                        value: '6550688903244808198',
                    },
                    {
                        label: '和县',
                        value: '6550688903244808199',
                    },
                    {
                        label: '含山县',
                        value: '6550688903244808200',
                    },
                    {
                        label: '当涂县',
                        value: '6550688903244808201',
                    },
                ],
            },
            {
                label: '淮北市',
                value: '6550688903244808202',
                children: [
                    {
                        label: '杜集区',
                        value: '6550688903244808203',
                    },
                    {
                        label: '相山区',
                        value: '6550688903244808204',
                    },
                    {
                        label: '烈山区',
                        value: '6550688903244808205',
                    },
                    {
                        label: '濉溪县',
                        value: '6550688903244808206',
                    },
                ],
            },
            {
                label: '铜陵市',
                value: '6550688903244808207',
                children: [
                    {
                        label: '铜官区',
                        value: '6550688903244808208',
                    },
                    {
                        label: '义安区',
                        value: '6550688903244808209',
                    },
                    {
                        label: '郊区',
                        value: '6550688903249002496',
                    },
                    {
                        label: '枞阳县',
                        value: '6550688903249002497',
                    },
                ],
            },
            {
                label: '安庆市',
                value: '6550688903249002498',
                children: [
                    {
                        label: '迎江区',
                        value: '6550688903249002499',
                    },
                    {
                        label: '大观区',
                        value: '6550688903249002500',
                    },
                    {
                        label: '宜秀区',
                        value: '6550688903249002501',
                    },
                    {
                        label: '怀宁县',
                        value: '6550688903249002502',
                    },
                    {
                        label: '潜山县',
                        value: '6550688903249002503',
                    },
                    {
                        label: '太湖县',
                        value: '6550688903249002504',
                    },
                    {
                        label: '宿松县',
                        value: '6550688903249002505',
                    },
                    {
                        label: '望江县',
                        value: '6550688903249002506',
                    },
                    {
                        label: '岳西县',
                        value: '6550688903249002507',
                    },
                    {
                        label: '桐城市',
                        value: '6550688903249002508',
                    },
                ],
            },
            {
                label: '黄山市',
                value: '6550688903249002509',
                children: [
                    {
                        label: '屯溪区',
                        value: '6550688903249002510',
                    },
                    {
                        label: '黄山区',
                        value: '6550688903249002511',
                    },
                    {
                        label: '徽州区',
                        value: '6550688903249002512',
                    },
                    {
                        label: '歙县',
                        value: '6550688903249002513',
                    },
                    {
                        label: '休宁县',
                        value: '6550688903249002514',
                    },
                    {
                        label: '黟县',
                        value: '6550688903253196800',
                    },
                    {
                        label: '祁门县',
                        value: '6550688903253196801',
                    },
                ],
            },
            {
                label: '滁州市',
                value: '6550688903253196802',
                children: [
                    {
                        label: '琅琊区',
                        value: '6550688903253196803',
                    },
                    {
                        label: '南谯区',
                        value: '6550688903253196804',
                    },
                    {
                        label: '来安县',
                        value: '6550688903253196805',
                    },
                    {
                        label: '全椒县',
                        value: '6550688903253196806',
                    },
                    {
                        label: '定远县',
                        value: '6550688903253196807',
                    },
                    {
                        label: '凤阳县',
                        value: '6550688903253196808',
                    },
                    {
                        label: '天长市',
                        value: '6550688903253196809',
                    },
                    {
                        label: '明光市',
                        value: '6550688903253196810',
                    },
                ],
            },
            {
                label: '阜阳市',
                value: '6550688903253196811',
                children: [
                    {
                        label: '颍州区',
                        value: '6550688903253196812',
                    },
                    {
                        label: '颍东区',
                        value: '6550688903253196813',
                    },
                    {
                        label: '颍泉区',
                        value: '6550688903253196814',
                    },
                    {
                        label: '临泉县',
                        value: '6550688903253196815',
                    },
                    {
                        label: '太和县',
                        value: '6550688903253196816',
                    },
                    {
                        label: '阜南县',
                        value: '6550688903253196817',
                    },
                    {
                        label: '颍上县',
                        value: '6550688903253196818',
                    },
                    {
                        label: '界首市',
                        value: '6550688903253196819',
                    },
                ],
            },
            {
                label: '宿州市',
                value: '6550688903253196820',
                children: [
                    {
                        label: '墉桥区',
                        value: '6550688903253196821',
                    },
                    {
                        label: '砀山县',
                        value: '6550688903253196822',
                    },
                    {
                        label: '萧县',
                        value: '6550688903253196823',
                    },
                    {
                        label: '灵璧县',
                        value: '6550688903253196824',
                    },
                    {
                        label: '泗县',
                        value: '6550688903253196825',
                    },
                ],
            },
            {
                label: '六安市',
                value: '6550688903257391104',
                children: [
                    {
                        label: '金安区',
                        value: '6550688903257391105',
                    },
                    {
                        label: '裕安区',
                        value: '6550688903257391106',
                    },
                    {
                        label: '叶集区',
                        value: '6550688903257391107',
                    },
                    {
                        label: '霍邱县',
                        value: '6550688903257391108',
                    },
                    {
                        label: '舒城县',
                        value: '6550688903257391109',
                    },
                    {
                        label: '金寨县',
                        value: '6550688903257391110',
                    },
                    {
                        label: '霍山县',
                        value: '6550688903257391111',
                    },
                ],
            },
            {
                label: '宣城市',
                value: '6550688903257391112',
                children: [
                    {
                        label: '宣州区',
                        value: '6550688903257391113',
                    },
                    {
                        label: '宁国市',
                        value: '6550688903257391114',
                    },
                    {
                        label: '郎溪县',
                        value: '6550688903257391115',
                    },
                    {
                        label: '广德县',
                        value: '6550688903257391116',
                    },
                    {
                        label: '泾县',
                        value: '6550688903257391117',
                    },
                    {
                        label: '旌德县',
                        value: '6550688903257391118',
                    },
                    {
                        label: '绩溪县',
                        value: '6550688903257391119',
                    },
                ],
            },
            {
                label: '亳州市',
                value: '6550688903257391120',
                children: [
                    {
                        label: '谯城区',
                        value: '6550688903257391121',
                    },
                    {
                        label: '涡阳县',
                        value: '6550688903257391122',
                    },
                    {
                        label: '蒙城县',
                        value: '6550688903257391123',
                    },
                    {
                        label: '利辛县',
                        value: '6550688903257391124',
                    },
                ],
            },
            {
                label: '池州市',
                value: '6550688903257391125',
                children: [
                    {
                        label: '贵池区',
                        value: '6550688903257391126',
                    },
                    {
                        label: '东至县',
                        value: '6550688903257391127',
                    },
                    {
                        label: '石台县',
                        value: '6550688903257391128',
                    },
                    {
                        label: '青阳县',
                        value: '6550688903257391129',
                    },
                ],
            },
        ],
    },
    {
        label: '福建省',
        value: '6550688903257391130',
        children: [
            {
                label: '福州市',
                value: '6550688903257391131',
                children: [
                    {
                        label: '鼓楼区',
                        value: '6550688903261585408',
                    },
                    {
                        label: '台江区',
                        value: '6550688903261585409',
                    },
                    {
                        label: '仓山区',
                        value: '6550688903261585410',
                    },
                    {
                        label: '马尾区',
                        value: '6550688903261585411',
                    },
                    {
                        label: '晋安区',
                        value: '6550688903261585412',
                    },
                    {
                        label: '闽侯县',
                        value: '6550688903261585413',
                    },
                    {
                        label: '连江县',
                        value: '6550688903261585414',
                    },
                    {
                        label: '罗源县',
                        value: '6550688903261585415',
                    },
                    {
                        label: '闽清县',
                        value: '6550688903261585416',
                    },
                    {
                        label: '永泰县',
                        value: '6550688903261585417',
                    },
                    {
                        label: '平潭县',
                        value: '6550688903261585418',
                    },
                    {
                        label: '福清市',
                        value: '6550688903261585419',
                    },
                    {
                        label: '长乐市',
                        value: '6550688903261585420',
                    },
                ],
            },
            {
                label: '厦门市',
                value: '6550688903261585421',
                children: [
                    {
                        label: '思明区 ',
                        value: '6550688903261585422',
                    },
                    {
                        label: '海沧区',
                        value: '6550688903261585423',
                    },
                    {
                        label: '湖里区',
                        value: '6550688903261585424',
                    },
                    {
                        label: '集美区',
                        value: '6550688903261585425',
                    },
                    {
                        label: '同安区',
                        value: '6550688903261585426',
                    },
                    {
                        label: '翔安区',
                        value: '6550688903261585427',
                    },
                ],
            },
            {
                label: '宁德市',
                value: '6550688903261585428',
                children: [
                    {
                        label: '蕉城区',
                        value: '6550688903261585429',
                    },
                    {
                        label: '霞浦县',
                        value: '6550688903261585430',
                    },
                    {
                        label: '古田县',
                        value: '6550688903261585431',
                    },
                    {
                        label: '屏南县',
                        value: '6550688903261585432',
                    },
                    {
                        label: '寿宁县',
                        value: '6550688903261585433',
                    },
                    {
                        label: '周宁县',
                        value: '6550688903261585434',
                    },
                    {
                        label: '柘荣县',
                        value: '6550688903265779712',
                    },
                    {
                        label: '福安市',
                        value: '6550688903265779713',
                    },
                    {
                        label: '福鼎市',
                        value: '6550688903265779714',
                    },
                ],
            },
            {
                label: '莆田市',
                value: '6550688903265779715',
                children: [
                    {
                        label: '城厢区',
                        value: '6550688903265779716',
                    },
                    {
                        label: '涵江区',
                        value: '6550688903265779717',
                    },
                    {
                        label: '荔城区',
                        value: '6550688903265779718',
                    },
                    {
                        label: '秀屿区',
                        value: '6550688903265779719',
                    },
                    {
                        label: '仙游县',
                        value: '6550688903265779720',
                    },
                ],
            },
            {
                label: '泉州市',
                value: '6550688903265779721',
                children: [
                    {
                        label: '鲤城区',
                        value: '6550688903265779722',
                    },
                    {
                        label: '丰泽区',
                        value: '6550688903265779723',
                    },
                    {
                        label: '洛江区',
                        value: '6550688903265779724',
                    },
                    {
                        label: '泉港区',
                        value: '6550688903265779725',
                    },
                    {
                        label: '惠安县',
                        value: '6550688903265779726',
                    },
                    {
                        label: '安溪县',
                        value: '6550688903265779727',
                    },
                    {
                        label: '永春县',
                        value: '6550688903265779728',
                    },
                    {
                        label: '德化县',
                        value: '6550688903265779729',
                    },
                    {
                        label: '金门县',
                        value: '6550688903265779730',
                    },
                    {
                        label: '石狮市',
                        value: '6550688903265779731',
                    },
                    {
                        label: '晋江市',
                        value: '6550688903265779732',
                    },
                    {
                        label: '南安市',
                        value: '6550688903265779733',
                    },
                ],
            },
            {
                label: '漳州市',
                value: '6550688903265779734',
                children: [
                    {
                        label: '芗城区',
                        value: '6550688903265779735',
                    },
                    {
                        label: '龙文区',
                        value: '6550688903265779736',
                    },
                    {
                        label: '云霄县',
                        value: '6550688903265779737',
                    },
                    {
                        label: '漳浦县',
                        value: '6550688903265779738',
                    },
                    {
                        label: '诏安县',
                        value: '6550688903269974016',
                    },
                    {
                        label: '长泰县',
                        value: '6550688903269974017',
                    },
                    {
                        label: '东山县',
                        value: '6550688903269974018',
                    },
                    {
                        label: '南靖县',
                        value: '6550688903269974019',
                    },
                    {
                        label: '平和县',
                        value: '6550688903269974020',
                    },
                    {
                        label: '华安县',
                        value: '6550688903269974021',
                    },
                    {
                        label: '龙海市',
                        value: '6550688903269974022',
                    },
                ],
            },
            {
                label: '龙岩',
                value: '6550688903269974023',
                children: [
                    {
                        label: '新罗区',
                        value: '6550688903269974024',
                    },
                    {
                        label: '永定区',
                        value: '6550688903269974025',
                    },
                    {
                        label: '长汀县',
                        value: '6550688903269974026',
                    },
                    {
                        label: '上杭县',
                        value: '6550688903269974027',
                    },
                    {
                        label: '武平县',
                        value: '6550688903269974028',
                    },
                    {
                        label: '连城县',
                        value: '6550688903269974029',
                    },
                    {
                        label: '漳平市',
                        value: '6550688903269974030',
                    },
                ],
            },
            {
                label: '三明市',
                value: '6550688903269974031',
                children: [
                    {
                        label: '梅列区',
                        value: '6550688903269974032',
                    },
                    {
                        label: '三元区',
                        value: '6550688903269974033',
                    },
                    {
                        label: '明溪县',
                        value: '6550688903269974034',
                    },
                    {
                        label: '清流县',
                        value: '6550688903269974035',
                    },
                    {
                        label: '宁化县',
                        value: '6550688903269974036',
                    },
                    {
                        label: '大田县',
                        value: '6550688903269974037',
                    },
                    {
                        label: '尤溪县',
                        value: '6550688903269974038',
                    },
                    {
                        label: '沙县',
                        value: '6550688903269974039',
                    },
                    {
                        label: '将乐县',
                        value: '6550688903269974040',
                    },
                    {
                        label: '泰宁县',
                        value: '6550688903274168320',
                    },
                    {
                        label: '建宁县',
                        value: '6550688903274168321',
                    },
                    {
                        label: '永安市',
                        value: '6550688903274168322',
                    },
                ],
            },
            {
                label: '南平市',
                value: '6550688903274168323',
                children: [
                    {
                        label: '延平区',
                        value: '6550688903274168324',
                    },
                    {
                        label: '建阳区',
                        value: '6550688903274168325',
                    },
                    {
                        label: '顺昌县',
                        value: '6550688903274168326',
                    },
                    {
                        label: '浦城县',
                        value: '6550688903274168327',
                    },
                    {
                        label: '光泽县',
                        value: '6550688903274168328',
                    },
                    {
                        label: '松溪县',
                        value: '6550688903274168329',
                    },
                    {
                        label: '政和县',
                        value: '6550688903274168330',
                    },
                    {
                        label: '邵武市',
                        value: '6550688903274168331',
                    },
                    {
                        label: '武夷山市',
                        value: '6550688903274168332',
                    },
                    {
                        label: '建瓯市',
                        value: '6550688903274168333',
                    },
                ],
            },
        ],
    },
    {
        label: '江西省',
        value: '6550688903274168334',
        children: [
            {
                label: '南昌市',
                value: '6550688903274168335',
                children: [
                    {
                        label: '东湖区',
                        value: '6550688903274168336',
                    },
                    {
                        label: '西湖区',
                        value: '6550688903274168337',
                    },
                    {
                        label: '青云谱区',
                        value: '6550688903274168338',
                    },
                    {
                        label: '湾里区',
                        value: '6550688903274168339',
                    },
                    {
                        label: '青山湖区',
                        value: '6550688903274168340',
                    },
                    {
                        label: '新建区',
                        value: '6550688903274168341',
                    },
                    {
                        label: '南昌县',
                        value: '6550688903274168342',
                    },
                    {
                        label: '安义县',
                        value: '6550688903274168343',
                    },
                    {
                        label: '进贤县',
                        value: '6550688903274168344',
                    },
                ],
            },
            {
                label: '景德镇市',
                value: '6550688903274168345',
                children: [
                    {
                        label: '昌江区',
                        value: '6550688903274168346',
                    },
                    {
                        label: '珠山区',
                        value: '6550688903278362624',
                    },
                    {
                        label: '浮梁县',
                        value: '6550688903278362625',
                    },
                    {
                        label: '乐平市',
                        value: '6550688903278362626',
                    },
                ],
            },
            {
                label: '萍乡市',
                value: '6550688903278362627',
                children: [
                    {
                        label: '安源区',
                        value: '6550688903278362628',
                    },
                    {
                        label: '湘东区',
                        value: '6550688903278362629',
                    },
                    {
                        label: '莲花县',
                        value: '6550688903278362630',
                    },
                    {
                        label: '上栗县',
                        value: '6550688903278362631',
                    },
                    {
                        label: '芦溪县',
                        value: '6550688903278362632',
                    },
                ],
            },
            {
                label: '九江市',
                value: '6550688903278362633',
                children: [
                    {
                        label: '濂溪区',
                        value: '6550688903278362634',
                    },
                    {
                        label: '浔阳区',
                        value: '6550688903278362635',
                    },
                    {
                        label: '九江县',
                        value: '6550688903278362636',
                    },
                    {
                        label: '武宁县',
                        value: '6550688903278362637',
                    },
                    {
                        label: '修水县',
                        value: '6550688903278362638',
                    },
                    {
                        label: '永修县',
                        value: '6550688903278362639',
                    },
                    {
                        label: '德安县',
                        value: '6550688903278362640',
                    },
                    {
                        label: '都昌县',
                        value: '6550688903278362641',
                    },
                    {
                        label: '湖口县',
                        value: '6550688903278362642',
                    },
                    {
                        label: '彭泽县',
                        value: '6550688903278362643',
                    },
                    {
                        label: '瑞昌市',
                        value: '6550688903278362644',
                    },
                    {
                        label: '共青城市',
                        value: '6550688903278362645',
                    },
                    {
                        label: '庐山市',
                        value: '6550688903278362646',
                    },
                ],
            },
            {
                label: '新余市',
                value: '6550688903278362647',
                children: [
                    {
                        label: '渝水区',
                        value: '6550688903278362648',
                    },
                    {
                        label: '分宜县',
                        value: '6550688903278362649',
                    },
                ],
            },
            {
                label: '鹰潭市',
                value: '6550688903278362650',
                children: [
                    {
                        label: '月湖区',
                        value: '6550688903282556928',
                    },
                    {
                        label: '余江县',
                        value: '6550688903282556929',
                    },
                    {
                        label: '贵溪市',
                        value: '6550688903282556930',
                    },
                ],
            },
            {
                label: '赣州市',
                value: '6550688903282556931',
                children: [
                    {
                        label: '章贡区',
                        value: '6550688903282556932',
                    },
                    {
                        label: '南康区',
                        value: '6550688903282556933',
                    },
                    {
                        label: '赣县区',
                        value: '6550688903282556934',
                    },
                    {
                        label: '信丰县',
                        value: '6550688903282556935',
                    },
                    {
                        label: '大余县',
                        value: '6550688903282556936',
                    },
                    {
                        label: '上犹县',
                        value: '6550688903282556937',
                    },
                    {
                        label: '崇义县',
                        value: '6550688903282556938',
                    },
                    {
                        label: '安远县',
                        value: '6550688903282556939',
                    },
                    {
                        label: '龙南县',
                        value: '6550688903282556940',
                    },
                    {
                        label: '定南县',
                        value: '6550688903282556941',
                    },
                    {
                        label: '全南县',
                        value: '6550688903282556942',
                    },
                    {
                        label: '宁都县',
                        value: '6550688903282556943',
                    },
                    {
                        label: '于都县',
                        value: '6550688903282556944',
                    },
                    {
                        label: '兴国县',
                        value: '6550688903282556945',
                    },
                    {
                        label: '会昌县',
                        value: '6550688903282556946',
                    },
                    {
                        label: '寻乌县',
                        value: '6550688903282556947',
                    },
                    {
                        label: '石城县',
                        value: '6550688903282556948',
                    },
                    {
                        label: '瑞金市',
                        value: '6550688903282556949',
                    },
                ],
            },
            {
                label: '宜春市',
                value: '6550688903282556950',
                children: [
                    {
                        label: '袁州区',
                        value: '6550688903282556951',
                    },
                    {
                        label: '丰城市',
                        value: '6550688903282556952',
                    },
                    {
                        label: '樟树市',
                        value: '6550688903282556953',
                    },
                    {
                        label: '高安市',
                        value: '6550688903282556954',
                    },
                    {
                        label: '奉新县',
                        value: '6550688903282556955',
                    },
                    {
                        label: '万载县',
                        value: '6550688903286751232',
                    },
                    {
                        label: '上高县',
                        value: '6550688903286751233',
                    },
                    {
                        label: '宜丰县',
                        value: '6550688903286751234',
                    },
                    {
                        label: '靖安县',
                        value: '6550688903286751235',
                    },
                    {
                        label: '铜鼓县',
                        value: '6550688903286751236',
                    },
                ],
            },
            {
                label: '上饶市',
                value: '6550688903286751237',
                children: [
                    {
                        label: '信州区',
                        value: '6550688903286751238',
                    },
                    {
                        label: '广丰区',
                        value: '6550688903286751239',
                    },
                    {
                        label: '上饶县',
                        value: '6550688903286751240',
                    },
                    {
                        label: '玉山县',
                        value: '6550688903286751241',
                    },
                    {
                        label: '铅山县',
                        value: '6550688903286751242',
                    },
                    {
                        label: '横峰县',
                        value: '6550688903286751243',
                    },
                    {
                        label: '弋阳县',
                        value: '6550688903286751244',
                    },
                    {
                        label: '余干县',
                        value: '6550688903286751245',
                    },
                    {
                        label: '波阳县',
                        value: '6550688903286751246',
                    },
                    {
                        label: '万年县',
                        value: '6550688903286751247',
                    },
                    {
                        label: '婺源县',
                        value: '6550688903286751248',
                    },
                    {
                        label: '德兴市',
                        value: '6550688903286751249',
                    },
                ],
            },
            {
                label: '吉安市',
                value: '6550688903286751250',
                children: [
                    {
                        label: '吉州区',
                        value: '6550688903286751251',
                    },
                    {
                        label: '青原区',
                        value: '6550688903286751252',
                    },
                    {
                        label: '井冈山市',
                        value: '6550688903286751253',
                    },
                    {
                        label: '吉安县',
                        value: '6550688903286751254',
                    },
                    {
                        label: '吉水县',
                        value: '6550688903286751255',
                    },
                    {
                        label: '峡江县',
                        value: '6550688903286751256',
                    },
                    {
                        label: '新干县',
                        value: '6550688903290945536',
                    },
                    {
                        label: '永丰县',
                        value: '6550688903290945537',
                    },
                    {
                        label: '泰和县',
                        value: '6550688903290945538',
                    },
                    {
                        label: '遂川县',
                        value: '6550688903290945539',
                    },
                    {
                        label: '万安县',
                        value: '6550688903290945540',
                    },
                    {
                        label: '安福县',
                        value: '6550688903290945541',
                    },
                    {
                        label: '永新县',
                        value: '6550688903290945542',
                    },
                ],
            },
            {
                label: '抚州市',
                value: '6550688903290945543',
                children: [
                    {
                        label: '临川区',
                        value: '6550688903290945544',
                    },
                    {
                        label: '南城县',
                        value: '6550688903290945545',
                    },
                    {
                        label: '黎川县',
                        value: '6550688903290945546',
                    },
                    {
                        label: '南丰县',
                        value: '6550688903290945547',
                    },
                    {
                        label: '崇仁县',
                        value: '6550688903290945548',
                    },
                    {
                        label: '乐安县',
                        value: '6550688903290945549',
                    },
                    {
                        label: '宜黄县',
                        value: '6550688903290945550',
                    },
                    {
                        label: '金溪县',
                        value: '6550688903290945551',
                    },
                    {
                        label: '资溪县',
                        value: '6550688903290945552',
                    },
                    {
                        label: '东乡县',
                        value: '6550688903290945553',
                    },
                    {
                        label: '广昌县',
                        value: '6550688903290945554',
                    },
                ],
            },
        ],
    },
    {
        label: '山东省',
        value: '6550688903295139840',
        children: [
            {
                label: '济南市',
                value: '6550688903295139841',
                children: [
                    {
                        label: '历下区',
                        value: '6550688903295139842',
                    },
                    {
                        label: '市中区',
                        value: '6550688903295139843',
                    },
                    {
                        label: '槐荫区',
                        value: '6550688903295139844',
                    },
                    {
                        label: '天桥区',
                        value: '6550688903295139845',
                    },
                    {
                        label: '历城区',
                        value: '6550688903295139846',
                    },
                    {
                        label: '长清区',
                        value: '6550688903295139847',
                    },
                    {
                        label: '章丘区',
                        value: '6550688903295139848',
                    },
                    {
                        label: '济阳县',
                        value: '6550688903295139849',
                    },
                    {
                        label: '商河县',
                        value: '6550688903295139850',
                    },
                    {
                        label: '平阴县',
                        value: '6550688903295139851',
                    },
                ],
            },
            {
                label: '青岛市',
                value: '6550688903295139852',
                children: [
                    {
                        label: '市南区',
                        value: '6550688903295139853',
                    },
                    {
                        label: '市北区',
                        value: '6550688903295139854',
                    },
                    {
                        label: '黄岛区',
                        value: '6550688903295139855',
                    },
                    {
                        label: '崂山区',
                        value: '6550688903295139856',
                    },
                    {
                        label: '李沧区',
                        value: '6550688903295139857',
                    },
                    {
                        label: '城阳区',
                        value: '6550688903295139858',
                    },
                    {
                        label: '胶州市',
                        value: '6550688903295139859',
                    },
                    {
                        label: '即墨市',
                        value: '6550688903295139860',
                    },
                    {
                        label: '平度市',
                        value: '6550688903295139861',
                    },
                    {
                        label: '莱西市',
                        value: '6550688903295139862',
                    },
                ],
            },
            {
                label: '淄博市',
                value: '6550688903299334144',
                children: [
                    {
                        label: '淄川区',
                        value: '6550688903299334145',
                    },
                    {
                        label: '张店区',
                        value: '6550688903299334146',
                    },
                    {
                        label: '博山区',
                        value: '6550688903299334147',
                    },
                    {
                        label: '临淄区',
                        value: '6550688903299334148',
                    },
                    {
                        label: '周村区',
                        value: '6550688903299334149',
                    },
                    {
                        label: '桓台县',
                        value: '6550688903299334150',
                    },
                    {
                        label: '高青县',
                        value: '6550688903299334151',
                    },
                    {
                        label: '沂源县',
                        value: '6550688903299334152',
                    },
                ],
            },
            {
                label: '枣庄市',
                value: '6550688903299334153',
                children: [
                    {
                        label: '市中区',
                        value: '6550688903299334154',
                    },
                    {
                        label: '薛城区',
                        value: '6550688903299334155',
                    },
                    {
                        label: '峄城区',
                        value: '6550688903299334156',
                    },
                    {
                        label: '台儿庄区',
                        value: '6550688903299334157',
                    },
                    {
                        label: '山亭区',
                        value: '6550688903299334158',
                    },
                    {
                        label: '滕州市',
                        value: '6550688903299334159',
                    },
                ],
            },
            {
                label: '东营市',
                value: '6550688903299334160',
                children: [
                    {
                        label: '东营区',
                        value: '6550688903299334161',
                    },
                    {
                        label: '河口区',
                        value: '6550688903299334162',
                    },
                    {
                        label: '垦利区',
                        value: '6550688903299334163',
                    },
                    {
                        label: '利津县',
                        value: '6550688903299334164',
                    },
                    {
                        label: '广饶县',
                        value: '6550688903303528448',
                    },
                ],
            },
            {
                label: '烟台市',
                value: '6550688903303528449',
                children: [
                    {
                        label: '芝罘区',
                        value: '6550688903303528450',
                    },
                    {
                        label: '福山区',
                        value: '6550688903303528451',
                    },
                    {
                        label: '牟平区',
                        value: '6550688903303528452',
                    },
                    {
                        label: '莱山区',
                        value: '6550688903303528453',
                    },
                    {
                        label: '长岛县',
                        value: '6550688903303528454',
                    },
                    {
                        label: '龙口市',
                        value: '6550688903303528455',
                    },
                    {
                        label: '莱阳市',
                        value: '6550688903303528456',
                    },
                    {
                        label: '莱州市',
                        value: '6550688903303528457',
                    },
                    {
                        label: '蓬莱市',
                        value: '6550688903303528458',
                    },
                    {
                        label: '招远市',
                        value: '6550688903303528459',
                    },
                    {
                        label: '栖霞市',
                        value: '6550688903303528460',
                    },
                    {
                        label: '海阳市',
                        value: '6550688903303528461',
                    },
                ],
            },
            {
                label: '潍坊市',
                value: '6550688903303528462',
                children: [
                    {
                        label: '潍城区',
                        value: '6550688903303528463',
                    },
                    {
                        label: '寒亭区',
                        value: '6550688903303528464',
                    },
                    {
                        label: '坊子区',
                        value: '6550688903303528465',
                    },
                    {
                        label: '奎文区',
                        value: '6550688903307722752',
                    },
                    {
                        label: '临朐县',
                        value: '6550688903307722753',
                    },
                    {
                        label: '昌乐县',
                        value: '6550688903307722754',
                    },
                    {
                        label: '青州市',
                        value: '6550688903307722755',
                    },
                    {
                        label: '诸城市',
                        value: '6550688903307722756',
                    },
                    {
                        label: '寿光市',
                        value: '6550688903307722757',
                    },
                    {
                        label: '安丘市',
                        value: '6550688903307722758',
                    },
                    {
                        label: '高密市',
                        value: '6550688903307722759',
                    },
                    {
                        label: '昌邑市',
                        value: '6550688903307722760',
                    },
                ],
            },
            {
                label: '济宁市',
                value: '6550688903307722761',
                children: [
                    {
                        label: '兖州区',
                        value: '6550688903307722762',
                    },
                    {
                        label: '任城区',
                        value: '6550688903307722763',
                    },
                    {
                        label: '微山县',
                        value: '6550688903307722764',
                    },
                    {
                        label: '鱼台县',
                        value: '6550688903307722765',
                    },
                    {
                        label: '金乡县',
                        value: '6550688903307722766',
                    },
                    {
                        label: '嘉祥县',
                        value: '6550688903307722767',
                    },
                    {
                        label: '汶上县',
                        value: '6550688903307722768',
                    },
                    {
                        label: '泗水县',
                        value: '6550688903307722769',
                    },
                    {
                        label: '梁山县',
                        value: '6550688903307722770',
                    },
                    {
                        label: '曲阜市',
                        value: '6550688903311917056',
                    },
                    {
                        label: '邹城市',
                        value: '6550688903311917057',
                    },
                ],
            },
            {
                label: '泰安市',
                value: '6550688903311917058',
                children: [
                    {
                        label: '泰山区',
                        value: '6550688903311917059',
                    },
                    {
                        label: '岱岳区',
                        value: '6550688903311917060',
                    },
                    {
                        label: '宁阳县',
                        value: '6550688903311917061',
                    },
                    {
                        label: '东平县',
                        value: '6550688903311917062',
                    },
                    {
                        label: '新泰市',
                        value: '6550688903311917063',
                    },
                    {
                        label: '肥城市',
                        value: '6550688903311917064',
                    },
                ],
            },
            {
                label: '威海市',
                value: '6550688903311917065',
                children: [
                    {
                        label: '环翠区',
                        value: '6550688903311917066',
                    },
                    {
                        label: '文登区',
                        value: '6550688903311917067',
                    },
                    {
                        label: '荣成市',
                        value: '6550688903311917068',
                    },
                    {
                        label: '乳山市',
                        value: '6550688903311917069',
                    },
                ],
            },
            {
                label: '日照市',
                value: '6550688903311917070',
                children: [
                    {
                        label: '东港区',
                        value: '6550688903311917071',
                    },
                    {
                        label: '岚山区',
                        value: '6550688903311917072',
                    },
                    {
                        label: '五莲县',
                        value: '6550688903311917073',
                    },
                    {
                        label: '莒县',
                        value: '6550688903311917074',
                    },
                ],
            },
            {
                label: '莱芜市',
                value: '6550688903316111360',
                children: [
                    {
                        label: '莱城区',
                        value: '6550688903316111361',
                    },
                    {
                        label: '钢城区',
                        value: '6550688903316111362',
                    },
                ],
            },
            {
                label: '临沂市',
                value: '6550688903316111363',
                children: [
                    {
                        label: '兰山区',
                        value: '6550688903316111364',
                    },
                    {
                        label: '罗庄区',
                        value: '6550688903316111365',
                    },
                    {
                        label: '河东区',
                        value: '6550688903316111366',
                    },
                    {
                        label: '沂南县',
                        value: '6550688903316111367',
                    },
                    {
                        label: '郯城县',
                        value: '6550688903316111368',
                    },
                    {
                        label: '沂水县',
                        value: '6550688903316111369',
                    },
                    {
                        label: '苍山县',
                        value: '6550688903316111370',
                    },
                    {
                        label: '费县',
                        value: '6550688903316111371',
                    },
                    {
                        label: '平邑县',
                        value: '6550688903316111372',
                    },
                    {
                        label: '莒南县',
                        value: '6550688903316111373',
                    },
                    {
                        label: '蒙阴县',
                        value: '6550688903316111374',
                    },
                    {
                        label: '临沭县',
                        value: '6550688903316111375',
                    },
                ],
            },
            {
                label: '德州市',
                value: '6550688903316111376',
                children: [
                    {
                        label: '德城区',
                        value: '6550688903316111377',
                    },
                    {
                        label: '陵城区',
                        value: '6550688903316111378',
                    },
                    {
                        label: '宁津县',
                        value: '6550688903320305664',
                    },
                    {
                        label: '庆云县',
                        value: '6550688903320305665',
                    },
                    {
                        label: '临邑县',
                        value: '6550688903320305666',
                    },
                    {
                        label: '齐河县',
                        value: '6550688903320305667',
                    },
                    {
                        label: '平原县',
                        value: '6550688903320305668',
                    },
                    {
                        label: '夏津县',
                        value: '6550688903320305669',
                    },
                    {
                        label: '武城县',
                        value: '6550688903320305670',
                    },
                    {
                        label: '乐陵市',
                        value: '6550688903320305671',
                    },
                    {
                        label: '禹城市',
                        value: '6550688903320305672',
                    },
                ],
            },
            {
                label: '聊城市',
                value: '6550688903320305673',
                children: [
                    {
                        label: '东昌府区',
                        value: '6550688903320305674',
                    },
                    {
                        label: '阳谷县',
                        value: '6550688903320305675',
                    },
                    {
                        label: '莘县',
                        value: '6550688903320305676',
                    },
                    {
                        label: '茌平县',
                        value: '6550688903320305677',
                    },
                    {
                        label: '东阿县',
                        value: '6550688903320305678',
                    },
                    {
                        label: '冠县',
                        value: '6550688903320305679',
                    },
                    {
                        label: '高唐县',
                        value: '6550688903320305680',
                    },
                    {
                        label: '临清市',
                        value: '6550688903320305681',
                    },
                ],
            },
            {
                label: '滨州市',
                value: '6550688903320305682',
                children: [
                    {
                        label: '滨城区',
                        value: '6550688903320305683',
                    },
                    {
                        label: '沾化区',
                        value: '6550688903320305684',
                    },
                    {
                        label: '惠民县',
                        value: '6550688903320305685',
                    },
                    {
                        label: '阳信县',
                        value: '6550688903320305686',
                    },
                    {
                        label: '无棣县',
                        value: '6550688903320305687',
                    },
                    {
                        label: '博兴县',
                        value: '6550688903320305688',
                    },
                    {
                        label: '邹平县',
                        value: '6550688903320305689',
                    },
                ],
            },
            {
                label: '菏泽市',
                value: '6550688903320305690',
                children: [
                    {
                        label: '牡丹区',
                        value: '6550688903320305691',
                    },
                    {
                        label: '曹县',
                        value: '6550688903320305692',
                    },
                    {
                        label: '定陶县',
                        value: '6550688903324499968',
                    },
                    {
                        label: '成武县',
                        value: '6550688903324499969',
                    },
                    {
                        label: '单县',
                        value: '6550688903324499970',
                    },
                    {
                        label: '巨野县',
                        value: '6550688903324499971',
                    },
                    {
                        label: '郓城县',
                        value: '6550688903324499972',
                    },
                    {
                        label: '鄄城县',
                        value: '6550688903324499973',
                    },
                    {
                        label: '东明县',
                        value: '6550688903324499974',
                    },
                ],
            },
        ],
    },
    {
        label: '河南省',
        value: '6550688903324499975',
        children: [
            {
                label: '郑州市',
                value: '6550688903324499976',
                children: [
                    {
                        label: '中原区',
                        value: '6550688903324499977',
                    },
                    {
                        label: '二七区',
                        value: '6550688903324499978',
                    },
                    {
                        label: '管城回族区',
                        value: '6550688903324499979',
                    },
                    {
                        label: '金水区',
                        value: '6550688903324499980',
                    },
                    {
                        label: '上街区',
                        value: '6550688903324499981',
                    },
                    {
                        label: '惠济区',
                        value: '6550688903324499982',
                    },
                    {
                        label: '中牟县',
                        value: '6550688903324499983',
                    },
                    {
                        label: '巩义市',
                        value: '6550688903324499984',
                    },
                    {
                        label: '荥阳市',
                        value: '6550688903324499985',
                    },
                    {
                        label: '新密市',
                        value: '6550688903324499986',
                    },
                    {
                        label: '新郑市',
                        value: '6550688903324499987',
                    },
                    {
                        label: '登封市',
                        value: '6550688903324499988',
                    },
                ],
            },
            {
                label: '开封市',
                value: '6550688903324499989',
                children: [
                    {
                        label: '龙亭区',
                        value: '6550688903324499990',
                    },
                    {
                        label: '顺河回族区',
                        value: '6550688903324499991',
                    },
                    {
                        label: '鼓楼区',
                        value: '6550688903324499992',
                    },
                    {
                        label: '禹王台区',
                        value: '6550688903324499993',
                    },
                    {
                        label: '祥符区',
                        value: '6550688903324499994',
                    },
                    {
                        label: '杞县',
                        value: '6550688903328694272',
                    },
                    {
                        label: '通许县',
                        value: '6550688903328694273',
                    },
                    {
                        label: '尉氏县',
                        value: '6550688903328694274',
                    },
                    {
                        label: '兰考县',
                        value: '6550688903328694275',
                    },
                ],
            },
            {
                label: '洛阳市',
                value: '6550688903328694276',
                children: [
                    {
                        label: '老城区',
                        value: '6550688903328694277',
                    },
                    {
                        label: '西工区',
                        value: '6550688903328694278',
                    },
                    {
                        label: '瀍河回族区',
                        value: '6550688903328694279',
                    },
                    {
                        label: '涧西区',
                        value: '6550688903328694280',
                    },
                    {
                        label: '吉利区',
                        value: '6550688903328694281',
                    },
                    {
                        label: '洛龙区',
                        value: '6550688903328694282',
                    },
                    {
                        label: '孟津县',
                        value: '6550688903328694283',
                    },
                    {
                        label: '新安县',
                        value: '6550688903328694284',
                    },
                    {
                        label: '栾川县',
                        value: '6550688903328694285',
                    },
                    {
                        label: '嵩县',
                        value: '6550688903328694286',
                    },
                    {
                        label: '汝阳县',
                        value: '6550688903328694287',
                    },
                    {
                        label: '宜阳县',
                        value: '6550688903328694288',
                    },
                    {
                        label: '洛宁县',
                        value: '6550688903328694289',
                    },
                    {
                        label: '伊川县',
                        value: '6550688903328694290',
                    },
                    {
                        label: '偃师市',
                        value: '6550688903328694291',
                    },
                ],
            },
            {
                label: '平顶山市',
                value: '6550688903328694292',
                children: [
                    {
                        label: '新华区',
                        value: '6550688903328694293',
                    },
                    {
                        label: '卫东区',
                        value: '6550688903328694294',
                    },
                    {
                        label: '石龙区',
                        value: '6550688903328694295',
                    },
                    {
                        label: '湛河区',
                        value: '6550688903328694296',
                    },
                    {
                        label: '宝丰县',
                        value: '6550688903328694297',
                    },
                    {
                        label: '叶县',
                        value: '6550688903328694298',
                    },
                    {
                        label: '鲁山县',
                        value: '6550688903328694299',
                    },
                    {
                        label: '郏县',
                        value: '6550688903332888576',
                    },
                    {
                        label: '舞钢市',
                        value: '6550688903332888577',
                    },
                    {
                        label: '汝州市',
                        value: '6550688903332888578',
                    },
                ],
            },
            {
                label: '安阳市',
                value: '6550688903332888579',
                children: [
                    {
                        label: '文峰区',
                        value: '6550688903332888580',
                    },
                    {
                        label: '北关区',
                        value: '6550688903332888581',
                    },
                    {
                        label: '殷都区',
                        value: '6550688903332888582',
                    },
                    {
                        label: '龙安区',
                        value: '6550688903332888583',
                    },
                    {
                        label: '安阳县',
                        value: '6550688903332888584',
                    },
                    {
                        label: '汤阴县',
                        value: '6550688903332888585',
                    },
                    {
                        label: '滑县',
                        value: '6550688903332888586',
                    },
                    {
                        label: '内黄县',
                        value: '6550688903332888587',
                    },
                    {
                        label: '林州市',
                        value: '6550688903332888588',
                    },
                ],
            },
            {
                label: '鹤壁市',
                value: '6550688903332888589',
                children: [
                    {
                        label: '鹤山区',
                        value: '6550688903332888590',
                    },
                    {
                        label: '山城区',
                        value: '6550688903332888591',
                    },
                    {
                        label: '淇滨区',
                        value: '6550688903332888592',
                    },
                    {
                        label: '浚县',
                        value: '6550688903332888593',
                    },
                    {
                        label: '淇县',
                        value: '6550688903332888594',
                    },
                ],
            },
            {
                label: '新乡市',
                value: '6550688903332888595',
                children: [
                    {
                        label: '红旗区',
                        value: '6550688903332888596',
                    },
                    {
                        label: '卫滨区',
                        value: '6550688903332888597',
                    },
                    {
                        label: '凤泉区',
                        value: '6550688903332888598',
                    },
                    {
                        label: '牧野区',
                        value: '6550688903332888599',
                    },
                    {
                        label: '新乡县',
                        value: '6550688903332888600',
                    },
                    {
                        label: '获嘉县',
                        value: '6550688903332888601',
                    },
                    {
                        label: '原阳县',
                        value: '6550688903332888602',
                    },
                    {
                        label: '延津县',
                        value: '6550688903332888603',
                    },
                    {
                        label: '封丘县',
                        value: '6550688903332888604',
                    },
                    {
                        label: '长垣县',
                        value: '6550688903332888605',
                    },
                    {
                        label: '卫辉市',
                        value: '6550688903337082880',
                    },
                    {
                        label: '辉县市',
                        value: '6550688903337082881',
                    },
                ],
            },
            {
                label: '焦作市',
                value: '6550688903337082882',
                children: [
                    {
                        label: '解放区',
                        value: '6550688903337082883',
                    },
                    {
                        label: '中站区',
                        value: '6550688903337082884',
                    },
                    {
                        label: '马村区',
                        value: '6550688903337082885',
                    },
                    {
                        label: '山阳区',
                        value: '6550688903337082886',
                    },
                    {
                        label: '修武县',
                        value: '6550688903337082887',
                    },
                    {
                        label: '博爱县',
                        value: '6550688903337082888',
                    },
                    {
                        label: '武陟县',
                        value: '6550688903337082889',
                    },
                    {
                        label: '温县',
                        value: '6550688903337082890',
                    },
                    {
                        label: '沁阳市',
                        value: '6550688903337082891',
                    },
                    {
                        label: '孟州市',
                        value: '6550688903337082892',
                    },
                ],
            },
            {
                label: '濮阳市',
                value: '6550688903337082893',
                children: [
                    {
                        label: '华龙区',
                        value: '6550688903337082894',
                    },
                    {
                        label: '清丰县',
                        value: '6550688903337082895',
                    },
                    {
                        label: '南乐县',
                        value: '6550688903337082896',
                    },
                    {
                        label: '范县',
                        value: '6550688903337082897',
                    },
                    {
                        label: '台前县',
                        value: '6550688903337082898',
                    },
                    {
                        label: '濮阳县',
                        value: '6550688903337082899',
                    },
                ],
            },
            {
                label: '许昌市',
                value: '6550688903337082900',
                children: [
                    {
                        label: '魏都区',
                        value: '6550688903337082901',
                    },
                    {
                        label: '建安区',
                        value: '6550688903337082902',
                    },
                    {
                        label: '鄢陵县',
                        value: '6550688903337082903',
                    },
                    {
                        label: '襄城县',
                        value: '6550688903337082904',
                    },
                    {
                        label: '禹州市',
                        value: '6550688903337082905',
                    },
                    {
                        label: '长葛市',
                        value: '6550688903337082906',
                    },
                ],
            },
            {
                label: '漯河市',
                value: '6550688903337082907',
                children: [
                    {
                        label: '源汇区',
                        value: '6550688903337082908',
                    },
                    {
                        label: '郾城区',
                        value: '6550688903341277184',
                    },
                    {
                        label: '召陵区',
                        value: '6550688903341277185',
                    },
                    {
                        label: '舞阳县',
                        value: '6550688903341277186',
                    },
                    {
                        label: '临颍县',
                        value: '6550688903341277187',
                    },
                ],
            },
            {
                label: '三门峡市',
                value: '6550688903341277188',
                children: [
                    {
                        label: '湖滨区',
                        value: '6550688903341277189',
                    },
                    {
                        label: '陕州区',
                        value: '6550688903341277190',
                    },
                    {
                        label: '渑池县',
                        value: '6550688903341277191',
                    },
                    {
                        label: '卢氏县',
                        value: '6550688903341277192',
                    },
                    {
                        label: '义马市',
                        value: '6550688903341277193',
                    },
                    {
                        label: '灵宝市',
                        value: '6550688903341277194',
                    },
                ],
            },
            {
                label: '南阳市',
                value: '6550688903341277195',
                children: [
                    {
                        label: '宛城区',
                        value: '6550688903341277196',
                    },
                    {
                        label: '卧龙区',
                        value: '6550688903341277197',
                    },
                    {
                        label: '南召县',
                        value: '6550688903341277198',
                    },
                    {
                        label: '方城县',
                        value: '6550688903341277199',
                    },
                    {
                        label: '西峡县',
                        value: '6550688903341277200',
                    },
                    {
                        label: '镇平县',
                        value: '6550688903341277201',
                    },
                    {
                        label: '内乡县',
                        value: '6550688903341277202',
                    },
                    {
                        label: '淅川县',
                        value: '6550688903341277203',
                    },
                    {
                        label: '社旗县',
                        value: '6550688903341277204',
                    },
                    {
                        label: '唐河县',
                        value: '6550688903341277205',
                    },
                    {
                        label: '新野县',
                        value: '6550688903341277206',
                    },
                    {
                        label: '桐柏县',
                        value: '6550688903341277207',
                    },
                    {
                        label: '邓州市',
                        value: '6550688903341277208',
                    },
                ],
            },
            {
                label: '商丘市',
                value: '6550688903341277209',
                children: [
                    {
                        label: '梁园区',
                        value: '6550688903341277210',
                    },
                    {
                        label: '睢阳区',
                        value: '6550688903341277211',
                    },
                    {
                        label: '民权县',
                        value: '6550688903341277212',
                    },
                    {
                        label: '睢县',
                        value: '6550688903341277213',
                    },
                    {
                        label: '宁陵县',
                        value: '6550688903341277214',
                    },
                    {
                        label: '柘城县',
                        value: '6550688903345471488',
                    },
                    {
                        label: '虞城县',
                        value: '6550688903345471489',
                    },
                    {
                        label: '夏邑县',
                        value: '6550688903345471490',
                    },
                    {
                        label: '永城市',
                        value: '6550688903345471491',
                    },
                ],
            },
            {
                label: '信阳市',
                value: '6550688903345471492',
                children: [
                    {
                        label: '浉河区',
                        value: '6550688903345471493',
                    },
                    {
                        label: '平桥区',
                        value: '6550688903345471494',
                    },
                    {
                        label: '罗山县',
                        value: '6550688903345471495',
                    },
                    {
                        label: '光山县',
                        value: '6550688903345471496',
                    },
                    {
                        label: '新县',
                        value: '6550688903345471497',
                    },
                    {
                        label: '商城县',
                        value: '6550688903345471498',
                    },
                    {
                        label: '固始县',
                        value: '6550688903345471499',
                    },
                    {
                        label: '潢川县',
                        value: '6550688903345471500',
                    },
                    {
                        label: '淮滨县',
                        value: '6550688903345471501',
                    },
                    {
                        label: '息县',
                        value: '6550688903345471502',
                    },
                ],
            },
            {
                label: '周口市',
                value: '6550688903345471503',
                children: [
                    {
                        label: '川汇区',
                        value: '6550688903345471504',
                    },
                    {
                        label: '项城市',
                        value: '6550688903345471505',
                    },
                    {
                        label: '扶沟县',
                        value: '6550688903345471506',
                    },
                    {
                        label: '西华县',
                        value: '6550688903345471507',
                    },
                    {
                        label: '商水县',
                        value: '6550688903345471508',
                    },
                    {
                        label: '太康县',
                        value: '6550688903345471509',
                    },
                    {
                        label: '鹿邑县',
                        value: '6550688903345471510',
                    },
                    {
                        label: '郸城县',
                        value: '6550688903345471511',
                    },
                    {
                        label: '淮阳县',
                        value: '6550688903345471512',
                    },
                    {
                        label: '沈丘县',
                        value: '6550688903345471513',
                    },
                ],
            },
            {
                label: '驻马店地区',
                value: '6550688903345471514',
                children: [
                    {
                        label: '驿城区',
                        value: '6550688903345471515',
                    },
                    {
                        label: '确山县',
                        value: '6550688903345471516',
                    },
                    {
                        label: '泌阳县',
                        value: '6550688903349665792',
                    },
                    {
                        label: '遂平县',
                        value: '6550688903349665793',
                    },
                    {
                        label: '西平县',
                        value: '6550688903349665794',
                    },
                    {
                        label: '上蔡县',
                        value: '6550688903349665795',
                    },
                    {
                        label: '汝南县',
                        value: '6550688903349665796',
                    },
                    {
                        label: '平舆县',
                        value: '6550688903349665797',
                    },
                    {
                        label: '新蔡县',
                        value: '6550688903349665798',
                    },
                    {
                        label: '正阳县',
                        value: '6550688903349665799',
                    },
                ],
            },
            {
                label: '省直辖行政单位',
                value: '6550688903349665800',
                children: [
                    {
                        label: '济源市',
                        value: '6550688903349665801',
                    },
                ],
            },
        ],
    },
    {
        label: '湖北省',
        value: '6550688903349665802',
        children: [
            {
                label: '武汉市',
                value: '6550688903349665803',
                children: [
                    {
                        label: '江岸区',
                        value: '6550688903349665804',
                    },
                    {
                        label: '江汉区',
                        value: '6550688903349665805',
                    },
                    {
                        label: '硚口区',
                        value: '6550688903349665806',
                    },
                    {
                        label: '汉阳区',
                        value: '6550688903349665807',
                    },
                    {
                        label: '武昌区',
                        value: '6550688903349665808',
                    },
                    {
                        label: '青山区',
                        value: '6550688903349665809',
                    },
                    {
                        label: '洪山区',
                        value: '6550688903349665810',
                    },
                    {
                        label: '东西湖区',
                        value: '6550688903349665811',
                    },
                    {
                        label: '汉南区',
                        value: '6550688903349665812',
                    },
                    {
                        label: '蔡甸区',
                        value: '6550688903349665813',
                    },
                    {
                        label: '江夏区',
                        value: '6550688903349665814',
                    },
                    {
                        label: '黄陂区',
                        value: '6550688903349665815',
                    },
                    {
                        label: '新洲区',
                        value: '6550688903349665816',
                    },
                ],
            },
            {
                label: '黄石市',
                value: '6550688903349665817',
                children: [
                    {
                        label: '黄石港区',
                        value: '6550688903349665818',
                    },
                    {
                        label: '西塞山区',
                        value: '6550688903349665819',
                    },
                    {
                        label: '下陆区',
                        value: '6550688903349665820',
                    },
                    {
                        label: '铁山区',
                        value: '6550688903353860096',
                    },
                    {
                        label: '阳新县',
                        value: '6550688903353860097',
                    },
                    {
                        label: '大冶市',
                        value: '6550688903353860098',
                    },
                ],
            },
            {
                label: '十堰市',
                value: '6550688903353860099',
                children: [
                    {
                        label: '茅箭区',
                        value: '6550688903353860100',
                    },
                    {
                        label: '张湾区',
                        value: '6550688903353860101',
                    },
                    {
                        label: '郧阳区',
                        value: '6550688903353860102',
                    },
                    {
                        label: '郧西县',
                        value: '6550688903353860103',
                    },
                    {
                        label: '竹山县',
                        value: '6550688903353860104',
                    },
                    {
                        label: '竹溪县',
                        value: '6550688903353860105',
                    },
                    {
                        label: '房县',
                        value: '6550688903353860106',
                    },
                    {
                        label: '丹江口市',
                        value: '6550688903353860107',
                    },
                ],
            },
            {
                label: '宜昌市',
                value: '6550688903353860108',
                children: [
                    {
                        label: '西陵区',
                        value: '6550688903353860109',
                    },
                    {
                        label: '伍家岗区',
                        value: '6550688903353860110',
                    },
                    {
                        label: '点军区',
                        value: '6550688903353860111',
                    },
                    {
                        label: '猇亭区',
                        value: '6550688903353860112',
                    },
                    {
                        label: '夷陵区',
                        value: '6550688903353860113',
                    },
                    {
                        label: '远安县',
                        value: '6550688903353860114',
                    },
                    {
                        label: '兴山县',
                        value: '6550688903353860115',
                    },
                    {
                        label: '秭归县',
                        value: '6550688903353860116',
                    },
                    {
                        label: '长阳土家族自治县',
                        value: '6550688903353860117',
                    },
                    {
                        label: '五峰土家族自治县',
                        value: '6550688903353860118',
                    },
                    {
                        label: '宜都市',
                        value: '6550688903353860119',
                    },
                    {
                        label: '当阳市',
                        value: '6550688903353860120',
                    },
                    {
                        label: '枝江市',
                        value: '6550688903353860121',
                    },
                ],
            },
            {
                label: '襄阳市',
                value: '6550688903353860122',
                children: [
                    {
                        label: '襄城区',
                        value: '6550688903353860123',
                    },
                    {
                        label: '樊城区',
                        value: '6550688903353860124',
                    },
                    {
                        label: '襄州区',
                        value: '6550688903353860125',
                    },
                    {
                        label: '南漳县',
                        value: '6550688903358054400',
                    },
                    {
                        label: '谷城县',
                        value: '6550688903358054401',
                    },
                    {
                        label: '保康县',
                        value: '6550688903358054402',
                    },
                    {
                        label: '老河口市',
                        value: '6550688903358054403',
                    },
                    {
                        label: '枣阳市',
                        value: '6550688903358054404',
                    },
                    {
                        label: '宜城市',
                        value: '6550688903358054405',
                    },
                ],
            },
            {
                label: '鄂州市',
                value: '6550688903358054406',
                children: [
                    {
                        label: '梁子湖区',
                        value: '6550688903358054407',
                    },
                    {
                        label: '华容区',
                        value: '6550688903358054408',
                    },
                    {
                        label: '鄂城区',
                        value: '6550688903358054409',
                    },
                ],
            },
            {
                label: '荆门市',
                value: '6550688903358054410',
                children: [
                    {
                        label: '东宝区',
                        value: '6550688903358054411',
                    },
                    {
                        label: '掇刀区',
                        value: '6550688903358054412',
                    },
                    {
                        label: '京山县',
                        value: '6550688903358054413',
                    },
                    {
                        label: '沙洋县',
                        value: '6550688903358054414',
                    },
                    {
                        label: '钟祥市',
                        value: '6550688903358054415',
                    },
                ],
            },
            {
                label: '孝感市',
                value: '6550688903358054416',
                children: [
                    {
                        label: '孝南区',
                        value: '6550688903358054417',
                    },
                    {
                        label: '孝昌县',
                        value: '6550688903362248704',
                    },
                    {
                        label: '大悟县',
                        value: '6550688903362248705',
                    },
                    {
                        label: '云梦县',
                        value: '6550688903362248706',
                    },
                    {
                        label: '应城市',
                        value: '6550688903362248707',
                    },
                    {
                        label: '安陆市',
                        value: '6550688903362248708',
                    },
                    {
                        label: '汉川市',
                        value: '6550688903362248709',
                    },
                ],
            },
            {
                label: '荆州市',
                value: '6550688903362248710',
                children: [
                    {
                        label: '沙市区',
                        value: '6550688903362248711',
                    },
                    {
                        label: '荆州区',
                        value: '6550688903362248712',
                    },
                    {
                        label: '公安县',
                        value: '6550688903362248713',
                    },
                    {
                        label: '监利县',
                        value: '6550688903362248714',
                    },
                    {
                        label: '江陵县',
                        value: '6550688903362248715',
                    },
                    {
                        label: '石首市',
                        value: '6550688903362248716',
                    },
                    {
                        label: '洪湖市',
                        value: '6550688903362248717',
                    },
                    {
                        label: '松滋市',
                        value: '6550688903362248718',
                    },
                ],
            },
            {
                label: '黄冈市',
                value: '6550688903362248719',
                children: [
                    {
                        label: '黄州区',
                        value: '6550688903362248720',
                    },
                    {
                        label: '团风县',
                        value: '6550688903362248721',
                    },
                    {
                        label: '红安县',
                        value: '6550688903362248722',
                    },
                    {
                        label: '罗田县',
                        value: '6550688903362248723',
                    },
                    {
                        label: '英山县',
                        value: '6550688903362248724',
                    },
                    {
                        label: '浠水县',
                        value: '6550688903362248725',
                    },
                    {
                        label: '蕲春县',
                        value: '6550688903362248726',
                    },
                    {
                        label: '黄梅县',
                        value: '6550688903362248727',
                    },
                    {
                        label: '麻城市',
                        value: '6550688903362248728',
                    },
                    {
                        label: '武穴市',
                        value: '6550688903362248729',
                    },
                ],
            },
            {
                label: '咸宁市',
                value: '6550688903362248730',
                children: [
                    {
                        label: '咸安区',
                        value: '6550688903366443008',
                    },
                    {
                        label: '嘉鱼县',
                        value: '6550688903366443009',
                    },
                    {
                        label: '通城县',
                        value: '6550688903366443010',
                    },
                    {
                        label: '崇阳县',
                        value: '6550688903366443011',
                    },
                    {
                        label: '通山县',
                        value: '6550688903366443012',
                    },
                    {
                        label: '赤壁市',
                        value: '6550688903366443013',
                    },
                ],
            },
            {
                label: '恩施土家族苗族自治州',
                value: '6550688903366443014',
                children: [
                    {
                        label: '恩施市',
                        value: '6550688903366443015',
                    },
                    {
                        label: '利川市',
                        value: '6550688903366443016',
                    },
                    {
                        label: '建始县',
                        value: '6550688903366443017',
                    },
                    {
                        label: '巴东县',
                        value: '6550688903366443018',
                    },
                    {
                        label: '宣恩县',
                        value: '6550688903366443019',
                    },
                    {
                        label: '咸丰县',
                        value: '6550688903366443020',
                    },
                    {
                        label: '来凤县',
                        value: '6550688903366443021',
                    },
                    {
                        label: '鹤峰县',
                        value: '6550688903366443022',
                    },
                ],
            },
            {
                label: '随州市',
                value: '6550688903366443023',
                children: [
                    {
                        label: '曾都区',
                        value: '6550688903366443024',
                    },
                    {
                        label: '广水市',
                        value: '6550688903366443025',
                    },
                    {
                        label: '随县',
                        value: '6550688903366443026',
                    },
                ],
            },
            {
                label: '省直辖行政单位',
                value: '6550688903366443027',
                children: [
                    {
                        label: '仙桃市',
                        value: '6550688903366443028',
                    },
                    {
                        label: '潜江市',
                        value: '6550688903366443029',
                    },
                    {
                        label: '天门市',
                        value: '6550688903366443030',
                    },
                    {
                        label: '神农架林区',
                        value: '6550688903366443031',
                    },
                ],
            },
        ],
    },
    {
        label: '湖南省',
        value: '6550688903366443032',
        children: [
            {
                label: '长沙市',
                value: '6550688903366443033',
                children: [
                    {
                        label: '芙蓉区',
                        value: '6550688903366443034',
                    },
                    {
                        label: '天心区',
                        value: '6550688903366443035',
                    },
                    {
                        label: '岳麓区',
                        value: '6550688903370637312',
                    },
                    {
                        label: '开福区',
                        value: '6550688903370637313',
                    },
                    {
                        label: '雨花区',
                        value: '6550688903370637314',
                    },
                    {
                        label: '望城区',
                        value: '6550688903370637315',
                    },
                    {
                        label: '长沙县',
                        value: '6550688903370637316',
                    },
                    {
                        label: '宁乡县',
                        value: '6550688903370637317',
                    },
                    {
                        label: '浏阳市',
                        value: '6550688903370637318',
                    },
                ],
            },
            {
                label: '株洲市',
                value: '6550688903370637319',
                children: [
                    {
                        label: '荷塘区',
                        value: '6550688903370637320',
                    },
                    {
                        label: '芦淞区',
                        value: '6550688903370637321',
                    },
                    {
                        label: '石峰区',
                        value: '6550688903370637322',
                    },
                    {
                        label: '天元区',
                        value: '6550688903370637323',
                    },
                    {
                        label: '株洲县',
                        value: '6550688903370637324',
                    },
                    {
                        label: '攸县',
                        value: '6550688903370637325',
                    },
                    {
                        label: '茶陵县',
                        value: '6550688903370637326',
                    },
                    {
                        label: '炎陵县',
                        value: '6550688903370637327',
                    },
                    {
                        label: '醴陵市',
                        value: '6550688903370637328',
                    },
                ],
            },
            {
                label: '湘潭市',
                value: '6550688903370637329',
                children: [
                    {
                        label: '雨湖区',
                        value: '6550688903370637330',
                    },
                    {
                        label: '岳塘区',
                        value: '6550688903370637331',
                    },
                    {
                        label: '湘潭县',
                        value: '6550688903370637332',
                    },
                    {
                        label: '湘乡市',
                        value: '6550688903370637333',
                    },
                    {
                        label: '韶山市',
                        value: '6550688903370637334',
                    },
                ],
            },
            {
                label: '衡阳市',
                value: '6550688903370637335',
                children: [
                    {
                        label: '珠晖区',
                        value: '6550688903370637336',
                    },
                    {
                        label: '雁峰区',
                        value: '6550688903370637337',
                    },
                    {
                        label: '石鼓区',
                        value: '6550688903370637338',
                    },
                    {
                        label: '蒸湘区',
                        value: '6550688903370637339',
                    },
                    {
                        label: '南岳区',
                        value: '6550688903370637340',
                    },
                    {
                        label: '衡阳县',
                        value: '6550688903370637341',
                    },
                    {
                        label: '衡南县',
                        value: '6550688903374831616',
                    },
                    {
                        label: '衡山县',
                        value: '6550688903374831617',
                    },
                    {
                        label: '衡东县',
                        value: '6550688903374831618',
                    },
                    {
                        label: '祁东县',
                        value: '6550688903374831619',
                    },
                    {
                        label: '耒阳市',
                        value: '6550688903374831620',
                    },
                    {
                        label: '常宁市',
                        value: '6550688903374831621',
                    },
                ],
            },
            {
                label: '邵阳市',
                value: '6550688903374831622',
                children: [
                    {
                        label: '双清区',
                        value: '6550688903374831623',
                    },
                    {
                        label: '大祥区',
                        value: '6550688903374831624',
                    },
                    {
                        label: '北塔区',
                        value: '6550688903374831625',
                    },
                    {
                        label: '邵东县',
                        value: '6550688903374831626',
                    },
                    {
                        label: '新邵县',
                        value: '6550688903374831627',
                    },
                    {
                        label: '邵阳县',
                        value: '6550688903374831628',
                    },
                    {
                        label: '隆回县',
                        value: '6550688903374831629',
                    },
                    {
                        label: '洞口县',
                        value: '6550688903374831630',
                    },
                    {
                        label: '绥宁县',
                        value: '6550688903374831631',
                    },
                    {
                        label: '新宁县',
                        value: '6550688903374831632',
                    },
                    {
                        label: '城步苗族自治县',
                        value: '6550688903374831633',
                    },
                    {
                        label: '武冈市',
                        value: '6550688903374831634',
                    },
                ],
            },
            {
                label: '岳阳市',
                value: '6550688903374831635',
                children: [
                    {
                        label: '岳阳楼区',
                        value: '6550688903374831636',
                    },
                    {
                        label: '云溪区',
                        value: '6550688903374831637',
                    },
                    {
                        label: '君山区',
                        value: '6550688903374831638',
                    },
                    {
                        label: '岳阳县',
                        value: '6550688903374831639',
                    },
                    {
                        label: '华容县',
                        value: '6550688903374831640',
                    },
                    {
                        label: '湘阴县',
                        value: '6550688903374831641',
                    },
                    {
                        label: '平江县',
                        value: '6550688903374831642',
                    },
                    {
                        label: '汨罗市',
                        value: '6550688903374831643',
                    },
                    {
                        label: '临湘市',
                        value: '6550688903374831644',
                    },
                ],
            },
            {
                label: '常德市',
                value: '6550688903374831645',
                children: [
                    {
                        label: '武陵区',
                        value: '6550688903374831646',
                    },
                    {
                        label: '鼎城区',
                        value: '6550688903379025920',
                    },
                    {
                        label: '安乡县',
                        value: '6550688903379025921',
                    },
                    {
                        label: '汉寿县',
                        value: '6550688903379025922',
                    },
                    {
                        label: '澧县',
                        value: '6550688903379025923',
                    },
                    {
                        label: '临澧县',
                        value: '6550688903379025924',
                    },
                    {
                        label: '桃源县',
                        value: '6550688903379025925',
                    },
                    {
                        label: '石门县',
                        value: '6550688903379025926',
                    },
                    {
                        label: '津市市',
                        value: '6550688903379025927',
                    },
                ],
            },
            {
                label: '张家界市',
                value: '6550688903379025928',
                children: [
                    {
                        label: '永定区',
                        value: '6550688903379025929',
                    },
                    {
                        label: '武陵源区',
                        value: '6550688903379025930',
                    },
                    {
                        label: '慈利县',
                        value: '6550688903379025931',
                    },
                    {
                        label: '桑植县',
                        value: '6550688903379025932',
                    },
                ],
            },
            {
                label: '益阳市',
                value: '6550688903379025933',
                children: [
                    {
                        label: '资阳区',
                        value: '6550688903379025934',
                    },
                    {
                        label: '赫山区',
                        value: '6550688903379025935',
                    },
                    {
                        label: '南县',
                        value: '6550688903379025936',
                    },
                    {
                        label: '桃江县',
                        value: '6550688903379025937',
                    },
                    {
                        label: '安化县',
                        value: '6550688903379025938',
                    },
                    {
                        label: '沅江市',
                        value: '6550688903379025939',
                    },
                ],
            },
            {
                label: '郴州市',
                value: '6550688903379025940',
                children: [
                    {
                        label: '北湖区',
                        value: '6550688903379025941',
                    },
                    {
                        label: '苏仙区',
                        value: '6550688903379025942',
                    },
                    {
                        label: '桂阳县',
                        value: '6550688903379025943',
                    },
                    {
                        label: '宜章县',
                        value: '6550688903379025944',
                    },
                    {
                        label: '永兴县',
                        value: '6550688903379025945',
                    },
                    {
                        label: '嘉禾县',
                        value: '6550688903379025946',
                    },
                    {
                        label: '临武县',
                        value: '6550688903379025947',
                    },
                    {
                        label: '汝城县',
                        value: '6550688903379025948',
                    },
                    {
                        label: '桂东县',
                        value: '6550688903379025949',
                    },
                    {
                        label: '安仁县',
                        value: '6550688903383220224',
                    },
                    {
                        label: '资兴市',
                        value: '6550688903383220225',
                    },
                ],
            },
            {
                label: '永州市',
                value: '6550688903383220226',
                children: [
                    {
                        label: '零陵区',
                        value: '6550688903383220227',
                    },
                    {
                        label: '冷水滩区',
                        value: '6550688903383220228',
                    },
                    {
                        label: '祁阳县',
                        value: '6550688903383220229',
                    },
                    {
                        label: '东安县',
                        value: '6550688903383220230',
                    },
                    {
                        label: '双牌县',
                        value: '6550688903383220231',
                    },
                    {
                        label: '道县',
                        value: '6550688903383220232',
                    },
                    {
                        label: '江永县',
                        value: '6550688903383220233',
                    },
                    {
                        label: '宁远县',
                        value: '6550688903383220234',
                    },
                    {
                        label: '蓝山县',
                        value: '6550688903383220235',
                    },
                    {
                        label: '新田县',
                        value: '6550688903383220236',
                    },
                    {
                        label: '江华瑶族自治县',
                        value: '6550688903383220237',
                    },
                ],
            },
            {
                label: '怀化市',
                value: '6550688903383220238',
                children: [
                    {
                        label: '鹤城区',
                        value: '6550688903383220239',
                    },
                    {
                        label: '中方县',
                        value: '6550688903383220240',
                    },
                    {
                        label: '沅陵县',
                        value: '6550688903383220241',
                    },
                    {
                        label: '辰溪县',
                        value: '6550688903383220242',
                    },
                    {
                        label: '溆浦县',
                        value: '6550688903383220243',
                    },
                    {
                        label: '会同县',
                        value: '6550688903383220244',
                    },
                    {
                        label: '麻阳苗族自治县',
                        value: '6550688903383220245',
                    },
                    {
                        label: '新晃侗族自治县',
                        value: '6550688903383220246',
                    },
                    {
                        label: '芷江侗族自治县',
                        value: '6550688903383220247',
                    },
                    {
                        label: '靖州苗族侗族自治县',
                        value: '6550688903383220248',
                    },
                    {
                        label: '通道侗族自治县',
                        value: '6550688903383220249',
                    },
                    {
                        label: '洪江市',
                        value: '6550688903383220250',
                    },
                ],
            },
            {
                label: '娄底地区',
                value: '6550688903383220251',
                children: [
                    {
                        label: '娄星区',
                        value: '6550688903383220252',
                    },
                    {
                        label: '冷水江市',
                        value: '6550688903383220253',
                    },
                    {
                        label: '涟源市',
                        value: '6550688903383220254',
                    },
                    {
                        label: '双峰县',
                        value: '6550688903387414528',
                    },
                    {
                        label: '新化县',
                        value: '6550688903387414529',
                    },
                ],
            },
            {
                label: '湘西土家族苗族自治州',
                value: '6550688903387414530',
                children: [
                    {
                        label: '吉首市',
                        value: '6550688903387414531',
                    },
                    {
                        label: '泸溪县',
                        value: '6550688903387414532',
                    },
                    {
                        label: '凤凰县',
                        value: '6550688903387414533',
                    },
                    {
                        label: '花垣县',
                        value: '6550688903387414534',
                    },
                    {
                        label: '保靖县',
                        value: '6550688903387414535',
                    },
                    {
                        label: '古丈县',
                        value: '6550688903387414536',
                    },
                    {
                        label: '永顺县',
                        value: '6550688903387414537',
                    },
                    {
                        label: '龙山县',
                        value: '6550688903387414538',
                    },
                ],
            },
        ],
    },
    {
        label: '广东省',
        value: '6550688903387414539',
        children: [
            {
                label: '广州市',
                value: '6550688903387414540',
                children: [
                    {
                        label: '荔湾区',
                        value: '6550688903387414541',
                    },
                    {
                        label: '越秀区',
                        value: '6550688903387414542',
                    },
                    {
                        label: '海珠区',
                        value: '6550688903387414543',
                    },
                    {
                        label: '天河区',
                        value: '6550688903387414544',
                    },
                    {
                        label: '白云区',
                        value: '6550688903387414545',
                    },
                    {
                        label: '黄埔区',
                        value: '6550688903387414546',
                    },
                    {
                        label: '番禺区',
                        value: '6550688903387414547',
                    },
                    {
                        label: '花都区',
                        value: '6550688903387414548',
                    },
                    {
                        label: '南沙区',
                        value: '6550688903387414549',
                    },
                    {
                        label: '增城区',
                        value: '6550688903387414550',
                    },
                    {
                        label: '从化区',
                        value: '6550688903387414551',
                    },
                ],
            },
            {
                label: '韶关市',
                value: '6550688903387414552',
                children: [
                    {
                        label: '武江区',
                        value: '6550688903387414553',
                    },
                    {
                        label: '浈江区',
                        value: '6550688903387414554',
                    },
                    {
                        label: '曲江区',
                        value: '6550688903387414555',
                    },
                    {
                        label: '始兴县',
                        value: '6550688903387414556',
                    },
                    {
                        label: '仁化县',
                        value: '6550688903387414557',
                    },
                    {
                        label: '翁源县',
                        value: '6550688903387414558',
                    },
                    {
                        label: '乳源瑶族自治县',
                        value: '6550688903391608832',
                    },
                    {
                        label: '新丰县',
                        value: '6550688903391608833',
                    },
                    {
                        label: '乐昌市',
                        value: '6550688903391608834',
                    },
                    {
                        label: '南雄市',
                        value: '6550688903391608835',
                    },
                ],
            },
            {
                label: '深圳市',
                value: '6550688903391608836',
                children: [
                    {
                        label: '罗湖区',
                        value: '6550688903391608837',
                    },
                    {
                        label: '福田区',
                        value: '6550688903391608838',
                    },
                    {
                        label: '南山区',
                        value: '6550688903391608839',
                    },
                    {
                        label: '宝安区',
                        value: '6550688903391608840',
                    },
                    {
                        label: '龙岗区',
                        value: '6550688903391608841',
                    },
                    {
                        label: '龙华区',
                        value: '6550688903391608842',
                    },
                    {
                        label: '坪山区',
                        value: '6550688903391608843',
                    },
                    {
                        label: '盐田区',
                        value: '6550688903391608844',
                    },
                ],
            },
            {
                label: '珠海市',
                value: '6550688903391608845',
                children: [
                    {
                        label: '香洲区',
                        value: '6550688903391608846',
                    },
                    {
                        label: '斗门区',
                        value: '6550688903391608847',
                    },
                    {
                        label: '金湾区',
                        value: '6550688903391608848',
                    },
                ],
            },
            {
                label: '汕头市',
                value: '6550688903391608849',
                children: [
                    {
                        label: '濠江区',
                        value: '6550688903391608850',
                    },
                    {
                        label: '龙湖区',
                        value: '6550688903391608851',
                    },
                    {
                        label: '金平区',
                        value: '6550688903391608852',
                    },
                    {
                        label: '潮南区',
                        value: '6550688903391608853',
                    },
                    {
                        label: '澄海区',
                        value: '6550688903391608854',
                    },
                    {
                        label: '潮阳区',
                        value: '6550688903391608855',
                    },
                    {
                        label: '南澳县',
                        value: '6550688903391608856',
                    },
                ],
            },
            {
                label: '佛山市',
                value: '6550688903391608857',
                children: [
                    {
                        label: '禅城区',
                        value: '6550688903391608858',
                    },
                    {
                        label: '顺德区',
                        value: '6550688903391608859',
                    },
                    {
                        label: '南海区',
                        value: '6550688903391608860',
                    },
                    {
                        label: '三水区',
                        value: '6550688903391608861',
                    },
                    {
                        label: '高明区',
                        value: '6550688903391608862',
                    },
                ],
            },
            {
                label: '江门市',
                value: '6550688903395803136',
                children: [
                    {
                        label: '蓬江区',
                        value: '6550688903395803137',
                    },
                    {
                        label: '江海区',
                        value: '6550688903395803138',
                    },
                    {
                        label: '新会区',
                        value: '6550688903395803139',
                    },
                    {
                        label: '台山市',
                        value: '6550688903395803140',
                    },
                    {
                        label: '开平市',
                        value: '6550688903395803141',
                    },
                    {
                        label: '鹤山市',
                        value: '6550688903395803142',
                    },
                    {
                        label: '恩平市',
                        value: '6550688903395803143',
                    },
                ],
            },
            {
                label: '湛江市',
                value: '6550688903395803144',
                children: [
                    {
                        label: '赤坎区',
                        value: '6550688903395803145',
                    },
                    {
                        label: '霞山区',
                        value: '6550688903395803146',
                    },
                    {
                        label: '坡头区',
                        value: '6550688903395803147',
                    },
                    {
                        label: '麻章区',
                        value: '6550688903395803148',
                    },
                    {
                        label: '遂溪县',
                        value: '6550688903395803149',
                    },
                    {
                        label: '徐闻县',
                        value: '6550688903395803150',
                    },
                    {
                        label: '廉江市',
                        value: '6550688903395803151',
                    },
                    {
                        label: '雷州市',
                        value: '6550688903395803152',
                    },
                    {
                        label: '吴川市',
                        value: '6550688903395803153',
                    },
                ],
            },
            {
                label: '茂名市',
                value: '6550688903395803154',
                children: [
                    {
                        label: '茂南区',
                        value: '6550688903395803155',
                    },
                    {
                        label: '电白区',
                        value: '6550688903395803156',
                    },
                    {
                        label: '高州市',
                        value: '6550688903395803157',
                    },
                    {
                        label: '化州市',
                        value: '6550688903395803158',
                    },
                    {
                        label: '信宜市',
                        value: '6550688903395803159',
                    },
                ],
            },
            {
                label: '肇庆市',
                value: '6550688903395803160',
                children: [
                    {
                        label: '端州区',
                        value: '6550688903395803161',
                    },
                    {
                        label: '鼎湖区',
                        value: '6550688903395803162',
                    },
                    {
                        label: '高要区',
                        value: '6550688903395803163',
                    },
                    {
                        label: '广宁县',
                        value: '6550688903395803164',
                    },
                    {
                        label: '怀集县',
                        value: '6550688903399997440',
                    },
                    {
                        label: '封开县',
                        value: '6550688903399997441',
                    },
                    {
                        label: '德庆县',
                        value: '6550688903399997442',
                    },
                    {
                        label: '四会市',
                        value: '6550688903399997443',
                    },
                ],
            },
            {
                label: '惠州市',
                value: '6550688903399997444',
                children: [
                    {
                        label: '惠城区',
                        value: '6550688903399997445',
                    },
                    {
                        label: '惠阳区',
                        value: '6550688903399997446',
                    },
                    {
                        label: '博罗县',
                        value: '6550688903399997447',
                    },
                    {
                        label: '惠东县',
                        value: '6550688903399997448',
                    },
                    {
                        label: '龙门县',
                        value: '6550688903399997449',
                    },
                ],
            },
            {
                label: '梅州市',
                value: '6550688903399997450',
                children: [
                    {
                        label: '梅江区',
                        value: '6550688903399997451',
                    },
                    {
                        label: '梅县区',
                        value: '6550688903399997452',
                    },
                    {
                        label: '大埔县',
                        value: '6550688903399997453',
                    },
                    {
                        label: '丰顺县',
                        value: '6550688903399997454',
                    },
                    {
                        label: '五华县',
                        value: '6550688903399997455',
                    },
                    {
                        label: '平远县',
                        value: '6550688903399997456',
                    },
                    {
                        label: '蕉岭县',
                        value: '6550688903399997457',
                    },
                    {
                        label: '兴宁市',
                        value: '6550688903399997458',
                    },
                ],
            },
            {
                label: '汕尾市',
                value: '6550688903399997459',
                children: [
                    {
                        label: '城区',
                        value: '6550688903399997460',
                    },
                    {
                        label: '海丰县',
                        value: '6550688903399997461',
                    },
                    {
                        label: '陆河县',
                        value: '6550688903399997462',
                    },
                    {
                        label: '陆丰市',
                        value: '6550688903399997463',
                    },
                ],
            },
            {
                label: '河源市',
                value: '6550688903399997464',
                children: [
                    {
                        label: '源城区',
                        value: '6550688903399997465',
                    },
                    {
                        label: '紫金县',
                        value: '6550688903399997466',
                    },
                    {
                        label: '龙川县',
                        value: '6550688903399997467',
                    },
                    {
                        label: '连平县',
                        value: '6550688903399997468',
                    },
                    {
                        label: '和平县',
                        value: '6550688903399997469',
                    },
                    {
                        label: '东源县',
                        value: '6550688903404191744',
                    },
                ],
            },
            {
                label: '阳江市',
                value: '6550688903404191745',
                children: [
                    {
                        label: '江城区',
                        value: '6550688903404191746',
                    },
                    {
                        label: '阳东区',
                        value: '6550688903404191747',
                    },
                    {
                        label: '阳西县',
                        value: '6550688903404191748',
                    },
                    {
                        label: '阳春市',
                        value: '6550688903404191749',
                    },
                ],
            },
            {
                label: '清远市',
                value: '6550688903404191750',
                children: [
                    {
                        label: '清城区',
                        value: '6550688903404191751',
                    },
                    {
                        label: '清新区',
                        value: '6550688903404191752',
                    },
                    {
                        label: '佛冈县',
                        value: '6550688903404191753',
                    },
                    {
                        label: '阳山县',
                        value: '6550688903404191754',
                    },
                    {
                        label: '连山壮族瑶族自治县',
                        value: '6550688903404191755',
                    },
                    {
                        label: '连南瑶族自治县',
                        value: '6550688903404191756',
                    },
                    {
                        label: '英德市',
                        value: '6550688903404191757',
                    },
                    {
                        label: '连州市',
                        value: '6550688903404191758',
                    },
                ],
            },
            {
                label: '东莞市',
                value: '6550688903404191759',
                children: [
                    {
                        label: '东莞市',
                        value: '6550688903404191760',
                    },
                ],
            },
            {
                label: '中山市',
                value: '6550688903404191761',
                children: [
                    {
                        label: '中山市',
                        value: '6550688903404191762',
                    },
                ],
            },
            {
                label: '潮州市',
                value: '6550688903404191763',
                children: [
                    {
                        label: '湘桥区',
                        value: '6550688903404191764',
                    },
                    {
                        label: '潮安区',
                        value: '6550688903404191765',
                    },
                    {
                        label: '饶平县',
                        value: '6550688903404191766',
                    },
                ],
            },
            {
                label: '揭阳市',
                value: '6550688903404191767',
                children: [
                    {
                        label: '榕城区',
                        value: '6550688903404191768',
                    },
                    {
                        label: '揭东区',
                        value: '6550688903404191769',
                    },
                    {
                        label: '揭西县',
                        value: '6550688903404191770',
                    },
                    {
                        label: '惠来县',
                        value: '6550688903404191771',
                    },
                    {
                        label: '普宁市',
                        value: '6550688903404191772',
                    },
                ],
            },
            {
                label: '云浮市',
                value: '6550688903408386048',
                children: [
                    {
                        label: '云城区',
                        value: '6550688903408386049',
                    },
                    {
                        label: '云安区',
                        value: '6550688903408386050',
                    },
                    {
                        label: '新兴县',
                        value: '6550688903408386051',
                    },
                    {
                        label: '郁南县',
                        value: '6550688903408386052',
                    },
                    {
                        label: '罗定市',
                        value: '6550688903408386053',
                    },
                ],
            },
        ],
    },
    {
        label: '广西',
        value: '6550688903408386054',
        children: [
            {
                label: '南宁市',
                value: '6550688903408386055',
                children: [
                    {
                        label: '兴宁区',
                        value: '6550688903408386056',
                    },
                    {
                        label: '青秀区',
                        value: '6550688903408386057',
                    },
                    {
                        label: '西乡塘区',
                        value: '6550688903408386058',
                    },
                    {
                        label: '江南区',
                        value: '6550688903408386059',
                    },
                    {
                        label: '良庆区',
                        value: '6550688903408386060',
                    },
                    {
                        label: '邕宁区',
                        value: '6550688903408386061',
                    },
                    {
                        label: '武鸣区',
                        value: '6550688903408386062',
                    },
                    {
                        label: '隆安县',
                        value: '6550688903408386063',
                    },
                    {
                        label: '马山县',
                        value: '6550688903408386064',
                    },
                    {
                        label: '上林县',
                        value: '6550688903408386065',
                    },
                    {
                        label: '宾阳县',
                        value: '6550688903408386066',
                    },
                    {
                        label: '横县',
                        value: '6550688903408386067',
                    },
                ],
            },
            {
                label: '柳州市',
                value: '6550688903408386068',
                children: [
                    {
                        label: '城中区',
                        value: '6550688903408386069',
                    },
                    {
                        label: '鱼峰区',
                        value: '6550688903408386070',
                    },
                    {
                        label: '柳南区',
                        value: '6550688903408386071',
                    },
                    {
                        label: '柳北区',
                        value: '6550688903408386072',
                    },
                    {
                        label: '柳江县',
                        value: '6550688903408386073',
                    },
                    {
                        label: '柳城县',
                        value: '6550688903408386074',
                    },
                    {
                        label: '鹿寨县',
                        value: '6550688903408386075',
                    },
                    {
                        label: '融安县',
                        value: '6550688903408386076',
                    },
                    {
                        label: '融水苗族自治县',
                        value: '6550688903408386077',
                    },
                    {
                        label: '三江侗族自治县',
                        value: '6550688903408386078',
                    },
                ],
            },
            {
                label: '桂林市',
                value: '6550688903412580352',
                children: [
                    {
                        label: '秀峰区',
                        value: '6550688903412580353',
                    },
                    {
                        label: '叠彩区',
                        value: '6550688903412580354',
                    },
                    {
                        label: '象山区',
                        value: '6550688903412580355',
                    },
                    {
                        label: '七星区',
                        value: '6550688903412580356',
                    },
                    {
                        label: '雁山区',
                        value: '6550688903412580357',
                    },
                    {
                        label: '临桂区',
                        value: '6550688903412580358',
                    },
                    {
                        label: '阳朔县',
                        value: '6550688903412580359',
                    },
                    {
                        label: '灵川县',
                        value: '6550688903412580360',
                    },
                    {
                        label: '全州县',
                        value: '6550688903412580361',
                    },
                    {
                        label: '兴安县',
                        value: '6550688903412580362',
                    },
                    {
                        label: '永福县',
                        value: '6550688903412580363',
                    },
                    {
                        label: '灌阳县',
                        value: '6550688903412580364',
                    },
                    {
                        label: '龙胜各族自治县',
                        value: '6550688903412580365',
                    },
                    {
                        label: '资源县',
                        value: '6550688903412580366',
                    },
                    {
                        label: '平乐县',
                        value: '6550688903412580367',
                    },
                    {
                        label: '荔蒲县',
                        value: '6550688903412580368',
                    },
                    {
                        label: '恭城瑶族自治县',
                        value: '6550688903412580369',
                    },
                ],
            },
            {
                label: '梧州市',
                value: '6550688903412580370',
                children: [
                    {
                        label: '万秀区',
                        value: '6550688903412580371',
                    },
                    {
                        label: '长洲区',
                        value: '6550688903412580372',
                    },
                    {
                        label: '龙圩区',
                        value: '6550688903412580373',
                    },
                    {
                        label: '苍梧县',
                        value: '6550688903412580374',
                    },
                    {
                        label: '藤县',
                        value: '6550688903412580375',
                    },
                    {
                        label: '蒙山县',
                        value: '6550688903412580376',
                    },
                    {
                        label: '岑溪市',
                        value: '6550688903412580377',
                    },
                ],
            },
            {
                label: '北海市',
                value: '6550688903412580378',
                children: [
                    {
                        label: '海城区',
                        value: '6550688903412580379',
                    },
                    {
                        label: '银海区',
                        value: '6550688903416774656',
                    },
                    {
                        label: '铁山港区',
                        value: '6550688903416774657',
                    },
                    {
                        label: '合浦县',
                        value: '6550688903416774658',
                    },
                ],
            },
            {
                label: '防城港市',
                value: '6550688903416774659',
                children: [
                    {
                        label: '港口区',
                        value: '6550688903416774660',
                    },
                    {
                        label: '防城区',
                        value: '6550688903416774661',
                    },
                    {
                        label: '上思县',
                        value: '6550688903416774662',
                    },
                    {
                        label: '东兴市',
                        value: '6550688903416774663',
                    },
                ],
            },
            {
                label: '钦州市',
                value: '6550688903416774664',
                children: [
                    {
                        label: '钦南区',
                        value: '6550688903416774665',
                    },
                    {
                        label: '钦北区',
                        value: '6550688903416774666',
                    },
                    {
                        label: '浦北县',
                        value: '6550688903416774667',
                    },
                    {
                        label: '灵山县',
                        value: '6550688903416774668',
                    },
                ],
            },
            {
                label: '贵港市',
                value: '6550688903416774669',
                children: [
                    {
                        label: '港北区',
                        value: '6550688903416774670',
                    },
                    {
                        label: '港南区',
                        value: '6550688903416774671',
                    },
                    {
                        label: '覃塘区',
                        value: '6550688903416774672',
                    },
                    {
                        label: '平南县',
                        value: '6550688903416774673',
                    },
                    {
                        label: '桂平市',
                        value: '6550688903416774674',
                    },
                ],
            },
            {
                label: '玉林市',
                value: '6550688903416774675',
                children: [
                    {
                        label: '玉州区',
                        value: '6550688903416774676',
                    },
                    {
                        label: '福绵区',
                        value: '6550688903416774677',
                    },
                    {
                        label: '容县',
                        value: '6550688903416774678',
                    },
                    {
                        label: '陆川县',
                        value: '6550688903416774679',
                    },
                    {
                        label: '博白县',
                        value: '6550688903416774680',
                    },
                    {
                        label: '兴业县',
                        value: '6550688903416774681',
                    },
                    {
                        label: '北流市',
                        value: '6550688903416774682',
                    },
                ],
            },
            {
                label: '崇左市',
                value: '6550688903416774683',
                children: [
                    {
                        label: '江州区',
                        value: '6550688903416774684',
                    },
                    {
                        label: '凭祥市',
                        value: '6550688903420968960',
                    },
                    {
                        label: '扶绥县',
                        value: '6550688903420968961',
                    },
                    {
                        label: '宁明县',
                        value: '6550688903420968962',
                    },
                    {
                        label: '龙州县',
                        value: '6550688903420968963',
                    },
                    {
                        label: '大新县',
                        value: '6550688903420968964',
                    },
                    {
                        label: '天等县',
                        value: '6550688903420968965',
                    },
                ],
            },
            {
                label: '来宾市',
                value: '6550688903420968966',
                children: [
                    {
                        label: '兴宾区',
                        value: '6550688903420968967',
                    },
                    {
                        label: '合山市',
                        value: '6550688903420968968',
                    },
                    {
                        label: '忻城县',
                        value: '6550688903420968969',
                    },
                    {
                        label: '象州县',
                        value: '6550688903420968970',
                    },
                    {
                        label: '武宣县',
                        value: '6550688903420968971',
                    },
                    {
                        label: '金秀瑶族自治县',
                        value: '6550688903420968972',
                    },
                ],
            },
            {
                label: '贺州市',
                value: '6550688903420968973',
                children: [
                    {
                        label: '八步区',
                        value: '6550688903420968974',
                    },
                    {
                        label: '平桂区',
                        value: '6550688903420968975',
                    },
                    {
                        label: '昭平县',
                        value: '6550688903420968976',
                    },
                    {
                        label: '钟山县',
                        value: '6550688903420968977',
                    },
                    {
                        label: '　富川瑶族自治县',
                        value: '6550688903420968978',
                    },
                ],
            },
            {
                label: '百色市',
                value: '6550688903420968979',
                children: [
                    {
                        label: '右江区',
                        value: '6550688903420968980',
                    },
                    {
                        label: '靖西市',
                        value: '6550688903420968981',
                    },
                    {
                        label: '田阳县',
                        value: '6550688903420968982',
                    },
                    {
                        label: '田东县',
                        value: '6550688903420968983',
                    },
                    {
                        label: '平果县',
                        value: '6550688903420968984',
                    },
                    {
                        label: '德保县',
                        value: '6550688903420968985',
                    },
                    {
                        label: '那坡县',
                        value: '6550688903420968986',
                    },
                    {
                        label: '凌云县',
                        value: '6550688903425163264',
                    },
                    {
                        label: '乐业县',
                        value: '6550688903425163265',
                    },
                    {
                        label: '田林县',
                        value: '6550688903425163266',
                    },
                    {
                        label: '隆林各族自治县',
                        value: '6550688903425163267',
                    },
                    {
                        label: '西林县',
                        value: '6550688903425163268',
                    },
                ],
            },
            {
                label: '河池市',
                value: '6550688903425163269',
                children: [
                    {
                        label: '金城江区',
                        value: '6550688903425163270',
                    },
                    {
                        label: '宜州区',
                        value: '6550688903425163271',
                    },
                    {
                        label: '罗城仫佬族自治县',
                        value: '6550688903425163272',
                    },
                    {
                        label: '环江毛南族自治县',
                        value: '6550688903425163273',
                    },
                    {
                        label: '南丹县',
                        value: '6550688903425163274',
                    },
                    {
                        label: '天峨县',
                        value: '6550688903425163275',
                    },
                    {
                        label: '凤山县',
                        value: '6550688903425163276',
                    },
                    {
                        label: '东兰县',
                        value: '6550688903425163277',
                    },
                    {
                        label: '巴马瑶族自治县',
                        value: '6550688903425163278',
                    },
                    {
                        label: '都安瑶族自治县',
                        value: '6550688903425163279',
                    },
                    {
                        label: '大化瑶族自治县',
                        value: '6550688903425163280',
                    },
                ],
            },
        ],
    },
    {
        label: '海南省',
        value: '6550688903425163281',
        children: [
            {
                label: '省直辖县级行政单位',
                value: '6550688903425163282',
                children: [
                    {
                        label: '琼海市',
                        value: '6550688903425163283',
                    },
                    {
                        label: '五指山市',
                        value: '6550688903429357568',
                    },
                    {
                        label: '文昌市',
                        value: '6550688903429357569',
                    },
                    {
                        label: '万宁市',
                        value: '6550688903429357570',
                    },
                    {
                        label: '东方市',
                        value: '6550688903429357571',
                    },
                    {
                        label: '定安县',
                        value: '6550688903429357572',
                    },
                    {
                        label: '屯昌县',
                        value: '6550688903429357573',
                    },
                    {
                        label: '澄迈县',
                        value: '6550688903429357574',
                    },
                    {
                        label: '临高县',
                        value: '6550688903429357575',
                    },
                    {
                        label: '白沙黎族自治县',
                        value: '6550688903429357576',
                    },
                    {
                        label: '昌江黎族自治县',
                        value: '6550688903429357577',
                    },
                    {
                        label: '乐东黎族自治县',
                        value: '6550688903429357578',
                    },
                    {
                        label: '陵水黎族自治县',
                        value: '6550688903429357579',
                    },
                    {
                        label: '保亭黎族苗族自治县',
                        value: '6550688903429357580',
                    },
                    {
                        label: '琼中黎族苗族自治县',
                        value: '6550688903429357581',
                    },
                ],
            },
            {
                label: '海口市',
                value: '6550688903429357582',
                children: [
                    {
                        label: '龙华区',
                        value: '6550688903429357583',
                    },
                    {
                        label: '秀英区',
                        value: '6550688903429357584',
                    },
                    {
                        label: '琼山区',
                        value: '6550688903429357585',
                    },
                    {
                        label: '美兰区',
                        value: '6550688903429357586',
                    },
                ],
            },
            {
                label: '三亚市',
                value: '6550688903433551872',
                children: [
                    {
                        label: '吉阳区',
                        value: '6550688903433551873',
                    },
                    {
                        label: '崖州区',
                        value: '6550688903433551874',
                    },
                    {
                        label: '天涯区',
                        value: '6550688903433551875',
                    },
                    {
                        label: '海棠区',
                        value: '6550688903433551876',
                    },
                ],
            },
            {
                label: '    儋州市',
                value: '6550688903433551877',
                children: [
                    {
                        label: '儋州市',
                        value: '6550688903433551878',
                    },
                ],
            },
            {
                label: '三沙市',
                value: '6550688903433551879',
                children: [
                    {
                        label: '三沙市',
                        value: '6550688903433551880',
                    },
                ],
            },
        ],
    },
    {
        label: '重庆市',
        value: '6550688903433551881',
        children: [
            {
                label: '重庆市',
                value: '6550688903433551882',
                children: [
                    {
                        label: '万州区',
                        value: '6550688903433551883',
                    },
                    {
                        label: '涪陵区',
                        value: '6550688903433551884',
                    },
                    {
                        label: '渝中区',
                        value: '6550688903433551885',
                    },
                    {
                        label: '大渡口区',
                        value: '6550688903433551886',
                    },
                    {
                        label: '江北区',
                        value: '6550688903433551887',
                    },
                    {
                        label: '沙坪坝区',
                        value: '6550688903437746176',
                    },
                    {
                        label: '九龙坡区',
                        value: '6550688903437746177',
                    },
                    {
                        label: '南岸区',
                        value: '6550688903437746178',
                    },
                    {
                        label: '北碚区',
                        value: '6550688903437746179',
                    },
                    {
                        label: '綦江区',
                        value: '6550688903437746180',
                    },
                    {
                        label: '大足区',
                        value: '6550688903437746181',
                    },
                    {
                        label: '渝北区',
                        value: '6550688903437746182',
                    },
                    {
                        label: '江津区',
                        value: '6550688903437746183',
                    },
                    {
                        label: '合川区',
                        value: '6550688903437746184',
                    },
                    {
                        label: '巴南区',
                        value: '6550688903437746185',
                    },
                    {
                        label: '长寿区',
                        value: '6550688903437746186',
                    },
                    {
                        label: '潼南区',
                        value: '6550688903437746187',
                    },
                    {
                        label: '铜梁区',
                        value: '6550688903437746188',
                    },
                    {
                        label: '璧山区',
                        value: '6550688903437746189',
                    },
                    {
                        label: '荣昌区',
                        value: '6550688903437746190',
                    },
                    {
                        label: '黔江区',
                        value: '6550688903437746191',
                    },
                    {
                        label: '永川区',
                        value: '6550688903437746192',
                    },
                    {
                        label: '南川区',
                        value: '6550688903437746193',
                    },
                    {
                        label: '开州区',
                        value: '6550688903437746194',
                    },
                    {
                        label: '梁平区',
                        value: '6550688903437746195',
                    },
                    {
                        label: '武隆区',
                        value: '6550688903441940480',
                    },
                    {
                        label: '丰都县',
                        value: '6550688903441940481',
                    },
                    {
                        label: '垫江县',
                        value: '6550688903441940482',
                    },
                    {
                        label: '城口县',
                        value: '6550688903441940483',
                    },
                    {
                        label: '忠县',
                        value: '6550688903441940484',
                    },
                    {
                        label: '云阳县',
                        value: '6550688903441940485',
                    },
                    {
                        label: '奉节县',
                        value: '6550688903441940486',
                    },
                    {
                        label: '巫山县',
                        value: '6550688903441940487',
                    },
                    {
                        label: '巫溪县',
                        value: '6550688903441940488',
                    },
                    {
                        label: '石柱土家族自治县',
                        value: '6550688903441940489',
                    },
                    {
                        label: '秀山土家族苗族自治县',
                        value: '6550688903441940490',
                    },
                    {
                        label: '酉阳土家族苗族自治县',
                        value: '6550688903441940491',
                    },
                    {
                        label: '彭水苗族土家族自治县',
                        value: '6550688903441940492',
                    },
                ],
            },
        ],
    },
    {
        label: '四川省',
        value: '6550688903441940493',
        children: [
            {
                label: '成都市',
                value: '6550688903441940494',
                children: [
                    {
                        label: '锦江区',
                        value: '6550688903441940495',
                    },
                    {
                        label: '青羊区',
                        value: '6550688903441940496',
                    },
                    {
                        label: '金牛区',
                        value: '6550688903441940497',
                    },
                    {
                        label: '武侯区',
                        value: '6550688903441940498',
                    },
                    {
                        label: '成华区',
                        value: '6550688903441940499',
                    },
                    {
                        label: '龙泉驿区',
                        value: '6550688903441940500',
                    },
                    {
                        label: '青白江区',
                        value: '6550688903441940501',
                    },
                    {
                        label: '温江区',
                        value: '6550688903441940502',
                    },
                    {
                        label: '新都区',
                        value: '6550688903441940503',
                    },
                    {
                        label: '双流区',
                        value: '6550688903441940504',
                    },
                    {
                        label: '郫都区',
                        value: '6550688903441940505',
                    },
                    {
                        label: '金堂县',
                        value: '6550688903441940506',
                    },
                    {
                        label: '大邑县',
                        value: '6550688903446134784',
                    },
                    {
                        label: '蒲江县',
                        value: '6550688903446134785',
                    },
                    {
                        label: '新津县',
                        value: '6550688903446134786',
                    },
                    {
                        label: '都江堰市',
                        value: '6550688903446134787',
                    },
                    {
                        label: '彭州市',
                        value: '6550688903446134788',
                    },
                    {
                        label: '邛崃市',
                        value: '6550688903446134789',
                    },
                    {
                        label: '崇州市',
                        value: '6550688903446134790',
                    },
                    {
                        label: '简阳市',
                        value: '6550688903446134791',
                    },
                ],
            },
            {
                label: '自贡市',
                value: '6550688903446134792',
                children: [
                    {
                        label: '自流井区',
                        value: '6550688903446134793',
                    },
                    {
                        label: '贡井区',
                        value: '6550688903446134794',
                    },
                    {
                        label: '大安区',
                        value: '6550688903446134795',
                    },
                    {
                        label: '沿滩区',
                        value: '6550688903446134796',
                    },
                    {
                        label: '荣县',
                        value: '6550688903446134797',
                    },
                    {
                        label: '富顺县',
                        value: '6550688903446134798',
                    },
                ],
            },
            {
                label: '攀枝花市',
                value: '6550688903446134799',
                children: [
                    {
                        label: '东区',
                        value: '6550688903446134800',
                    },
                    {
                        label: '西区',
                        value: '6550688903446134801',
                    },
                    {
                        label: '仁和区',
                        value: '6550688903446134802',
                    },
                    {
                        label: '米易县',
                        value: '6550688903446134803',
                    },
                    {
                        label: '盐边县',
                        value: '6550688903446134804',
                    },
                ],
            },
            {
                label: '泸州市',
                value: '6550688903446134805',
                children: [
                    {
                        label: '江阳区',
                        value: '6550688903446134806',
                    },
                    {
                        label: '纳溪区',
                        value: '6550688903446134807',
                    },
                    {
                        label: '龙马潭区',
                        value: '6550688903450329088',
                    },
                    {
                        label: '泸县',
                        value: '6550688903450329089',
                    },
                    {
                        label: '合江县',
                        value: '6550688903450329090',
                    },
                    {
                        label: '叙永县',
                        value: '6550688903450329091',
                    },
                    {
                        label: '古蔺县',
                        value: '6550688903450329092',
                    },
                ],
            },
            {
                label: '德阳市',
                value: '6550688903450329093',
                children: [
                    {
                        label: '旌阳区',
                        value: '6550688903450329094',
                    },
                    {
                        label: '中江县',
                        value: '6550688903450329095',
                    },
                    {
                        label: '罗江县',
                        value: '6550688903450329096',
                    },
                    {
                        label: '广汉市',
                        value: '6550688903450329097',
                    },
                    {
                        label: '什邡市',
                        value: '6550688903450329098',
                    },
                    {
                        label: '绵竹市',
                        value: '6550688903450329099',
                    },
                ],
            },
            {
                label: '绵阳市',
                value: '6550688903450329100',
                children: [
                    {
                        label: '涪城区',
                        value: '6550688903450329101',
                    },
                    {
                        label: '游仙区',
                        value: '6550688903450329102',
                    },
                    {
                        label: '三台县',
                        value: '6550688903450329103',
                    },
                    {
                        label: '盐亭县',
                        value: '6550688903450329104',
                    },
                    {
                        label: '安县',
                        value: '6550688903450329105',
                    },
                    {
                        label: '梓潼县',
                        value: '6550688903450329106',
                    },
                    {
                        label: '北川羌族自治县',
                        value: '6550688903450329107',
                    },
                    {
                        label: '平武县',
                        value: '6550688903454523392',
                    },
                    {
                        label: '江油市',
                        value: '6550688903454523393',
                    },
                ],
            },
            {
                label: '广元市',
                value: '6550688903454523394',
                children: [
                    {
                        label: '利州区',
                        value: '6550688903454523395',
                    },
                    {
                        label: '昭化区',
                        value: '6550688903454523396',
                    },
                    {
                        label: '朝天区',
                        value: '6550688903454523397',
                    },
                    {
                        label: '旺苍县',
                        value: '6550688903454523398',
                    },
                    {
                        label: '青川县',
                        value: '6550688903454523399',
                    },
                    {
                        label: '剑阁县',
                        value: '6550688903454523400',
                    },
                    {
                        label: '苍溪县',
                        value: '6550688903454523401',
                    },
                ],
            },
            {
                label: '遂宁市',
                value: '6550688903454523402',
                children: [
                    {
                        label: '船山区',
                        value: '6550688903454523403',
                    },
                    {
                        label: '安居区',
                        value: '6550688903454523404',
                    },
                    {
                        label: '蓬溪县',
                        value: '6550688903454523405',
                    },
                    {
                        label: '射洪县',
                        value: '6550688903454523406',
                    },
                    {
                        label: '大英县',
                        value: '6550688903454523407',
                    },
                ],
            },
            {
                label: '内江市',
                value: '6550688903454523408',
                children: [
                    {
                        label: '市中区',
                        value: '6550688903454523409',
                    },
                    {
                        label: '东兴区',
                        value: '6550688903454523410',
                    },
                    {
                        label: '威远县',
                        value: '6550688903454523411',
                    },
                    {
                        label: '资中县',
                        value: '6550688903454523412',
                    },
                    {
                        label: '隆昌县',
                        value: '6550688903454523413',
                    },
                ],
            },
            {
                label: '乐山市',
                value: '6550688903454523414',
                children: [
                    {
                        label: '市中区',
                        value: '6550688903454523415',
                    },
                    {
                        label: '沙湾区',
                        value: '6550688903458717696',
                    },
                    {
                        label: '五通桥区',
                        value: '6550688903458717697',
                    },
                    {
                        label: '金口河区',
                        value: '6550688903458717698',
                    },
                    {
                        label: '犍为县',
                        value: '6550688903458717699',
                    },
                    {
                        label: '井研县',
                        value: '6550688903458717700',
                    },
                    {
                        label: '夹江县',
                        value: '6550688903458717701',
                    },
                    {
                        label: '沐川县',
                        value: '6550688903458717702',
                    },
                    {
                        label: '峨边彝族自治县',
                        value: '6550688903458717703',
                    },
                    {
                        label: '马边彝族自治县',
                        value: '6550688903458717704',
                    },
                    {
                        label: '峨眉山市',
                        value: '6550688903458717705',
                    },
                ],
            },
            {
                label: '南充市',
                value: '6550688903458717706',
                children: [
                    {
                        label: '顺庆区',
                        value: '6550688903458717707',
                    },
                    {
                        label: '高坪区',
                        value: '6550688903458717708',
                    },
                    {
                        label: '嘉陵区',
                        value: '6550688903458717709',
                    },
                    {
                        label: '南部县',
                        value: '6550688903458717710',
                    },
                    {
                        label: '营山县',
                        value: '6550688903458717711',
                    },
                    {
                        label: '蓬安县',
                        value: '6550688903458717712',
                    },
                    {
                        label: '仪陇县',
                        value: '6550688903458717713',
                    },
                    {
                        label: '西充县',
                        value: '6550688903458717714',
                    },
                    {
                        label: '阆中市',
                        value: '6550688903458717715',
                    },
                ],
            },
            {
                label: '宜宾市',
                value: '6550688903458717716',
                children: [
                    {
                        label: '翠屏区',
                        value: '6550688903458717717',
                    },
                    {
                        label: '南溪区',
                        value: '6550688903458717718',
                    },
                    {
                        label: '宜宾县',
                        value: '6550688903458717719',
                    },
                    {
                        label: '江安县',
                        value: '6550688903458717720',
                    },
                    {
                        label: '长宁县',
                        value: '6550688903458717721',
                    },
                    {
                        label: '高县',
                        value: '6550688903458717722',
                    },
                    {
                        label: '珙县',
                        value: '6550688903458717723',
                    },
                    {
                        label: '筠连县',
                        value: '6550688903458717724',
                    },
                    {
                        label: '兴文县',
                        value: '6550688903462912000',
                    },
                    {
                        label: '屏山县',
                        value: '6550688903462912001',
                    },
                ],
            },
            {
                label: '广安市',
                value: '6550688903462912002',
                children: [
                    {
                        label: '广安区',
                        value: '6550688903462912003',
                    },
                    {
                        label: '前锋区',
                        value: '6550688903462912004',
                    },
                    {
                        label: '岳池县',
                        value: '6550688903462912005',
                    },
                    {
                        label: '武胜县',
                        value: '6550688903462912006',
                    },
                    {
                        label: '邻水县',
                        value: '6550688903462912007',
                    },
                    {
                        label: '华莹市',
                        value: '6550688903462912008',
                    },
                ],
            },
            {
                label: '达州市',
                value: '6550688903462912009',
                children: [
                    {
                        label: '达川区',
                        value: '6550688903462912010',
                    },
                    {
                        label: '通川区',
                        value: '6550688903462912011',
                    },
                    {
                        label: '宣汉县',
                        value: '6550688903462912012',
                    },
                    {
                        label: '开江县',
                        value: '6550688903462912013',
                    },
                    {
                        label: '大竹县',
                        value: '6550688903462912014',
                    },
                    {
                        label: '渠县',
                        value: '6550688903462912015',
                    },
                    {
                        label: '万源市',
                        value: '6550688903462912016',
                    },
                ],
            },
            {
                label: '雅安市',
                value: '6550688903462912017',
                children: [
                    {
                        label: '雨城区',
                        value: '6550688903462912018',
                    },
                    {
                        label: '名山区',
                        value: '6550688903462912019',
                    },
                    {
                        label: '荥经县',
                        value: '6550688903462912020',
                    },
                    {
                        label: '汉源县',
                        value: '6550688903462912021',
                    },
                    {
                        label: '石棉县',
                        value: '6550688903462912022',
                    },
                    {
                        label: '天全县',
                        value: '6550688903462912023',
                    },
                    {
                        label: '芦山县',
                        value: '6550688903462912024',
                    },
                    {
                        label: '宝兴县',
                        value: '6550688903462912025',
                    },
                ],
            },
            {
                label: '阿坝藏族羌族自治州',
                value: '6550688903462912026',
                children: [
                    {
                        label: '汶川县',
                        value: '6550688903462912027',
                    },
                    {
                        label: '理县',
                        value: '6550688903462912028',
                    },
                    {
                        label: '茂县',
                        value: '6550688903467106304',
                    },
                    {
                        label: '松潘县',
                        value: '6550688903467106305',
                    },
                    {
                        label: '九寨沟县',
                        value: '6550688903467106306',
                    },
                    {
                        label: '金川县',
                        value: '6550688903467106307',
                    },
                    {
                        label: '小金县',
                        value: '6550688903467106308',
                    },
                    {
                        label: '黑水县',
                        value: '6550688903467106309',
                    },
                    {
                        label: '马尔康市',
                        value: '6550688903467106310',
                    },
                    {
                        label: '壤塘县',
                        value: '6550688903467106311',
                    },
                    {
                        label: '阿坝县',
                        value: '6550688903467106312',
                    },
                    {
                        label: '若尔盖县',
                        value: '6550688903467106313',
                    },
                    {
                        label: '红原县',
                        value: '6550688903467106314',
                    },
                ],
            },
            {
                label: '甘孜藏族自治州',
                value: '6550688903467106315',
                children: [
                    {
                        label: '康定市',
                        value: '6550688903467106316',
                    },
                    {
                        label: '泸定县',
                        value: '6550688903467106317',
                    },
                    {
                        label: '丹巴县',
                        value: '6550688903467106318',
                    },
                    {
                        label: '九龙县',
                        value: '6550688903467106319',
                    },
                    {
                        label: '雅江县',
                        value: '6550688903467106320',
                    },
                    {
                        label: '道孚县',
                        value: '6550688903467106321',
                    },
                    {
                        label: '炉霍县',
                        value: '6550688903467106322',
                    },
                    {
                        label: '甘孜县',
                        value: '6550688903467106323',
                    },
                    {
                        label: '新龙县',
                        value: '6550688903467106324',
                    },
                    {
                        label: '德格县',
                        value: '6550688903467106325',
                    },
                    {
                        label: '白玉县',
                        value: '6550688903467106326',
                    },
                    {
                        label: '石渠县',
                        value: '6550688903467106327',
                    },
                    {
                        label: '色达县',
                        value: '6550688903467106328',
                    },
                    {
                        label: '理塘县',
                        value: '6550688903467106329',
                    },
                    {
                        label: '巴塘县',
                        value: '6550688903467106330',
                    },
                    {
                        label: '乡城县',
                        value: '6550688903467106331',
                    },
                    {
                        label: '稻城县',
                        value: '6550688903467106332',
                    },
                    {
                        label: '得荣县',
                        value: '6550688903467106333',
                    },
                ],
            },
            {
                label: '凉山彝族自治州',
                value: '6550688903471300608',
                children: [
                    {
                        label: '西昌市',
                        value: '6550688903471300609',
                    },
                    {
                        label: '木里藏族自治县',
                        value: '6550688903471300610',
                    },
                    {
                        label: '盐源县',
                        value: '6550688903471300611',
                    },
                    {
                        label: '德昌县',
                        value: '6550688903471300612',
                    },
                    {
                        label: '会理县',
                        value: '6550688903471300613',
                    },
                    {
                        label: '会东县',
                        value: '6550688903471300614',
                    },
                    {
                        label: '宁南县',
                        value: '6550688903471300615',
                    },
                    {
                        label: '普格县',
                        value: '6550688903471300616',
                    },
                    {
                        label: '布拖县',
                        value: '6550688903471300617',
                    },
                    {
                        label: '金阳县',
                        value: '6550688903471300618',
                    },
                    {
                        label: '昭觉县',
                        value: '6550688903471300619',
                    },
                    {
                        label: '喜德县',
                        value: '6550688903471300620',
                    },
                    {
                        label: '冕宁县',
                        value: '6550688903471300621',
                    },
                    {
                        label: '越西县',
                        value: '6550688903471300622',
                    },
                    {
                        label: '甘洛县',
                        value: '6550688903471300623',
                    },
                    {
                        label: '美姑县',
                        value: '6550688903471300624',
                    },
                    {
                        label: '雷波县',
                        value: '6550688903471300625',
                    },
                ],
            },
            {
                label: '巴中市',
                value: '6550688903471300626',
                children: [
                    {
                        label: '巴州区',
                        value: '6550688903471300627',
                    },
                    {
                        label: '恩阳区',
                        value: '6550688903471300628',
                    },
                    {
                        label: '通江县',
                        value: '6550688903471300629',
                    },
                    {
                        label: '南江县',
                        value: '6550688903471300630',
                    },
                    {
                        label: '平昌县',
                        value: '6550688903471300631',
                    },
                ],
            },
            {
                label: '眉山市',
                value: '6550688903471300632',
                children: [
                    {
                        label: '东坡区',
                        value: '6550688903471300633',
                    },
                    {
                        label: '彭山区',
                        value: '6550688903471300634',
                    },
                    {
                        label: '仁寿县',
                        value: '6550688903471300635',
                    },
                    {
                        label: '洪雅县',
                        value: '6550688903471300636',
                    },
                    {
                        label: '丹棱县',
                        value: '6550688903471300637',
                    },
                    {
                        label: '青神县',
                        value: '6550688903471300638',
                    },
                ],
            },
            {
                label: '资阳市',
                value: '6550688903475494912',
                children: [
                    {
                        label: '雁江区',
                        value: '6550688903475494913',
                    },
                    {
                        label: '安岳县',
                        value: '6550688903475494914',
                    },
                    {
                        label: '乐至县',
                        value: '6550688903475494915',
                    },
                ],
            },
        ],
    },
    {
        label: '贵州省',
        value: '6550688903475494916',
        children: [
            {
                label: '贵阳市',
                value: '6550688903475494917',
                children: [
                    {
                        label: '南明区',
                        value: '6550688903475494918',
                    },
                    {
                        label: '云岩区',
                        value: '6550688903475494919',
                    },
                    {
                        label: '花溪区',
                        value: '6550688903475494920',
                    },
                    {
                        label: '乌当区',
                        value: '6550688903475494921',
                    },
                    {
                        label: '白云区',
                        value: '6550688903475494922',
                    },
                    {
                        label: '观山湖区',
                        value: '6550688903475494923',
                    },
                    {
                        label: '开阳县',
                        value: '6550688903475494924',
                    },
                    {
                        label: '息烽县',
                        value: '6550688903475494925',
                    },
                    {
                        label: '修文县',
                        value: '6550688903475494926',
                    },
                    {
                        label: '清镇市',
                        value: '6550688903475494927',
                    },
                ],
            },
            {
                label: '六盘水市',
                value: '6550688903475494928',
                children: [
                    {
                        label: '钟山区',
                        value: '6550688903475494929',
                    },
                    {
                        label: '六枝特区',
                        value: '6550688903475494930',
                    },
                    {
                        label: '盘县',
                        value: '6550688903475494931',
                    },
                    {
                        label: '水城县',
                        value: '6550688903475494932',
                    },
                ],
            },
            {
                label: '遵义市',
                value: '6550688903475494933',
                children: [
                    {
                        label: '红花岗区',
                        value: '6550688903475494934',
                    },
                    {
                        label: '汇川区',
                        value: '6550688903475494935',
                    },
                    {
                        label: '遵义县',
                        value: '6550688903475494936',
                    },
                    {
                        label: '桐梓县',
                        value: '6550688903475494937',
                    },
                    {
                        label: '绥阳县',
                        value: '6550688903475494938',
                    },
                    {
                        label: '正安县',
                        value: '6550688903475494939',
                    },
                    {
                        label: '道真仡佬族苗族自治县',
                        value: '6550688903475494940',
                    },
                    {
                        label: '务川仡佬族苗族自治县',
                        value: '6550688903475494941',
                    },
                    {
                        label: '凤冈县',
                        value: '6550688903475494942',
                    },
                    {
                        label: '湄潭县',
                        value: '6550688903479689216',
                    },
                    {
                        label: '余庆县',
                        value: '6550688903479689217',
                    },
                    {
                        label: '习水县',
                        value: '6550688903479689218',
                    },
                    {
                        label: '赤水市',
                        value: '6550688903479689219',
                    },
                    {
                        label: '仁怀市',
                        value: '6550688903479689220',
                    },
                ],
            },
            {
                label: '铜仁市',
                value: '6550688903479689221',
                children: [
                    {
                        label: '碧江区',
                        value: '6550688903479689222',
                    },
                    {
                        label: '万山区',
                        value: '6550688903479689223',
                    },
                    {
                        label: '江口县',
                        value: '6550688903479689224',
                    },
                    {
                        label: '玉屏侗族自治县',
                        value: '6550688903479689225',
                    },
                    {
                        label: '石阡县',
                        value: '6550688903479689226',
                    },
                    {
                        label: '思南县',
                        value: '6550688903479689227',
                    },
                    {
                        label: '印江土家族苗族自治县',
                        value: '6550688903479689228',
                    },
                    {
                        label: '德江县',
                        value: '6550688903479689229',
                    },
                    {
                        label: '沿河土家族自治县',
                        value: '6550688903479689230',
                    },
                    {
                        label: '松桃苗族自治县',
                        value: '6550688903479689231',
                    },
                ],
            },
            {
                label: '黔西南布依族苗族自治州',
                value: '6550688903479689232',
                children: [
                    {
                        label: '兴义市',
                        value: '6550688903479689233',
                    },
                    {
                        label: '兴仁县',
                        value: '6550688903479689234',
                    },
                    {
                        label: '普安县',
                        value: '6550688903479689235',
                    },
                    {
                        label: '晴隆县',
                        value: '6550688903479689236',
                    },
                    {
                        label: '贞丰县',
                        value: '6550688903479689237',
                    },
                    {
                        label: '望谟县',
                        value: '6550688903479689238',
                    },
                    {
                        label: '册亨县',
                        value: '6550688903479689239',
                    },
                    {
                        label: '安龙县',
                        value: '6550688903479689240',
                    },
                ],
            },
            {
                label: '毕节市',
                value: '6550688903479689241',
                children: [
                    {
                        label: '七星关区',
                        value: '6550688903479689242',
                    },
                    {
                        label: '大方县',
                        value: '6550688903479689243',
                    },
                    {
                        label: '黔西县',
                        value: '6550688903483883520',
                    },
                    {
                        label: '金沙县',
                        value: '6550688903483883521',
                    },
                    {
                        label: '织金县',
                        value: '6550688903483883522',
                    },
                    {
                        label: '纳雍县',
                        value: '6550688903483883523',
                    },
                    {
                        label: '威宁彝族回族苗族自治县',
                        value: '6550688903483883524',
                    },
                    {
                        label: '赫章县',
                        value: '6550688903483883525',
                    },
                ],
            },
            {
                label: '安顺市',
                value: '6550688903483883526',
                children: [
                    {
                        label: '西秀区',
                        value: '6550688903483883527',
                    },
                    {
                        label: '平坝区',
                        value: '6550688903483883528',
                    },
                    {
                        label: '普定县',
                        value: '6550688903483883529',
                    },
                    {
                        label: '关岭布依族苗族自治县',
                        value: '6550688903483883530',
                    },
                    {
                        label: '镇宁布依族苗族自治县',
                        value: '6550688903483883531',
                    },
                    {
                        label: '紫云苗族布依族自治县',
                        value: '6550688903483883532',
                    },
                ],
            },
            {
                label: '黔东南苗族侗族自治州',
                value: '6550688903483883533',
                children: [
                    {
                        label: '凯里市',
                        value: '6550688903483883534',
                    },
                    {
                        label: '黄平县',
                        value: '6550688903483883535',
                    },
                    {
                        label: '施秉县',
                        value: '6550688903483883536',
                    },
                    {
                        label: '三穗县',
                        value: '6550688903483883537',
                    },
                    {
                        label: '镇远县',
                        value: '6550688903483883538',
                    },
                    {
                        label: '岑巩县',
                        value: '6550688903483883539',
                    },
                    {
                        label: '天柱县',
                        value: '6550688903483883540',
                    },
                    {
                        label: '锦屏县',
                        value: '6550688903483883541',
                    },
                    {
                        label: '剑河县',
                        value: '6550688903483883542',
                    },
                    {
                        label: '台江县',
                        value: '6550688903483883543',
                    },
                    {
                        label: '黎平县',
                        value: '6550688903483883544',
                    },
                    {
                        label: '榕江县',
                        value: '6550688903483883545',
                    },
                    {
                        label: '从江县',
                        value: '6550688903483883546',
                    },
                    {
                        label: '雷山县',
                        value: '6550688903483883547',
                    },
                    {
                        label: '麻江县',
                        value: '6550688903483883548',
                    },
                    {
                        label: '丹寨县',
                        value: '6550688903483883549',
                    },
                ],
            },
            {
                label: '黔南布依族苗族自治州',
                value: '6550688903488077824',
                children: [
                    {
                        label: '都匀市',
                        value: '6550688903488077825',
                    },
                    {
                        label: '福泉市',
                        value: '6550688903488077826',
                    },
                    {
                        label: '荔波县',
                        value: '6550688903488077827',
                    },
                    {
                        label: '贵定县',
                        value: '6550688903488077828',
                    },
                    {
                        label: '瓮安县',
                        value: '6550688903488077829',
                    },
                    {
                        label: '独山县',
                        value: '6550688903488077830',
                    },
                    {
                        label: '平塘县',
                        value: '6550688903488077831',
                    },
                    {
                        label: '罗甸县',
                        value: '6550688903488077832',
                    },
                    {
                        label: '长顺县',
                        value: '6550688903488077833',
                    },
                    {
                        label: '龙里县',
                        value: '6550688903488077834',
                    },
                    {
                        label: '惠水县',
                        value: '6550688903488077835',
                    },
                    {
                        label: '三都水族自治县',
                        value: '6550688903488077836',
                    },
                ],
            },
        ],
    },
    {
        label: '云南省',
        value: '6550688903488077837',
        children: [
            {
                label: '昆明市',
                value: '6550688903488077838',
                children: [
                    {
                        label: '五华区',
                        value: '6550688903488077839',
                    },
                    {
                        label: '盘龙区',
                        value: '6550688903488077840',
                    },
                    {
                        label: '官渡区',
                        value: '6550688903488077841',
                    },
                    {
                        label: '西山区',
                        value: '6550688903488077842',
                    },
                    {
                        label: '东川区',
                        value: '6550688903488077843',
                    },
                    {
                        label: '呈贡区',
                        value: '6550688903488077844',
                    },
                    {
                        label: '晋宁区',
                        value: '6550688903488077845',
                    },
                    {
                        label: '富民县',
                        value: '6550688903488077846',
                    },
                    {
                        label: '宜良县',
                        value: '6550688903488077847',
                    },
                    {
                        label: '石林彝族自治县',
                        value: '6550688903488077848',
                    },
                    {
                        label: '嵩明县',
                        value: '6550688903488077849',
                    },
                    {
                        label: '禄劝彝族苗族自治县',
                        value: '6550688903488077850',
                    },
                    {
                        label: '寻甸回族自治县',
                        value: '6550688903488077851',
                    },
                    {
                        label: '安宁市',
                        value: '6550688903488077852',
                    },
                ],
            },
            {
                label: '曲靖市',
                value: '6550688903488077853',
                children: [
                    {
                        label: '麒麟区',
                        value: '6550688903492272128',
                    },
                    {
                        label: '马龙县',
                        value: '6550688903492272129',
                    },
                    {
                        label: '陆良县',
                        value: '6550688903492272130',
                    },
                    {
                        label: '师宗县',
                        value: '6550688903492272131',
                    },
                    {
                        label: '罗平县',
                        value: '6550688903492272132',
                    },
                    {
                        label: '富源县',
                        value: '6550688903492272133',
                    },
                    {
                        label: '会泽县',
                        value: '6550688903492272134',
                    },
                    {
                        label: '沾益县',
                        value: '6550688903492272135',
                    },
                    {
                        label: '宣威市',
                        value: '6550688903492272136',
                    },
                ],
            },
            {
                label: '玉溪市',
                value: '6550688903492272137',
                children: [
                    {
                        label: '红塔区',
                        value: '6550688903492272138',
                    },
                    {
                        label: '江川区',
                        value: '6550688903492272139',
                    },
                    {
                        label: '澄江县',
                        value: '6550688903492272140',
                    },
                    {
                        label: '通海县',
                        value: '6550688903492272141',
                    },
                    {
                        label: '华宁县',
                        value: '6550688903492272142',
                    },
                    {
                        label: '易门县',
                        value: '6550688903492272143',
                    },
                    {
                        label: '峨山彝族自治县',
                        value: '6550688903492272144',
                    },
                    {
                        label: '新平彝族傣族自治县',
                        value: '6550688903492272145',
                    },
                    {
                        label: '元江哈尼族彝族傣族自治县',
                        value: '6550688903492272146',
                    },
                ],
            },
            {
                label: '昭通市',
                value: '6550688903492272147',
                children: [
                    {
                        label: '昭阳区',
                        value: '6550688903492272148',
                    },
                    {
                        label: '鲁甸县',
                        value: '6550688903492272149',
                    },
                    {
                        label: '巧家县',
                        value: '6550688903492272150',
                    },
                    {
                        label: '盐津县',
                        value: '6550688903492272151',
                    },
                    {
                        label: '大关县',
                        value: '6550688903492272152',
                    },
                    {
                        label: '永善县',
                        value: '6550688903492272153',
                    },
                    {
                        label: '绥江县',
                        value: '6550688903492272154',
                    },
                    {
                        label: '镇雄县',
                        value: '6550688903492272155',
                    },
                    {
                        label: '彝良县',
                        value: '6550688903492272156',
                    },
                    {
                        label: '威信县',
                        value: '6550688903492272157',
                    },
                    {
                        label: '水富县',
                        value: '6550688903496466432',
                    },
                ],
            },
            {
                label: '楚雄彝族自治州',
                value: '6550688903496466433',
                children: [
                    {
                        label: '楚雄市',
                        value: '6550688903496466434',
                    },
                    {
                        label: '双柏县',
                        value: '6550688903496466435',
                    },
                    {
                        label: '牟定县',
                        value: '6550688903496466436',
                    },
                    {
                        label: '南华县',
                        value: '6550688903496466437',
                    },
                    {
                        label: '姚安县',
                        value: '6550688903496466438',
                    },
                    {
                        label: '大姚县',
                        value: '6550688903496466439',
                    },
                    {
                        label: '永仁县',
                        value: '6550688903496466440',
                    },
                    {
                        label: '元谋县',
                        value: '6550688903496466441',
                    },
                    {
                        label: '武定县',
                        value: '6550688903496466442',
                    },
                    {
                        label: '禄丰县',
                        value: '6550688903496466443',
                    },
                ],
            },
            {
                label: '红河哈尼族彝族自治州',
                value: '6550688903496466444',
                children: [
                    {
                        label: '个旧市',
                        value: '6550688903496466445',
                    },
                    {
                        label: '开远市',
                        value: '6550688903496466446',
                    },
                    {
                        label: '蒙自市',
                        value: '6550688903496466447',
                    },
                    {
                        label: '屏边苗族自治县',
                        value: '6550688903496466448',
                    },
                    {
                        label: '建水县',
                        value: '6550688903496466449',
                    },
                    {
                        label: '石屏县',
                        value: '6550688903496466450',
                    },
                    {
                        label: '弥勒市',
                        value: '6550688903496466451',
                    },
                    {
                        label: '泸西县',
                        value: '6550688903496466452',
                    },
                    {
                        label: '元阳县',
                        value: '6550688903496466453',
                    },
                    {
                        label: '红河县',
                        value: '6550688903496466454',
                    },
                    {
                        label: '金平苗族瑶族傣族自治县',
                        value: '6550688903496466455',
                    },
                    {
                        label: '绿春县',
                        value: '6550688903496466456',
                    },
                    {
                        label: '河口瑶族自治县',
                        value: '6550688903496466457',
                    },
                ],
            },
            {
                label: '文山壮族苗族自治州',
                value: '6550688903496466458',
                children: [
                    {
                        label: '文山市',
                        value: '6550688903496466459',
                    },
                    {
                        label: '砚山县',
                        value: '6550688903496466460',
                    },
                    {
                        label: '西畴县',
                        value: '6550688903496466461',
                    },
                    {
                        label: '麻栗坡县',
                        value: '6550688903496466462',
                    },
                    {
                        label: '马关县',
                        value: '6550688903500660736',
                    },
                    {
                        label: '丘北县',
                        value: '6550688903500660737',
                    },
                    {
                        label: '广南县',
                        value: '6550688903500660738',
                    },
                    {
                        label: '富宁县',
                        value: '6550688903500660739',
                    },
                ],
            },
            {
                label: '普洱市',
                value: '6550688903500660740',
                children: [
                    {
                        label: '思茅区',
                        value: '6550688903500660741',
                    },
                    {
                        label: '宁洱哈尼族彝族自治县',
                        value: '6550688903500660742',
                    },
                    {
                        label: '墨江哈尼族自治县',
                        value: '6550688903500660743',
                    },
                    {
                        label: '景东彝族自治县',
                        value: '6550688903500660744',
                    },
                    {
                        label: '景谷傣族彝族自治县',
                        value: '6550688903500660745',
                    },
                    {
                        label: '镇沅彝族哈尼族拉祜族自治县',
                        value: '6550688903500660746',
                    },
                    {
                        label: '江城哈尼族彝族自治县',
                        value: '6550688903500660747',
                    },
                    {
                        label: '孟连傣族拉祜族佤族自治县',
                        value: '6550688903500660748',
                    },
                    {
                        label: '澜沧拉祜族自治县',
                        value: '6550688903500660749',
                    },
                    {
                        label: '西盟佤族自治县',
                        value: '6550688903500660750',
                    },
                ],
            },
            {
                label: '西双版纳傣族自治州',
                value: '6550688903500660751',
                children: [
                    {
                        label: '景洪市',
                        value: '6550688903500660752',
                    },
                    {
                        label: '勐海县',
                        value: '6550688903500660753',
                    },
                    {
                        label: '勐腊县',
                        value: '6550688903500660754',
                    },
                ],
            },
            {
                label: '大理白族自治州',
                value: '6550688903504855040',
                children: [
                    {
                        label: '大理市',
                        value: '6550688903504855041',
                    },
                    {
                        label: '漾濞彝族自治县',
                        value: '6550688903504855042',
                    },
                    {
                        label: '祥云县',
                        value: '6550688903504855043',
                    },
                    {
                        label: '宾川县',
                        value: '6550688903504855044',
                    },
                    {
                        label: '弥渡县',
                        value: '6550688903504855045',
                    },
                    {
                        label: '南涧彝族自治县',
                        value: '6550688903504855046',
                    },
                    {
                        label: '巍山彝族回族自治县',
                        value: '6550688903504855047',
                    },
                    {
                        label: '永平县',
                        value: '6550688903504855048',
                    },
                    {
                        label: '云龙县',
                        value: '6550688903504855049',
                    },
                    {
                        label: '洱源县',
                        value: '6550688903504855050',
                    },
                    {
                        label: '剑川县',
                        value: '6550688903504855051',
                    },
                    {
                        label: '鹤庆县',
                        value: '6550688903504855052',
                    },
                ],
            },
            {
                label: '保山市',
                value: '6550688903504855053',
                children: [
                    {
                        label: '隆阳区',
                        value: '6550688903504855054',
                    },
                    {
                        label: '施甸县',
                        value: '6550688903504855055',
                    },
                    {
                        label: '腾冲市',
                        value: '6550688903504855056',
                    },
                    {
                        label: '龙陵县',
                        value: '6550688903504855057',
                    },
                    {
                        label: '昌宁县',
                        value: '6550688903504855058',
                    },
                ],
            },
            {
                label: '德宏傣族景颇族自治州',
                value: '6550688903504855059',
                children: [
                    {
                        label: '瑞丽市',
                        value: '6550688903504855060',
                    },
                    {
                        label: '芒市',
                        value: '6550688903504855061',
                    },
                    {
                        label: '梁河县',
                        value: '6550688903504855062',
                    },
                    {
                        label: '盈江县',
                        value: '6550688903504855063',
                    },
                    {
                        label: '陇川县',
                        value: '6550688903504855064',
                    },
                ],
            },
            {
                label: '丽江市',
                value: '6550688903504855065',
                children: [
                    {
                        label: '古城区',
                        value: '6550688903504855066',
                    },
                    {
                        label: '玉龙纳西族自治县',
                        value: '6550688903504855067',
                    },
                    {
                        label: '永胜县',
                        value: '6550688903504855068',
                    },
                    {
                        label: '华坪县',
                        value: '6550688903509049344',
                    },
                    {
                        label: '宁蒗彝族自治县',
                        value: '6550688903509049345',
                    },
                ],
            },
            {
                label: '怒江傈僳族自治州',
                value: '6550688903509049346',
                children: [
                    {
                        label: '泸水市',
                        value: '6550688903509049347',
                    },
                    {
                        label: '福贡县',
                        value: '6550688903509049348',
                    },
                    {
                        label: '贡山独龙族怒族自治县',
                        value: '6550688903509049349',
                    },
                    {
                        label: '兰坪白族普米族自治县',
                        value: '6550688903509049350',
                    },
                ],
            },
            {
                label: '迪庆藏族自治州',
                value: '6550688903509049351',
                children: [
                    {
                        label: '香格里拉市',
                        value: '6550688903509049352',
                    },
                    {
                        label: '德钦县',
                        value: '6550688903509049353',
                    },
                    {
                        label: '维西傈僳族自治县',
                        value: '6550688903509049354',
                    },
                ],
            },
            {
                label: '临沧市',
                value: '6550688903509049355',
                children: [
                    {
                        label: '临翔区',
                        value: '6550688903509049356',
                    },
                    {
                        label: '凤庆县',
                        value: '6550688903509049357',
                    },
                    {
                        label: '云县',
                        value: '6550688903509049358',
                    },
                    {
                        label: '永德县',
                        value: '6550688903509049359',
                    },
                    {
                        label: '镇康县',
                        value: '6550688903509049360',
                    },
                    {
                        label: '双江拉祜族佤族布朗族傣族自治县',
                        value: '6550688903509049361',
                    },
                    {
                        label: '耿马傣族佤族自治县',
                        value: '6550688903509049362',
                    },
                    {
                        label: '沧源佤族自治县',
                        value: '6550688903509049363',
                    },
                ],
            },
        ],
    },
    {
        label: '西藏',
        value: '6550688903509049364',
        children: [
            {
                label: '拉萨市',
                value: '6550688903509049365',
                children: [
                    {
                        label: '城关区',
                        value: '6550688903509049366',
                    },
                    {
                        label: '林周县',
                        value: '6550688903509049367',
                    },
                    {
                        label: '当雄县',
                        value: '6550688903509049368',
                    },
                    {
                        label: '尼木县',
                        value: '6550688903509049369',
                    },
                    {
                        label: '曲水县',
                        value: '6550688903513243648',
                    },
                    {
                        label: '堆龙德庆县',
                        value: '6550688903513243649',
                    },
                    {
                        label: '达孜县',
                        value: '6550688903513243650',
                    },
                    {
                        label: '墨竹工卡县',
                        value: '6550688903513243651',
                    },
                ],
            },
            {
                label: '昌都市',
                value: '6550688903513243652',
                children: [
                    {
                        label: '卡若区',
                        value: '6550688903513243653',
                    },
                    {
                        label: '江达县',
                        value: '6550688903513243654',
                    },
                    {
                        label: '贡觉县',
                        value: '6550688903513243655',
                    },
                    {
                        label: '类乌齐县',
                        value: '6550688903513243656',
                    },
                    {
                        label: '丁青县',
                        value: '6550688903513243657',
                    },
                    {
                        label: '察雅县',
                        value: '6550688903513243658',
                    },
                    {
                        label: '八宿县',
                        value: '6550688903513243659',
                    },
                    {
                        label: '左贡县',
                        value: '6550688903513243660',
                    },
                    {
                        label: '芒康县',
                        value: '6550688903513243661',
                    },
                    {
                        label: '洛隆县',
                        value: '6550688903513243662',
                    },
                    {
                        label: '边坝县',
                        value: '6550688903513243663',
                    },
                ],
            },
            {
                label: '山南地区',
                value: '6550688903513243664',
                children: [
                    {
                        label: '乃东县',
                        value: '6550688903513243665',
                    },
                    {
                        label: '扎囊县',
                        value: '6550688903513243666',
                    },
                    {
                        label: '贡嘎县',
                        value: '6550688903513243667',
                    },
                    {
                        label: '桑日县',
                        value: '6550688903513243668',
                    },
                    {
                        label: '琼结县',
                        value: '6550688903513243669',
                    },
                    {
                        label: '曲松县',
                        value: '6550688903513243670',
                    },
                    {
                        label: '措美县',
                        value: '6550688903513243671',
                    },
                    {
                        label: '洛扎县',
                        value: '6550688903513243672',
                    },
                    {
                        label: '加查县',
                        value: '6550688903513243673',
                    },
                    {
                        label: '隆子县',
                        value: '6550688903513243674',
                    },
                    {
                        label: '错那县',
                        value: '6550688903513243675',
                    },
                    {
                        label: '浪卡子县',
                        value: '6550688903513243676',
                    },
                ],
            },
            {
                label: '日喀则市',
                value: '6550688903513243677',
                children: [
                    {
                        label: '桑珠孜区',
                        value: '6550688903517437952',
                    },
                    {
                        label: '南木林县',
                        value: '6550688903517437953',
                    },
                    {
                        label: '江孜县',
                        value: '6550688903517437954',
                    },
                    {
                        label: '定日县',
                        value: '6550688903517437955',
                    },
                    {
                        label: '萨迦县',
                        value: '6550688903517437956',
                    },
                    {
                        label: '拉孜县',
                        value: '6550688903517437957',
                    },
                    {
                        label: '昂仁县',
                        value: '6550688903517437958',
                    },
                    {
                        label: '谢通门县',
                        value: '6550688903517437959',
                    },
                    {
                        label: '白朗县',
                        value: '6550688903517437960',
                    },
                    {
                        label: '仁布县',
                        value: '6550688903517437961',
                    },
                    {
                        label: '康马县',
                        value: '6550688903517437962',
                    },
                    {
                        label: '定结县',
                        value: '6550688903517437963',
                    },
                    {
                        label: '仲巴县',
                        value: '6550688903517437964',
                    },
                    {
                        label: '亚东县',
                        value: '6550688903517437965',
                    },
                    {
                        label: '吉隆县',
                        value: '6550688903517437966',
                    },
                    {
                        label: '聂拉木县',
                        value: '6550688903517437967',
                    },
                    {
                        label: '萨嘎县',
                        value: '6550688903517437968',
                    },
                    {
                        label: '岗巴县',
                        value: '6550688903517437969',
                    },
                ],
            },
            {
                label: '那曲地区',
                value: '6550688903517437970',
                children: [
                    {
                        label: '那曲县',
                        value: '6550688903517437971',
                    },
                    {
                        label: '嘉黎县',
                        value: '6550688903517437972',
                    },
                    {
                        label: '比如县',
                        value: '6550688903517437973',
                    },
                    {
                        label: '聂荣县',
                        value: '6550688903517437974',
                    },
                    {
                        label: '安多县',
                        value: '6550688903517437975',
                    },
                    {
                        label: '申扎县',
                        value: '6550688903517437976',
                    },
                    {
                        label: '索县',
                        value: '6550688903517437977',
                    },
                    {
                        label: '班戈县',
                        value: '6550688903517437978',
                    },
                    {
                        label: '双湖县',
                        value: '6550688903517437979',
                    },
                    {
                        label: '巴青县',
                        value: '6550688903517437980',
                    },
                    {
                        label: '尼玛县',
                        value: '6550688903517437981',
                    },
                ],
            },
            {
                label: '阿里地区',
                value: '6550688903517437982',
                children: [
                    {
                        label: '普兰县',
                        value: '6550688903521632256',
                    },
                    {
                        label: '札达县',
                        value: '6550688903521632257',
                    },
                    {
                        label: '噶尔县',
                        value: '6550688903521632258',
                    },
                    {
                        label: '日土县',
                        value: '6550688903521632259',
                    },
                    {
                        label: '革吉县',
                        value: '6550688903521632260',
                    },
                    {
                        label: '改则县',
                        value: '6550688903521632261',
                    },
                    {
                        label: '措勤县',
                        value: '6550688903521632262',
                    },
                ],
            },
            {
                label: '林芝地区',
                value: '6550688903521632263',
                children: [
                    {
                        label: '林芝县',
                        value: '6550688903521632264',
                    },
                    {
                        label: '工布江达县',
                        value: '6550688903521632265',
                    },
                    {
                        label: '米林县',
                        value: '6550688903521632266',
                    },
                    {
                        label: '墨脱县',
                        value: '6550688903521632267',
                    },
                    {
                        label: '波密县',
                        value: '6550688903521632268',
                    },
                    {
                        label: '察隅县',
                        value: '6550688903521632269',
                    },
                    {
                        label: '朗县',
                        value: '6550688903521632270',
                    },
                ],
            },
        ],
    },
    {
        label: '陕西省',
        value: '6550688903521632271',
        children: [
            {
                label: '西安市',
                value: '6550688903521632272',
                children: [
                    {
                        label: '新城区',
                        value: '6550688903521632273',
                    },
                    {
                        label: '碑林区',
                        value: '6550688903521632274',
                    },
                    {
                        label: '莲湖区',
                        value: '6550688903521632275',
                    },
                    {
                        label: '灞桥区',
                        value: '6550688903521632276',
                    },
                    {
                        label: '未央区',
                        value: '6550688903521632277',
                    },
                    {
                        label: '雁塔区',
                        value: '6550688903521632278',
                    },
                    {
                        label: '阎良区',
                        value: '6550688903521632279',
                    },
                    {
                        label: '临潼区',
                        value: '6550688903521632280',
                    },
                    {
                        label: '长安区',
                        value: '6550688903521632281',
                    },
                    {
                        label: '高陵区',
                        value: '6550688903521632282',
                    },
                    {
                        label: '鄠邑区',
                        value: '6550688903521632283',
                    },
                    {
                        label: '周至县',
                        value: '6550688903521632284',
                    },
                    {
                        label: '蓝田县',
                        value: '6550688903521632285',
                    },
                ],
            },
            {
                label: '铜川市',
                value: '6550688903521632286',
                children: [
                    {
                        label: '王益区',
                        value: '6550688903525826560',
                    },
                    {
                        label: '印台区',
                        value: '6550688903525826561',
                    },
                    {
                        label: '耀州区',
                        value: '6550688903525826562',
                    },
                    {
                        label: '宜君县',
                        value: '6550688903525826563',
                    },
                ],
            },
            {
                label: '宝鸡市',
                value: '6550688903525826564',
                children: [
                    {
                        label: '渭滨区',
                        value: '6550688903525826565',
                    },
                    {
                        label: '金台区',
                        value: '6550688903525826566',
                    },
                    {
                        label: '陈仓区',
                        value: '6550688903525826567',
                    },
                    {
                        label: '凤翔县',
                        value: '6550688903525826568',
                    },
                    {
                        label: '岐山县',
                        value: '6550688903525826569',
                    },
                    {
                        label: '扶风县',
                        value: '6550688903525826570',
                    },
                    {
                        label: '眉县',
                        value: '6550688903525826571',
                    },
                    {
                        label: '陇县',
                        value: '6550688903525826572',
                    },
                    {
                        label: '千阳县',
                        value: '6550688903525826573',
                    },
                    {
                        label: '麟游县',
                        value: '6550688903525826574',
                    },
                    {
                        label: '凤县',
                        value: '6550688903525826575',
                    },
                    {
                        label: '太白县',
                        value: '6550688903525826576',
                    },
                ],
            },
            {
                label: '咸阳市',
                value: '6550688903525826577',
                children: [
                    {
                        label: '秦都区',
                        value: '6550688903525826578',
                    },
                    {
                        label: '杨陵区',
                        value: '6550688903525826579',
                    },
                    {
                        label: '渭城区',
                        value: '6550688903525826580',
                    },
                    {
                        label: '三原县',
                        value: '6550688903525826581',
                    },
                    {
                        label: '泾阳县',
                        value: '6550688903525826582',
                    },
                    {
                        label: '乾县',
                        value: '6550688903525826583',
                    },
                    {
                        label: '礼泉县',
                        value: '6550688903525826584',
                    },
                    {
                        label: '永寿县',
                        value: '6550688903525826585',
                    },
                    {
                        label: '彬县',
                        value: '6550688903525826586',
                    },
                    {
                        label: '长武县',
                        value: '6550688903525826587',
                    },
                    {
                        label: '旬邑县',
                        value: '6550688903525826588',
                    },
                    {
                        label: '淳化县',
                        value: '6550688903530020864',
                    },
                    {
                        label: '武功县',
                        value: '6550688903530020865',
                    },
                    {
                        label: '兴平市',
                        value: '6550688903530020866',
                    },
                ],
            },
            {
                label: '渭南市',
                value: '6550688903530020867',
                children: [
                    {
                        label: '临渭区',
                        value: '6550688903530020868',
                    },
                    {
                        label: '华州区',
                        value: '6550688903530020869',
                    },
                    {
                        label: '潼关县',
                        value: '6550688903530020870',
                    },
                    {
                        label: '大荔县',
                        value: '6550688903530020871',
                    },
                    {
                        label: '合阳县',
                        value: '6550688903530020872',
                    },
                    {
                        label: '澄城县',
                        value: '6550688903530020873',
                    },
                    {
                        label: '蒲城县',
                        value: '6550688903530020874',
                    },
                    {
                        label: '白水县',
                        value: '6550688903530020875',
                    },
                    {
                        label: '富平县',
                        value: '6550688903530020876',
                    },
                    {
                        label: '韩城市',
                        value: '6550688903530020877',
                    },
                    {
                        label: '华阴市',
                        value: '6550688903530020878',
                    },
                ],
            },
            {
                label: '延安市',
                value: '6550688903530020879',
                children: [
                    {
                        label: '宝塔区',
                        value: '6550688903530020880',
                    },
                    {
                        label: '安塞区',
                        value: '6550688903530020881',
                    },
                    {
                        label: '延长县',
                        value: '6550688903530020882',
                    },
                    {
                        label: '延川县',
                        value: '6550688903530020883',
                    },
                    {
                        label: '子长县',
                        value: '6550688903530020884',
                    },
                    {
                        label: '志丹县',
                        value: '6550688903530020885',
                    },
                    {
                        label: '吴旗县',
                        value: '6550688903530020886',
                    },
                    {
                        label: '甘泉县',
                        value: '6550688903530020887',
                    },
                    {
                        label: '富县',
                        value: '6550688903530020888',
                    },
                    {
                        label: '洛川县',
                        value: '6550688903530020889',
                    },
                    {
                        label: '宜川县',
                        value: '6550688903530020890',
                    },
                    {
                        label: '黄龙县',
                        value: '6550688903530020891',
                    },
                    {
                        label: '黄陵县',
                        value: '6550688903530020892',
                    },
                ],
            },
            {
                label: '汉中市',
                value: '6550688903530020893',
                children: [
                    {
                        label: '汉台区',
                        value: '6550688903530020894',
                    },
                    {
                        label: '南郑县',
                        value: '6550688903534215168',
                    },
                    {
                        label: '城固县',
                        value: '6550688903534215169',
                    },
                    {
                        label: '洋县',
                        value: '6550688903534215170',
                    },
                    {
                        label: '西乡县',
                        value: '6550688903534215171',
                    },
                    {
                        label: '勉县',
                        value: '6550688903534215172',
                    },
                    {
                        label: '宁强县',
                        value: '6550688903534215173',
                    },
                    {
                        label: '略阳县',
                        value: '6550688903534215174',
                    },
                    {
                        label: '镇巴县',
                        value: '6550688903534215175',
                    },
                    {
                        label: '留坝县',
                        value: '6550688903534215176',
                    },
                    {
                        label: '佛坪县',
                        value: '6550688903534215177',
                    },
                ],
            },
            {
                label: '安康市',
                value: '6550688903534215178',
                children: [
                    {
                        label: '汉滨区',
                        value: '6550688903534215179',
                    },
                    {
                        label: '汉阴县',
                        value: '6550688903534215180',
                    },
                    {
                        label: '石泉县',
                        value: '6550688903534215181',
                    },
                    {
                        label: '宁陕县',
                        value: '6550688903534215182',
                    },
                    {
                        label: '紫阳县',
                        value: '6550688903534215183',
                    },
                    {
                        label: '岚皋县',
                        value: '6550688903534215184',
                    },
                    {
                        label: '平利县',
                        value: '6550688903534215185',
                    },
                    {
                        label: '镇坪县',
                        value: '6550688903534215186',
                    },
                    {
                        label: '旬阳县',
                        value: '6550688903534215187',
                    },
                    {
                        label: '白河县',
                        value: '6550688903534215188',
                    },
                ],
            },
            {
                label: '商洛市',
                value: '6550688903534215189',
                children: [
                    {
                        label: '商州区',
                        value: '6550688903534215190',
                    },
                    {
                        label: '洛南县',
                        value: '6550688903534215191',
                    },
                    {
                        label: '丹凤县',
                        value: '6550688903534215192',
                    },
                    {
                        label: '商南县',
                        value: '6550688903534215193',
                    },
                    {
                        label: '山阳县',
                        value: '6550688903534215194',
                    },
                    {
                        label: '镇安县',
                        value: '6550688903534215195',
                    },
                    {
                        label: '柞水县',
                        value: '6550688903534215196',
                    },
                ],
            },
            {
                label: '榆林市',
                value: '6550688903534215197',
                children: [
                    {
                        label: '榆阳区',
                        value: '6550688903534215198',
                    },
                    {
                        label: '横山区',
                        value: '6550688903538409472',
                    },
                    {
                        label: '神木县',
                        value: '6550688903538409473',
                    },
                    {
                        label: '府谷县',
                        value: '6550688903538409474',
                    },
                    {
                        label: '靖边县',
                        value: '6550688903538409475',
                    },
                    {
                        label: '定边县',
                        value: '6550688903538409476',
                    },
                    {
                        label: '绥德县',
                        value: '6550688903538409477',
                    },
                    {
                        label: '米脂县',
                        value: '6550688903538409478',
                    },
                    {
                        label: '佳县',
                        value: '6550688903538409479',
                    },
                    {
                        label: '吴堡县',
                        value: '6550688903538409480',
                    },
                    {
                        label: '清涧县',
                        value: '6550688903538409481',
                    },
                    {
                        label: '子洲县',
                        value: '6550688903538409482',
                    },
                ],
            },
        ],
    },
    {
        label: '甘肃省',
        value: '6550688903538409483',
        children: [
            {
                label: '兰州市',
                value: '6550688903538409484',
                children: [
                    {
                        label: '城关区',
                        value: '6550688903538409485',
                    },
                    {
                        label: '七里河区',
                        value: '6550688903538409486',
                    },
                    {
                        label: '西固区',
                        value: '6550688903538409487',
                    },
                    {
                        label: '安宁区',
                        value: '6550688903538409488',
                    },
                    {
                        label: '红古区',
                        value: '6550688903538409489',
                    },
                    {
                        label: '永登县',
                        value: '6550688903538409490',
                    },
                    {
                        label: '皋兰县',
                        value: '6550688903538409491',
                    },
                    {
                        label: '榆中县',
                        value: '6550688903538409492',
                    },
                ],
            },
            {
                label: '嘉峪关市',
                value: '6550688903538409493',
                children: [
                    {
                        label: '嘉峪关市',
                        value: '6550688903538409494',
                    },
                ],
            },
            {
                label: '金昌市',
                value: '6550688903538409495',
                children: [
                    {
                        label: '金川区',
                        value: '6550688903538409496',
                    },
                    {
                        label: '永昌县',
                        value: '6550688903538409497',
                    },
                ],
            },
            {
                label: '白银市',
                value: '6550688903542603776',
                children: [
                    {
                        label: '白银区',
                        value: '6550688903542603777',
                    },
                    {
                        label: '平川区',
                        value: '6550688903542603778',
                    },
                    {
                        label: '靖远县',
                        value: '6550688903542603779',
                    },
                    {
                        label: '会宁县',
                        value: '6550688903542603780',
                    },
                    {
                        label: '景泰县',
                        value: '6550688903542603781',
                    },
                ],
            },
            {
                label: '天水市',
                value: '6550688903542603782',
                children: [
                    {
                        label: '秦州区',
                        value: '6550688903542603783',
                    },
                    {
                        label: '麦积区',
                        value: '6550688903542603784',
                    },
                    {
                        label: '清水县',
                        value: '6550688903542603785',
                    },
                    {
                        label: '秦安县',
                        value: '6550688903542603786',
                    },
                    {
                        label: '甘谷县',
                        value: '6550688903542603787',
                    },
                    {
                        label: '武山县',
                        value: '6550688903542603788',
                    },
                    {
                        label: '张家川回族自治县',
                        value: '6550688903542603789',
                    },
                ],
            },
            {
                label: '酒泉市',
                value: '6550688903542603790',
                children: [
                    {
                        label: '肃州区',
                        value: '6550688903542603791',
                    },
                    {
                        label: '玉门市',
                        value: '6550688903542603792',
                    },
                    {
                        label: '敦煌市',
                        value: '6550688903542603793',
                    },
                    {
                        label: '金塔县',
                        value: '6550688903542603794',
                    },
                    {
                        label: '肃北蒙古族自治县',
                        value: '6550688903542603795',
                    },
                    {
                        label: '阿克塞哈萨克族自治县',
                        value: '6550688903542603796',
                    },
                    {
                        label: '安西县',
                        value: '6550688903542603797',
                    },
                ],
            },
            {
                label: '张掖市',
                value: '6550688903542603798',
                children: [
                    {
                        label: '甘州区',
                        value: '6550688903542603799',
                    },
                    {
                        label: '肃南裕固族自治县',
                        value: '6550688903542603800',
                    },
                    {
                        label: '民乐县',
                        value: '6550688903542603801',
                    },
                    {
                        label: '临泽县',
                        value: '6550688903542603802',
                    },
                    {
                        label: '高台县',
                        value: '6550688903542603803',
                    },
                    {
                        label: '山丹县',
                        value: '6550688903542603804',
                    },
                ],
            },
            {
                label: '武威市',
                value: '6550688903546798080',
                children: [
                    {
                        label: '凉州区',
                        value: '6550688903546798081',
                    },
                    {
                        label: '民勤县',
                        value: '6550688903546798082',
                    },
                    {
                        label: '古浪县',
                        value: '6550688903546798083',
                    },
                    {
                        label: '天祝藏族自治县',
                        value: '6550688903546798084',
                    },
                ],
            },
            {
                label: '定西市',
                value: '6550688903546798085',
                children: [
                    {
                        label: '安定区',
                        value: '6550688903546798086',
                    },
                    {
                        label: '通渭县',
                        value: '6550688903546798087',
                    },
                    {
                        label: '陇西县',
                        value: '6550688903546798088',
                    },
                    {
                        label: '渭源县',
                        value: '6550688903546798089',
                    },
                    {
                        label: '临洮县',
                        value: '6550688903546798090',
                    },
                    {
                        label: '漳县',
                        value: '6550688903546798091',
                    },
                    {
                        label: '岷县',
                        value: '6550688903546798092',
                    },
                ],
            },
            {
                label: '陇南市',
                value: '6550688903546798093',
                children: [
                    {
                        label: '武都区',
                        value: '6550688903546798094',
                    },
                    {
                        label: '宕昌县',
                        value: '6550688903546798095',
                    },
                    {
                        label: '成县',
                        value: '6550688903546798096',
                    },
                    {
                        label: '康县',
                        value: '6550688903546798097',
                    },
                    {
                        label: '文县',
                        value: '6550688903546798098',
                    },
                    {
                        label: '西和县',
                        value: '6550688903546798099',
                    },
                    {
                        label: '礼县',
                        value: '6550688903546798100',
                    },
                    {
                        label: '两当县',
                        value: '6550688903546798101',
                    },
                    {
                        label: '徽县',
                        value: '6550688903546798102',
                    },
                ],
            },
            {
                label: '平凉市',
                value: '6550688903546798103',
                children: [
                    {
                        label: '崆峒区',
                        value: '6550688903546798104',
                    },
                    {
                        label: '泾川县',
                        value: '6550688903546798105',
                    },
                    {
                        label: '灵台县',
                        value: '6550688903546798106',
                    },
                    {
                        label: '崇信县',
                        value: '6550688903546798107',
                    },
                    {
                        label: '华亭县',
                        value: '6550688903546798108',
                    },
                    {
                        label: '庄浪县',
                        value: '6550688903550992384',
                    },
                    {
                        label: '静宁县',
                        value: '6550688903550992385',
                    },
                ],
            },
            {
                label: '庆阳市',
                value: '6550688903550992386',
                children: [
                    {
                        label: '西峰区',
                        value: '6550688903550992387',
                    },
                    {
                        label: '庆城县',
                        value: '6550688903550992388',
                    },
                    {
                        label: '环县',
                        value: '6550688903550992389',
                    },
                    {
                        label: '华池县',
                        value: '6550688903550992390',
                    },
                    {
                        label: '合水县',
                        value: '6550688903550992391',
                    },
                    {
                        label: '正宁县',
                        value: '6550688903550992392',
                    },
                    {
                        label: '宁县',
                        value: '6550688903550992393',
                    },
                    {
                        label: '镇原县',
                        value: '6550688903550992394',
                    },
                ],
            },
            {
                label: '临夏回族自治州',
                value: '6550688903550992395',
                children: [
                    {
                        label: '临夏市',
                        value: '6550688903550992396',
                    },
                    {
                        label: '临夏县',
                        value: '6550688903550992397',
                    },
                    {
                        label: '康乐县',
                        value: '6550688903550992398',
                    },
                    {
                        label: '永靖县',
                        value: '6550688903550992399',
                    },
                    {
                        label: '广河县',
                        value: '6550688903550992400',
                    },
                    {
                        label: '和政县',
                        value: '6550688903550992401',
                    },
                    {
                        label: '东乡族自治县',
                        value: '6550688903550992402',
                    },
                    {
                        label: '积石山保安族东乡族撒拉族自治县',
                        value: '6550688903550992403',
                    },
                ],
            },
            {
                label: '甘南藏族自治州',
                value: '6550688903550992404',
                children: [
                    {
                        label: '合作市',
                        value: '6550688903550992405',
                    },
                    {
                        label: '临潭县',
                        value: '6550688903550992406',
                    },
                    {
                        label: '卓尼县',
                        value: '6550688903550992407',
                    },
                    {
                        label: '舟曲县',
                        value: '6550688903550992408',
                    },
                    {
                        label: '迭部县',
                        value: '6550688903550992409',
                    },
                    {
                        label: '玛曲县',
                        value: '6550688903550992410',
                    },
                    {
                        label: '碌曲县',
                        value: '6550688903550992411',
                    },
                    {
                        label: '夏河县',
                        value: '6550688903550992412',
                    },
                ],
            },
        ],
    },
    {
        label: '青海省',
        value: '6550688903550992413',
        children: [
            {
                label: '西宁市',
                value: '6550688903550992414',
                children: [
                    {
                        label: '城东区',
                        value: '6550688903555186688',
                    },
                    {
                        label: '城中区',
                        value: '6550688903555186689',
                    },
                    {
                        label: '城西区',
                        value: '6550688903555186690',
                    },
                    {
                        label: '城北区',
                        value: '6550688903555186691',
                    },
                    {
                        label: '湟中县',
                        value: '6550688903555186692',
                    },
                    {
                        label: '湟源县',
                        value: '6550688903555186693',
                    },
                    {
                        label: '大通回族土族自治县',
                        value: '6550688903555186694',
                    },
                ],
            },
            {
                label: '海东市',
                value: '6550688903555186695',
                children: [
                    {
                        label: '乐都区',
                        value: '6550688903555186696',
                    },
                    {
                        label: '平安县',
                        value: '6550688903555186697',
                    },
                    {
                        label: '民和回族土族自治县',
                        value: '6550688903555186698',
                    },
                    {
                        label: '互助土族自治县',
                        value: '6550688903555186699',
                    },
                    {
                        label: '化隆回族自治县',
                        value: '6550688903555186700',
                    },
                    {
                        label: '循化撒拉族自治县',
                        value: '6550688903555186701',
                    },
                ],
            },
            {
                label: '海北藏族自治州',
                value: '6550688903555186702',
                children: [
                    {
                        label: '门源回族自治县',
                        value: '6550688903555186703',
                    },
                    {
                        label: '祁连县',
                        value: '6550688903555186704',
                    },
                    {
                        label: '海晏县',
                        value: '6550688903555186705',
                    },
                    {
                        label: '刚察县',
                        value: '6550688903555186706',
                    },
                ],
            },
            {
                label: '黄南藏族自治州',
                value: '6550688903555186707',
                children: [
                    {
                        label: '同仁县',
                        value: '6550688903555186708',
                    },
                    {
                        label: '尖扎县',
                        value: '6550688903555186709',
                    },
                    {
                        label: '泽库县',
                        value: '6550688903555186710',
                    },
                    {
                        label: '河南蒙古族自治县',
                        value: '6550688903555186711',
                    },
                ],
            },
            {
                label: '海南藏族自治州',
                value: '6550688903555186712',
                children: [
                    {
                        label: '共和县',
                        value: '6550688903555186713',
                    },
                    {
                        label: '同德县',
                        value: '6550688903555186714',
                    },
                    {
                        label: '贵德县',
                        value: '6550688903555186715',
                    },
                    {
                        label: '兴海县',
                        value: '6550688903555186716',
                    },
                    {
                        label: '贵南县',
                        value: '6550688903555186717',
                    },
                ],
            },
            {
                label: '果洛藏族自治州',
                value: '6550688903555186718',
                children: [
                    {
                        label: '玛沁县',
                        value: '6550688903555186719',
                    },
                    {
                        label: '班玛县',
                        value: '6550688903559380992',
                    },
                    {
                        label: '甘德县',
                        value: '6550688903559380993',
                    },
                    {
                        label: '达日县',
                        value: '6550688903559380994',
                    },
                    {
                        label: '久治县',
                        value: '6550688903559380995',
                    },
                    {
                        label: '玛多县',
                        value: '6550688903559380996',
                    },
                ],
            },
            {
                label: '玉树藏族自治州',
                value: '6550688903559380997',
                children: [
                    {
                        label: '玉树市',
                        value: '6550688903559380998',
                    },
                    {
                        label: '杂多县',
                        value: '6550688903559380999',
                    },
                    {
                        label: '称多县',
                        value: '6550688903559381000',
                    },
                    {
                        label: '治多县',
                        value: '6550688903559381001',
                    },
                    {
                        label: '囊谦县',
                        value: '6550688903559381002',
                    },
                    {
                        label: '曲麻莱县',
                        value: '6550688903559381003',
                    },
                ],
            },
            {
                label: '海西蒙古族藏族自治州',
                value: '6550688903559381004',
                children: [
                    {
                        label: '格尔木市',
                        value: '6550688903559381005',
                    },
                    {
                        label: '德令哈市',
                        value: '6550688903559381006',
                    },
                    {
                        label: '乌兰县',
                        value: '6550688903559381007',
                    },
                    {
                        label: '都兰县',
                        value: '6550688903559381008',
                    },
                    {
                        label: '天峻县',
                        value: '6550688903559381009',
                    },
                ],
            },
        ],
    },
    {
        label: '宁夏',
        value: '6550688903563575296',
        children: [
            {
                label: '银川市',
                value: '6550688903563575297',
                children: [
                    {
                        label: '兴庆区',
                        value: '6550688903563575298',
                    },
                    {
                        label: '西夏区',
                        value: '6550688903563575299',
                    },
                    {
                        label: '金凤区',
                        value: '6550688903563575300',
                    },
                    {
                        label: '永宁县',
                        value: '6550688903563575301',
                    },
                    {
                        label: '贺兰县',
                        value: '6550688903563575302',
                    },
                    {
                        label: '灵武市',
                        value: '6550688903563575303',
                    },
                ],
            },
            {
                label: '石嘴山市',
                value: '6550688903563575304',
                children: [
                    {
                        label: '大武口区',
                        value: '6550688903563575305',
                    },
                    {
                        label: '惠农区',
                        value: '6550688903563575306',
                    },
                    {
                        label: '平罗县',
                        value: '6550688903563575307',
                    },
                ],
            },
            {
                label: '吴忠市',
                value: '6550688903563575308',
                children: [
                    {
                        label: '利通区',
                        value: '6550688903563575309',
                    },
                    {
                        label: '红寺堡区',
                        value: '6550688903563575310',
                    },
                    {
                        label: '盐池县',
                        value: '6550688903563575311',
                    },
                    {
                        label: '同心县',
                        value: '6550688903563575312',
                    },
                    {
                        label: '青铜峡市',
                        value: '6550688903563575313',
                    },
                ],
            },
            {
                label: '固原市',
                value: '6550688903563575314',
                children: [
                    {
                        label: '原州区',
                        value: '6550688903563575315',
                    },
                    {
                        label: '西吉县',
                        value: '6550688903563575316',
                    },
                    {
                        label: '隆德县',
                        value: '6550688903563575317',
                    },
                    {
                        label: '泾源县',
                        value: '6550688903563575318',
                    },
                    {
                        label: '彭阳县',
                        value: '6550688903563575319',
                    },
                ],
            },
            {
                label: '中卫市',
                value: '6550688903567769600',
                children: [
                    {
                        label: '沙坡头区',
                        value: '6550688903567769601',
                    },
                    {
                        label: '中宁县',
                        value: '6550688903567769602',
                    },
                    {
                        label: '海原县',
                        value: '6550688903567769603',
                    },
                ],
            },
        ],
    },
    {
        label: '新疆',
        value: '6550688903567769604',
        children: [
            {
                label: '乌鲁木齐市',
                value: '6550688903567769605',
                children: [
                    {
                        label: '天山区',
                        value: '6550688903567769606',
                    },
                    {
                        label: '沙依巴克区',
                        value: '6550688903567769607',
                    },
                    {
                        label: '新市区',
                        value: '6550688903567769608',
                    },
                    {
                        label: '水磨沟区',
                        value: '6550688903567769609',
                    },
                    {
                        label: '头屯河区',
                        value: '6550688903567769610',
                    },
                    {
                        label: '南山矿区',
                        value: '6550688903567769611',
                    },
                    {
                        label: '东山区',
                        value: '6550688903567769612',
                    },
                    {
                        label: '乌鲁木齐县',
                        value: '6550688903567769613',
                    },
                ],
            },
            {
                label: '克拉玛依市',
                value: '6550688903567769614',
                children: [
                    {
                        label: '独山子区',
                        value: '6550688903567769615',
                    },
                    {
                        label: '克拉玛依区',
                        value: '6550688903567769616',
                    },
                    {
                        label: '白碱滩区',
                        value: '6550688903567769617',
                    },
                    {
                        label: '乌尔禾区',
                        value: '6550688903567769618',
                    },
                ],
            },
            {
                label: '吐鲁番地区',
                value: '6550688903567769619',
                children: [
                    {
                        label: '吐鲁番市',
                        value: '6550688903567769620',
                    },
                    {
                        label: '鄯善县',
                        value: '6550688903567769621',
                    },
                    {
                        label: '托克逊县',
                        value: '6550688903567769622',
                    },
                ],
            },
            {
                label: '哈密地区',
                value: '6550688903567769623',
                children: [
                    {
                        label: '哈密市',
                        value: '6550688903567769624',
                    },
                    {
                        label: '巴里坤哈萨克自治县',
                        value: '6550688903567769625',
                    },
                    {
                        label: '伊吾县',
                        value: '6550688903567769626',
                    },
                ],
            },
            {
                label: '昌吉回族自治州',
                value: '6550688903571963904',
                children: [
                    {
                        label: '昌吉市',
                        value: '6550688903571963905',
                    },
                    {
                        label: '阜康市',
                        value: '6550688903571963906',
                    },
                    {
                        label: '呼图壁县',
                        value: '6550688903571963907',
                    },
                    {
                        label: '玛纳斯县',
                        value: '6550688903571963908',
                    },
                    {
                        label: '奇台县',
                        value: '6550688903571963909',
                    },
                    {
                        label: '吉木萨尔县',
                        value: '6550688903571963910',
                    },
                    {
                        label: '木垒哈萨克自治县',
                        value: '6550688903571963911',
                    },
                ],
            },
            {
                label: '博尔塔拉蒙古自治州',
                value: '6550688903571963912',
                children: [
                    {
                        label: '阿拉山口市',
                        value: '6550688903571963913',
                    },
                    {
                        label: '博乐市',
                        value: '6550688903571963914',
                    },
                    {
                        label: '精河县',
                        value: '6550688903571963915',
                    },
                    {
                        label: '温泉县',
                        value: '6550688903571963916',
                    },
                ],
            },
            {
                label: '巴音郭楞蒙古自治州',
                value: '6550688903571963917',
                children: [
                    {
                        label: '库尔勒市',
                        value: '6550688903571963918',
                    },
                    {
                        label: '轮台县',
                        value: '6550688903571963919',
                    },
                    {
                        label: '尉犁县',
                        value: '6550688903571963920',
                    },
                    {
                        label: '若羌县',
                        value: '6550688903571963921',
                    },
                    {
                        label: '且末县',
                        value: '6550688903571963922',
                    },
                    {
                        label: '焉耆回族自治县',
                        value: '6550688903571963923',
                    },
                    {
                        label: '和静县',
                        value: '6550688903571963924',
                    },
                    {
                        label: '和硕县',
                        value: '6550688903571963925',
                    },
                    {
                        label: '博湖县',
                        value: '6550688903571963926',
                    },
                ],
            },
            {
                label: '阿克苏地区',
                value: '6550688903571963927',
                children: [
                    {
                        label: '阿克苏市',
                        value: '6550688903571963928',
                    },
                    {
                        label: '温宿县',
                        value: '6550688903571963929',
                    },
                    {
                        label: '库车县',
                        value: '6550688903571963930',
                    },
                    {
                        label: '沙雅县',
                        value: '6550688903571963931',
                    },
                    {
                        label: '新和县',
                        value: '6550688903571963932',
                    },
                    {
                        label: '拜城县',
                        value: '6550688903571963933',
                    },
                    {
                        label: '乌什县',
                        value: '6550688903576158208',
                    },
                    {
                        label: '阿瓦提县',
                        value: '6550688903576158209',
                    },
                    {
                        label: '柯坪县',
                        value: '6550688903576158210',
                    },
                ],
            },
            {
                label: '克孜勒苏柯尔克孜自治州',
                value: '6550688903576158211',
                children: [
                    {
                        label: '阿图什市',
                        value: '6550688903576158212',
                    },
                    {
                        label: '阿克陶县',
                        value: '6550688903576158213',
                    },
                    {
                        label: '阿合奇县',
                        value: '6550688903576158214',
                    },
                    {
                        label: '乌恰县',
                        value: '6550688903576158215',
                    },
                ],
            },
            {
                label: '喀什地区',
                value: '6550688903576158216',
                children: [
                    {
                        label: '喀什市',
                        value: '6550688903576158217',
                    },
                    {
                        label: '疏附县',
                        value: '6550688903576158218',
                    },
                    {
                        label: '疏勒县',
                        value: '6550688903576158219',
                    },
                    {
                        label: '英吉沙县',
                        value: '6550688903576158220',
                    },
                    {
                        label: '泽普县',
                        value: '6550688903576158221',
                    },
                    {
                        label: '莎车县',
                        value: '6550688903576158222',
                    },
                    {
                        label: '叶城县',
                        value: '6550688903576158223',
                    },
                    {
                        label: '麦盖提县',
                        value: '6550688903576158224',
                    },
                    {
                        label: '岳普湖县',
                        value: '6550688903576158225',
                    },
                    {
                        label: '伽师县',
                        value: '6550688903576158226',
                    },
                    {
                        label: '巴楚县',
                        value: '6550688903576158227',
                    },
                    {
                        label: '塔什库尔干塔吉克自治县',
                        value: '6550688903576158228',
                    },
                ],
            },
            {
                label: '和田地区',
                value: '6550688903576158229',
                children: [
                    {
                        label: '和田市',
                        value: '6550688903576158230',
                    },
                    {
                        label: '和田县',
                        value: '6550688903576158231',
                    },
                    {
                        label: '墨玉县',
                        value: '6550688903576158232',
                    },
                    {
                        label: '皮山县',
                        value: '6550688903576158233',
                    },
                    {
                        label: '洛浦县',
                        value: '6550688903576158234',
                    },
                    {
                        label: '策勒县',
                        value: '6550688903576158235',
                    },
                    {
                        label: '于田县',
                        value: '6550688903580352512',
                    },
                    {
                        label: '民丰县',
                        value: '6550688903580352513',
                    },
                ],
            },
            {
                label: '伊犁哈萨克自治州',
                value: '6550688903580352514',
                children: [
                    {
                        label: '奎屯市',
                        value: '6550688903580352515',
                    },
                    {
                        label: '霍尔果斯市',
                        value: '6550688903580352516',
                    },
                    {
                        label: '伊宁市',
                        value: '6550688903580352517',
                    },
                    {
                        label: '伊宁县',
                        value: '6550688903580352518',
                    },
                    {
                        label: '察布查尔锡伯自治县',
                        value: '6550688903580352519',
                    },
                    {
                        label: '霍城县',
                        value: '6550688903580352520',
                    },
                    {
                        label: '巩留县',
                        value: '6550688903580352521',
                    },
                    {
                        label: '新源县',
                        value: '6550688903580352522',
                    },
                    {
                        label: '昭苏县',
                        value: '6550688903580352523',
                    },
                    {
                        label: '特克斯县',
                        value: '6550688903580352524',
                    },
                    {
                        label: '尼勒克县',
                        value: '6550688903580352525',
                    },
                ],
            },
            {
                label: '塔城地区',
                value: '6550688903580352526',
                children: [
                    {
                        label: '塔城市',
                        value: '6550688903580352527',
                    },
                    {
                        label: '乌苏市',
                        value: '6550688903580352528',
                    },
                    {
                        label: '额敏县',
                        value: '6550688903580352529',
                    },
                    {
                        label: '沙湾县',
                        value: '6550688903580352530',
                    },
                    {
                        label: '托里县',
                        value: '6550688903584546816',
                    },
                    {
                        label: '裕民县',
                        value: '6550688903584546817',
                    },
                    {
                        label: '和布克赛尔蒙古自治县',
                        value: '6550688903584546818',
                    },
                ],
            },
            {
                label: '阿勒泰地区',
                value: '6550688903584546819',
                children: [
                    {
                        label: '阿勒泰市',
                        value: '6550688903584546820',
                    },
                    {
                        label: '布尔津县',
                        value: '6550688903584546821',
                    },
                    {
                        label: '富蕴县',
                        value: '6550688903584546822',
                    },
                    {
                        label: '福海县',
                        value: '6550688903584546823',
                    },
                    {
                        label: '哈巴河县',
                        value: '6550688903584546824',
                    },
                    {
                        label: '青河县',
                        value: '6550688903584546825',
                    },
                    {
                        label: '吉木乃县',
                        value: '6550688903584546826',
                    },
                ],
            },
            {
                label: '省直辖行政单位',
                value: '6550688903584546827',
                children: [
                    {
                        label: '石河子市',
                        value: '6550688903584546828',
                    },
                    {
                        label: '阿拉尔市',
                        value: '6550688903584546829',
                    },
                    {
                        label: '图木舒克市',
                        value: '6550688903584546830',
                    },
                    {
                        label: '北屯市',
                        value: '6550688903584546831',
                    },
                    {
                        label: '铁门关市',
                        value: '6550688903584546832',
                    },
                    {
                        label: '双河市',
                        value: '6550688903584546833',
                    },
                    {
                        label: '五家渠市',
                        value: '6550688903584546834',
                    },
                ],
            },
        ],
    },
    {
        label: '台湾省',
        value: '6550688903584546835',
        children: [
            {
                label: '台北市',
                value: '6550688903584546836',
                children: [],
            },
            {
                label: '高雄市',
                value: '6550688903588741121',
                children: [],
            },
            {
                label: '基隆市',
                value: '6550688903588741123',
                children: [],
            },
            {
                label: '台中市',
                value: '6550688903588741125',
                children: [],
            },
            {
                label: '台南市',
                value: '6550688903588741127',
                children: [],
            },
            {
                label: '新竹市',
                value: '6550688903588741129',
                children: [],
            },
            {
                label: '嘉义市',
                value: '6550688903588741131',
                children: [],
            },
            {
                label: '新北市',
                value: '6550688903588741133',
                children: [],
            },
            {
                label: '宜兰县',
                value: '6550688903588741135',
                children: [],
            },
            {
                label: '桃园县',
                value: '6550688903588741137',
                children: [],
            },
            {
                label: '新竹县',
                value: '6550688903588741139',
                children: [],
            },
            {
                label: '苗栗县',
                value: '6550688903592935424',
                children: [],
            },
            {
                label: '彰化县',
                value: '6550688903592935426',
                children: [],
            },
            {
                label: '南投县',
                value: '6550688903592935428',
                children: [],
            },
            {
                label: '云林县',
                value: '6550688903592935430',
                children: [],
            },
            {
                label: '嘉义县',
                value: '6550688903592935432',
                children: [],
            },
            {
                label: '屏东县',
                value: '6550688903592935434',
                children: [],
            },
            {
                label: '台东县',
                value: '6550688903592935436',
                children: [],
            },
            {
                label: '花莲县',
                value: '6550688903592935438',
                children: [],
            },
            {
                label: '澎湖县',
                value: '6550688903592935440',
                children: [],
            },
        ],
    },
    {
        label: '香港特别行政自治区',
        value: '6550688903592935442',
        children: [
            {
                label: '澎湖县',
                value: '6550688903592935443',
                children: [],
            },
        ],
    },
    {
        label: '澳门特别行政自治区',
        value: '6550688903592935445',
        children: [
            {
                label: '澎湖县',
                value: '6550688903592935446',
                children: [],
            },
        ],
    },
];
export {cityData};
