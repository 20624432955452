import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60bde455"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ExtensionImages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanImage = _resolveComponent("VanImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.src)
      ? (_openBlock(), _createBlock(_component_VanImage, {
          key: 0,
          src: $setup.src,
          width: "100vw",
          height: "100vh",
          fit: "fill"
        }, null, 8, ["src"]))
      : _createCommentVNode("", true)
  ]))
}