import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/style/style.less';
import Vant from 'vant';
import moment from 'moment';
import VueVideoPlayer from '@videojs-player/vue';
import 'video.js/dist/video-js.css';
import {getWechatSDKSign} from '@/api/miscellaneous';
import VueWechatTitle from 'vue-wechat-title';
moment.locale('zh-cn');
getWechatSDKSign({url: location.href.split('#')[0]}).then(res => {
    const {appId, timestamp, nonceStr, signature} = res;
    wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ['chooseImage', 'previewImage', 'updateTimelineShareData', 'updateAppMessageShareData'], // 必填，需要使用的 JS 接口列表
        openTagList: ['wx-open-launch-weapp', 'wx-open-launch-app', 'wx-open-subscribe', 'wx-open-audio'], // 可选，需要使用的开放标签列表
    });
    createApp(App).use(store).use(Vant).use(router).use(VueVideoPlayer).use(VueWechatTitle).mount('#app');
});
