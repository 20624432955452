
import {Options, Vue} from 'vue-class-component';
import areaProvince, {AreaProvinceItem} from '@/libs/area_province';
import {Dialog} from 'vant';
import {Toast} from 'vant';
import {login, getSetting, setting, register, ckeckedVariety, searchCategories, getSupplyHotList} from '@/api/miscellaneous';
import userStoreModule from '@/store/modules/user';
import appStoreModule from '@/store/modules/app';
import {useRoute, useRouter} from 'vue-router';

@Options({
    name: 'Register',
    components: {[Dialog.Component.name]: Dialog.Component},
})
export default class Register extends Vue {
    private returnUrl: any = '';
    private route = useRoute();
    private router = useRouter();
    private areaList = areaProvince;
    private isAreaShow = false;
    private isAddVarietyShow = false;
    private saveData: any = {
        categoryTitle: {},
        encrypt: '',
        mobile: '',
        nickName: '',
        receivePurchase: true,
        receiveProcess: true,
        regional: {},
        webOpenId: '',
    };
    private searchData = {
        name: '',
        pageNum: 1,
        pageSize: 20,
    };
    private loading = false;
    private finished = false;
    private searchVarietyList: any[] = [];
    private commonVarietyList: any[] = appStoreModule.varietyList;
    private varietyName = '';
    private regionalName: string[] = ['全国'];
    private varietyList: any[] = [];

    private hotList: any[] = [];

    private isRegister = false;

    public beforeMount(): void {
        this.returnUrl = this.route.query.returnUrl;
        //console.log(this.route.query.returnUrl, '--routename');
        this.isRegister = true;
        this.getSetting();
        this.getHotList();
    }
    beforeCreate() {
        appStoreModule.init();
        this.commonVarietyList = appStoreModule.varietyList;
    }

    private getHotList() {
        getSupplyHotList().then((res: any) => {
            this.hotList = res;
        });
    }

    /**
     * 地区Picker确认选择
     * */
    private confirmArea(val: AreaProvinceItem): void {
        if (val.id == '0') {
            // this.saveData.regional = ['0'];
            // this.regionalName = ['全国'];
            this.saveData.regional = {[val.id]: val.text};
        } else {
            // const index = this.saveData.regional.indexOf('0');
            // if (index > -1) {
            //     this.saveData.regional.splice(index, 1);
            //     this.regionalName.splice(index, 1);
            // }
            // this.saveData.regional.push(val.id);
            // this.regionalName.push(val.text);
            delete this.saveData.regional['0'];
            this.saveData.regional[val.id] = val.text;
        }
        this.isAreaShow = false;
    }

    /**
     * 清理单项地区
     * */
    private clearRegional(key: string): void {
        // this.saveData.regional.splice(index, 1);
        // this.regionalName.splice(index, 1);
        delete this.saveData.regional[key];
        if (Object.keys(this.saveData.regional).length == 0) {
            this.saveData.regional = {'0': `全国`};
        }
    }

    /**
     * 清理单项品种
     * */
    private clearVariety(key: string): void {
        // this.saveData.categoryTitle.splice(index, 1);
        delete this.saveData.categoryTitle[key];
    }
    /**
     * 获取设置数据
     * */
    private getSetting(): void {
        let token = userStoreModule.token;
        getSetting({token}).then(res => {
            let {user, categoryTitle, regional, receivePurchase, receiveProcess} = res;
            let {mobile, nickName} = user;
            this.saveData.nickName = nickName;
            this.saveData.mobile = mobile;
            this.saveData.mobile = mobile;
            // this.saveData.categoryTitle = Object.values(categoryTitle);
            this.saveData.categoryTitle = categoryTitle;
            this.saveData.receivePurchase = this.$route.query.needPush === '2' ? false : receivePurchase;
            this.saveData.receiveProcess = receiveProcess;
            if (this.$route.query.needPush === '2') {
                Toast({
                    message: '请点击保存按钮，完成消息退订',
                    duration: 3000,
                });
            }
            this.saveData.regional = regional;
        });
    }

    /**
     * 提交保存
     * */
    private save(): void {
        if (this.isRegister) {
            this.setting();
        } else {
            this.register();
        }
    }

    /**
     * 提交设置
     * */
    private setting(): void {
        const {mobile, nickName, receivePurchase, categoryTitle, regional, receiveProcess} = this.saveData;
        const token = userStoreModule.token;
        setting({mobile, nickName, receivePurchase, categoryTitle, regional, token, receiveProcess}).then(res => {
            Toast.success('保存成功');
            if (this.returnUrl == '') {
                setTimeout(() => {
                    //  @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    window.WeixinJSBridge.call('closeWindow');
                    // Cookie.remove('token');
                    // Cookie.remove('encrypt');
                    // Toast('清除成功');
                }, 1500);
            } else {
                this.router.push({name: this.returnUrl});
            }
        });
    }
    /**
     * 提交注册
     * */
    private register(): void {
        register(this.saveData).then(res => {
            let {token} = res;
            this.isRegister = true;
            userStoreModule.setToken(token);
            Toast.success('绑定成功');
            if (this.returnUrl == '') {
                setTimeout(() => {
                    //@ts-ignore
                    window.WeixinJSBridge.call('closeWindow');
                }, 1500);
            } else {
                this.router.push({name: this.returnUrl});
            }
        });
    }

    private timer = NaN;
    private searchVariety(): void {
        if (this.timer) clearTimeout(this.timer);
        this.loading = true;
        this.timer = setTimeout(() => {
            this.searchData.pageNum = 1;
            if (this.searchData.name) {
                searchCategories(this.searchData).then((res: any) => {
                    this.loading = false;
                    this.searchVarietyList = res.items;
                });
            }
        }, 500);
    }
    private choseVariety(item: any): void {
        item.name = item.name ?? item.categoryName;
        item.id = item.id ?? item.categoryId;
        appStoreModule.setVariety({data: item, type: 'varietyList'});
        this.commonVarietyList = appStoreModule.varietyList;
        this.searchVarietyList = [];
        this.saveData.categoryTitle[item.id] = item.name;
        this.searchData.name = '';
        // this.isAddVarietyShow = false;
    }

    private page(): void {
        if (this.loading) return;
        this.loading = true;
        this.searchData.pageNum += 1;
        searchCategories(this.searchData).then((res: any) => {
            this.searchVarietyList = [...this.searchVarietyList, ...res.items];
            this.loading = false;
            this.finished = res.count == 0 ? true : this.searchData.pageNum * this.searchData.pageSize > res.totalCount;
        });
    }
}
