import Validator from '@/libs/validator';

export const quotedPriceParams = new Validator({
    price: {
        name: '报价价格',
        rule: {
            type: 'string',
            required: true,
        },
    },
    areaId: {
        name: '地址ID',
        rule: {
            type: 'string',
            required: true,
        },
    },
    address: {
        name: '地址',
        rule: {
            type: 'string',
            required: true,
        },
    },
    // photos: {
    //     name: '图片',
    //     rule: {
    //         type: 'array',
    //         required: true,
    //     },
    // },
});

export const supplyData = new Validator({
    categoryId: {
        name: '品种',
        rule: {
            type: 'string',
            required: true,
        },
    },
    quantity: {
        name: '数量',
        rule: {
            type: 'number',
            required: true,
        },
    },
    address: {
        name: '地址',
        rule: {
            type: 'string',
            required: true,
        },
    },
    areaId: {
        name: '地址Code',
        rule: {
            type: 'string',
            required: true,
        },
    },
});

export const publishSingleDataValidator = new Validator({
    title: {name: '品种名称', rule: {type: 'string', required: true}},
    quantity: {name: '采购数量', rule: {type: 'string', required: true}},
    receiptAreaId: {name: '地址Code', rule: {type: 'string', required: true}},
    receiptAddress: {name: '收货地址', rule: {type: 'string', required: true}},
});

export const publishProcessingValidator = new Validator({
    categoryId: {name: '品种', rule: {type: 'number', required: true}},
    profile: {name: '详细说明', rule: {type: 'string', required: true}},
    areaId: {name: '地址Code', rule: {type: 'string', required: true}},
    address: {name: '地址', rule: {type: 'array', required: true}},
});

export interface ReleaseEngineer {
    companyDisplay: boolean;
    title: string;
    company: string;
    profile: string;
    receiptAreaId: string;
    receiptAddress: string;
    receiptLocation: string;
    expires: string;
    invoiceType: string;
    documentUrl: string;
    requirePrice: boolean;
    requirePhotos: boolean;
    requireAddress: boolean;
    payTerm: string;
}
export const releaseEngineerValidator = new Validator({
    title: {name: '项目名称', rule: {type: 'string', required: true}},
    company: {name: '采购单位', rule: {type: 'string', required: true}},
    receiptAreaId: {name: '地址Code', rule: {type: 'string', required: true}},
    receiptAddress: {name: '地址', rule: {type: 'string', required: true}},
    expires: {name: '截至时间', rule: {type: 'string', required: true}},
});

export const releaseWantToBuyValidator = new Validator({
    content: {name: '复制文案', rule: {type: 'string', required: true}},
    userId: {name: '用户', rule: {type: 'string', required: true}},
});
