import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-074d281c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CityTreeSelector" }
const _hoisted_2 = { class: "city" }
const _hoisted_3 = { class: "city__title" }
const _hoisted_4 = { class: "city__list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "area" }
const _hoisted_7 = { class: "area__title" }
const _hoisted_8 = { class: "area__list" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanTreeSelect = _resolveComponent("VanTreeSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VanTreeSelect, {
      items: $setup.cityList,
      "active-id": $setup.props.modelValue,
      "onUpdate:active-id": _cache[2] || (_cache[2] = ($event: any) => (($setup.props.modelValue) = $event)),
      "main-active-index": $setup.cityTreeIndex,
      "onUpdate:main-active-index": _cache[3] || (_cache[3] = ($event: any) => (($setup.cityTreeIndex) = $event)),
      onClickNav: $setup.clickNav
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.cityList[$setup.cityTreeIndex].text), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "city__list-item",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.setArea($setup.cityList[$setup.cityTreeIndex].id)))
            }, "全省"),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cityList[$setup.cityTreeIndex].children, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["city__list-item", $setup.cityTreeCityIndex == index?"active":""]),
                key: index,
                onClick: ($event: any) => ($setup.cityTreeCityIndex = index)
              }, _toDisplayString(item.text), 11, _hoisted_5))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.cityList[$setup.cityTreeIndex].children[$setup.cityTreeCityIndex].text), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              class: "area__list-item",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.setArea($setup.cityList[$setup.cityTreeIndex].children[$setup.cityTreeCityIndex].id)))
            }, "全市"),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cityList[$setup.cityTreeIndex].children[$setup.cityTreeCityIndex].children, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "area__list-item",
                key: `area${index}`,
                onClick: ($event: any) => ($setup.setArea(item.id))
              }, _toDisplayString(item.text), 9, _hoisted_9))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["items", "active-id", "main-active-index"])
  ]))
}