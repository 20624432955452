import {ref, unref, Ref} from 'vue';

export interface TurnPagesParams {
    api: (data: any) => Promise<any>;
    params?: Ref<any>;
    handleData?: (data: any) => any;
    loadingReturn?: boolean;
}
export const turnPages = (data: TurnPagesParams) => {
    const {api, params} = data;
    const getListData = ref({
        page: 1,
        size: 10,
    });
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);

    /**
     * @description 加载数据 & 加载更多
     * */
    const loadMore = () => {
        if (loading.value && data.loadingReturn) return;
        loading.value = true;
        const dataParams = {...getListData.value, ...(params?.value || {})}; // 合并参数
        api(dataParams).then(res => {
            loading.value = false;
            getListData.value.page = getListData.value.page + 1;
            finished.value = res.totalCount == 0 ? true : dataParams.page * dataParams.size > res.totalCount;
            if (data.handleData) {
                list.value = list.value.concat(data.handleData(res));
            } else {
                list.value = list.value.concat(res.items);
            }
        });
    };

    /**
     * @description 从新加载
     * @param {object} data 重新加载的参数
     * */
    const refresh = (data: any = {}) => {
        getListData.value = {
            ...(params?.value || {}),
            ...{
                page: 1,
                size: 10,
            },
            ...data,
        };
        finished.value = false;
        list.value = [];
        loadMore();
    };

    return {
        list,
        loading,
        finished,
        loadMore,
        refresh,
    };
};
