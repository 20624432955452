
import {Options, Vue} from 'vue-class-component';
import {Toast} from 'vant';
import {getShortUrlSecheme, getWechatAppUrl} from '@/api/miscellaneous';

@Options({
    name: 'JumpDirectlyWeapp',
})
export default class JumpDirectlyWeapp extends Vue {
    public beforeMount(): void {
        let {url, pagePath, pathQuery} = this.$route.query;
        if (url) {
            Toast.loading('正在跳转苗搭档小程序');
            window.location.href = url as string;
        } else if (pagePath) {
            getWechatAppUrl({
                url: pagePath as string,
                query: (pathQuery || ``) as string,
            }).then(res => {
                location.href = JSON.parse(res).openlink;
            });
        } else {
            getShortUrlSecheme().then(res => {
                const {openlink} = JSON.parse(res as string);
                Toast.loading('正在跳转苗搭档小程序');
                window.location.href = openlink;
            });
        }
    }
}
