import {Module, VuexModule, Mutation, getModule, Action} from 'vuex-module-decorators';
import store from '@/store';
import {getToken, setToken} from '@/libs/utils';

@Module({name: 'app', store, dynamic: true})
class App extends VuexModule {
    public permission = {};
    public varietyList: any[] = [];
    public history: any[] = [];
    @Mutation
    init() {
        this.varietyList = JSON.parse(localStorage.getItem('varietyList') || `[]`);
        this.history = JSON.parse(localStorage.getItem('history') || `[]`);
    }

    @Mutation
    setPermission(permission: any) {
        this.permission = permission;
    }

    @Mutation
    setVariety(data: {data: any; type: string}) {
        const {data: variety, type} = data;
        const storageList = localStorage.getItem(type);
        if (storageList) {
            const list = JSON.parse(storageList);
            const doesItExist = list.findIndex((el: any) => el.name == variety.name);
            if (doesItExist > -1) {
                list.splice(doesItExist, 1);
                list.unshift(variety);
                localStorage.setItem(type, JSON.stringify(list));
                // @ts-ignore
                this[type] = list;
            } else {
                if (list.length > 10) {
                    const newList = list.slice(0, 9);
                    newList.unshift(variety);
                    localStorage.setItem(type, JSON.stringify(newList));
                    // @ts-ignore
                    this[type] = newList;
                } else {
                    list.unshift(variety);
                    localStorage.setItem(type, JSON.stringify(list));
                    // @ts-ignore
                    this[type] = list;
                }
            }
        } else {
            localStorage.setItem(type, JSON.stringify([variety]));
            this.varietyList = [variety];
        }
    }
}
const appStoreModule = getModule(App);
export default appStoreModule;
