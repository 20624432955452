import Home from '@/views/Home.vue';
import JumpToWeapp from '@/views/JumpToWeapp.vue';
import Register from '@/views/Register.vue';
import JumpDirectlyWeapp from '@/views/JumpDirectlyWeapp.vue';
import Customer from '@/views/Customer.vue';
import {RouteRecordRaw} from 'vue-router';
import ProductList from '@/views/ProductList.vue';
import BuyDetail from '@/views/BuyDetail.vue';
import DealWithDetail from '@/views/DealWithDetail.vue';
import BargainPrice from '@/views/BargainPrice.vue';
import QuotedPrice from '@/views/QuotedPrice.vue';
import QuotedPriceList from '@/views/QuotedPriceList.vue';
import QuotedDetail from '@/views/QuotedDetail.vue';
import EngineeringDetail from '@/views/EngineeringDetail.vue';
import EngineeringList from '@/views/EngineeringList.vue';
import ReleaseSupply from '@/views/ReleaseSupply/ReleaseSupply.vue';
import Report from '@/views/Report.vue';
import Result from '@/views/Result.vue';
import WantToBuy from '@/views/WantToBuy.vue';
import ProcessingList from '@/views/ProcessingList.vue';
import PushProcessingList from '@/views/PushProcessingList.vue';
import PushProcessingDetail from '@/views/PushProcessingDetail.vue';
import RechargeSeedlingBean from '@/views/RechargeSeedlingBean.vue';
import OpenMembership from '@/views/OpenMembership.vue';
import SupplyList from '@/views/SupplyList/SupplyList.vue';
import SupplyDetail from '@/views/SupplyDetail.vue';
// import Mine from '@/views/Mine.vue';
import My from '@/views/My.vue';
import GetCode from '@/views/GetCode.vue';
import SellerShop from '@/views/SellerShop.vue';
import ReleaseWantToBuy from '@/views/ReleaseWantToBuy.vue';
import RecommendedToday from '@/views/RecommendedToday.vue';
import MyOfferPriceList from '@/views/MyOfferPriceList.vue';
import MyWantToBuyList from '@/views/MyWantToBuyList.vue';
import QuotationOfWantToBuy from '@/views/QuotationOfWantToBuy.vue';
import ExtensionImages from '@/views/ExtensionImages.vue';
import BusinessOpportunity from '@/views/BusinessOpportunity/BusinessOpportunity.vue';
import MySupplyList from '@/views/MySupplyList.vue';
import ReleaseProcessing from '@/views/ReleaseProcessing.vue';
import ReleaseEngineering from '@/views/ReleaseEngineering.vue';
import Questionnaire from '@/views/Questionnaire.vue';
import Agreement from '@/views/Agreement.vue';
import QuoteInformation from '@/views/QuoteInformation.vue';
import CheckIn from '@/views/CheckIn.vue';
import MiaodouRule from '@/views/MiaodouRule.vue';
import Forwarding from '@/views/Forwarding.vue';
import ThenStore from '@/views/ThenStore.vue';
import EditShop from '@/views/EditShop.vue';
import About from '@/views/About.vue';
import t from '@/views/t.vue';
import down from '@/views/down.vue';
import ProcessDetail from "@/views/ProcessDetail.vue";
import RealName from "@/views/ReaLName.vue";

export const routers: RouteRecordRaw[] = [
    {
        path: '/',
        // name: 'home',
        component: Home,
        meta: {
            title: '苗搭档',
            isLogin: true,
        },
        // redirect: 'JumpToWeapp',
        beforeEnter(t, f, n) {
            /**
             * 处理原项目分享链接的井号
             * */
            if (/#/.test(t.hash)) {
                const path = t.hash.replace(/#/, '');
                const query = /\?/.test(t.hash)
                    ? Object.fromEntries(
                          t.hash
                              .split('?')[1]
                              .split('&')
                              .map(el => el.split('=')),
                      )
                    : {};
                n({path, query});
            } else {
                n();
            }
        },
    },
    {
        path: '/supply_list',
        redirect: 'SupplyList',
    },
    {
        path: '/seek_list',
        redirect: 'WantToBuy',
    },
    {
        path: '/goxcx',
        redirect: 'JumpToWeapp',
    },
    {
        path: '/enginerr',
        redirect: 'EngineeringDetail',
    },
    {
        path: '/cooperate_list',
        redirect: 'EngineeringList',
    },
    {
        path: '/all_down',
        redirect: 'JumpToWeapp?pageType=app',
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页',
            isShowKefu: true,
        },
    },
    {
        path: '/JumpToWeapp',
        name: 'JumpToWeapp',
        component: JumpToWeapp,
        meta: {
            title: '跳转小程序',
            isHideTab: true,
        },
    },
    {
        path: '/t',
        name: 't',
        component: t,
        meta: {
            title: '短信跳转到APP',
            isHideTab: true,
        },
    },
    {
        path: '/down',
        name: 'down',
        component: down,
        meta: {
            title: '直接短信跳转到APP',
            isHideTab: true,
        },
    },
    {
        path: '/Register',
        name: 'Register',
        component: Register,
        meta: {
            title: '订阅',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/JumpDirectlyWeapp',
        name: 'JumpDirectlyWeapp',
        component: JumpDirectlyWeapp,
        meta: {
            title: '直接跳转小程序',
            isHideTab: true,
        },
    },
    {
        path: '/Customer',
        name: 'Customer',
        component: Customer,
        meta: {
            title: '二维码',
            isHideTab: true,
        },
    },
    {
        path: '/BuyDetail',
        name: 'BuyDetail',
        component: BuyDetail,
        meta: {
            title: '求购详情',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/DealWithDetail',
        name: 'DealWithDetail',
        component: DealWithDetail,
        meta: {
            title: '处理详情',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/ProcessDetail',
        name: 'ProcessDetail',
        component: ProcessDetail,
        meta: {
            title: '处理详情',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/BargainPrice',
        name: 'BargainPrice',
        component: BargainPrice,
        meta: {
            title: '处理列表',
            isLogin: true,
        },
    },
    {
        path: '/QuotedPrice',
        name: 'QuotedPrice',
        component: QuotedPrice,
        meta: {
            title: '报价',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/QuotedPriceList',
        name: 'QuotedPriceList',
        component: QuotedPriceList,
        meta: {
            title: '求购列表',
            isLogin: true,
            keepAlive: true,
            isSignIn: true,
            isShowKefu: true,
        },
    },
    {
        path: '/ThenStore',
        name: 'ThenStore',
        component: ThenStore,
        meta: {
            title: '我的苗圃',
            isLogin: true,
            keepAlive: true,
            isSignIn: true,
            isShowKefu: false,
        },
    },

    {
        path: '/ProductList',
        name: 'ProductList',
        component: ProductList,
        meta: {
            title: '求购列表',
            isLogin: true,
            keepAlive: true,
            isHideTab: true,
        },
    },
    {
        path: '/QuotedDetail',
        name: 'QuotedDetail',
        component: QuotedDetail,
        meta: {
            title: '求购详情',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/EngineeringDetail',
        name: 'EngineeringDetail',
        component: EngineeringDetail,
        meta: {
            title: '工程详情',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/EngineeringList',
        name: 'EngineeringList',
        component: EngineeringList,
        meta: {
            title: '工程单列表',
            isLogin: true,
            keepAlive: true,
            isShowKefu: true,
        },
    },
    {
        path: '/ReleaseSupply',
        name: 'ReleaseSupply',
        component: ReleaseSupply,
        meta: {
            title: '发布供应',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/Report',
        name: 'Report',
        component: Report,
        meta: {
            title: '举报',
            isLogin: true,
        },
    },
    {
        path: '/Result',
        name: 'Result',
        component: Result,
        meta: {
            title: '结果',
            isHideTab: true,
        },
    },
    {
        path: '/WantToBuy',
        name: 'WantToBuy',
        component: WantToBuy,
        meta: {
            title: '求购列表',
            isLogin: true,
            // keepAlive: true,
            isShowKefu: true,
        },
    },
    {
        path: '/ProcessingList',
        name: 'ProcessingList',
        component: ProcessingList,
        meta: {
            title: '处理列表',
            isLogin: true,
            keepAlive: true,
            isShowKefu: true,
        },
    },
    {
        path: '/PushProcessingList',
        name: 'PushProcessingList',
        component: PushProcessingList,
        meta: {
            title: '推送处理列表',
            isLogin: true,
            keepAlive: true,
            isSignIn: true,
            isShowKefu: true,
        },
    },
    {
        path: '/PushProcessingDetail',
        name: 'PushProcessingDetail',
        component: PushProcessingDetail,
        meta: {
            title: '推送处理详情',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/RechargeSeedlingBean',
        name: 'RechargeSeedlingBean',
        component: RechargeSeedlingBean,
        meta: {
            title: '苗豆充值',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/OpenMembership',
        name: 'OpenMembership',
        component: OpenMembership,
        meta: {
            title: '开通会员',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/SupplyList',
        name: 'SupplyList',
        component: SupplyList,
        meta: {
            title: '供应列表',
            isLogin: true,
            keepAlive: true,
            isShowKefu: true,
        },
    },
    {
        path: '/RealName',
        name: 'RealName',
        component: RealName,
        meta: {
            title: '实名认证',
            isLogin: true,
            keepAlive: false,
            isShowKefu: false,
            isHideTab: true,
        },
    },
    {
        path: '/My',
        name: 'My',
        component: My,
        meta: {
            title: '我的',
            isLogin: true,
            isShowKefu: true,
        },
    },
    {
        path: '/SupplyDetail',
        name: 'SupplyDetail',
        component: SupplyDetail,
        meta: {
            title: '供应详情',
            isLogin: true,
            isHideTab: false,
        },
    },
    {
        path: '/GetCode',
        name: 'GetCode',
        component: GetCode,
        meta: {
            title: '获取Code',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/SellerShop',
        name: 'SellerShop',
        component: SellerShop,
        meta: {
            title: '商家店铺',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/ReleaseWantToBuy',
        name: 'ReleaseWantToBuy',
        component: ReleaseWantToBuy,
        meta: {
            title: '发布求购',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/RecommendedToday',
        name: 'RecommendedToday',
        component: RecommendedToday,
        meta: {
            title: '今日推荐',
            isLogin: true,
            keepAlive: true,
            isHideTab: false,
            isSignIn: true,
            isShowKefu: true,
        },
    },
    {
        path: '/MyOfferPriceList',
        name: 'MyOfferPriceList',
        component: MyOfferPriceList,
        meta: {
            title: '我的报价列表',
            isLogin: true,
        },
    },
    {
        path: '/MyWantToBuyList',
        name: 'MyWantToBuyList',
        component: MyWantToBuyList,
        meta: {
            title: '我的求购列表',
            isLogin: true,
        },
    },
    {
        path: '/QuotationOfWantToBuy',
        name: 'QuotationOfWantToBuy',
        component: QuotationOfWantToBuy,
        meta: {
            title: '我的求购报价',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/ExtensionImages',
        name: 'ExtensionImages',
        component: ExtensionImages,
        meta: {
            title: '推广图片',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/BusinessOpportunity',
        name: 'BusinessOpportunity',
        component: BusinessOpportunity,
        meta: {
            title: '我的商机',
            keepAlive: true,
            isLogin: true,
        },
    },
    {
        path: '/MySupplyList',
        name: 'MySupplyList',
        component: MySupplyList,
        meta: {
            title: '我的供应',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/ReleaseProcessing',
        name: 'ReleaseProcessing',
        component: ReleaseProcessing,
        meta: {
            title: '发布处理',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/ReleaseEngineering',
        name: 'ReleaseEngineering',
        component: ReleaseEngineering,
        meta: {
            title: '发布工程采购',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/Questionnaire',
        name: 'Questionnaire',
        component: Questionnaire,
        meta: {
            title: '问卷',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/Agreement',
        name: 'Agreement',
        component: Agreement,
        meta: {
            title: '协议',
            // isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/QuoteInformation',
        name: 'QuoteInformation',
        component: QuoteInformation,
        meta: {
            title: '报价单',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/CheckIn',
        name: 'CheckIn',
        component: CheckIn,
        meta: {
            title: '签到有礼',
            isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/MiaodouRule',
        name: 'MiaodouRule',
        component: MiaodouRule,
        meta: {
            title: '苗豆规则',
            // isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/Forwarding',
        name: 'Forwarding',
        component: Forwarding,
        meta: {
            title: 'Forwarding',
            // isLogin: true,
            isHideTab: true,
        },
    },
    {
        path: '/EditShop',
        name: 'EditShop',
        component: EditShop,
        meta: {
            title: '修改店铺',
            isHideTab: true,
        },
    },
    {
        path: '/About',
        name: 'About',
        component: About,
        meta: {
            title: '关于苗搭档',
            isHideTab: true,
        },
    },
    // {
    //     path: '/Mine',
    //     name: 'Mine',
    //     component: Mine,
    //     meta: {
    //         title: '求购列表',
    //         isLogin: true,
    //         keepAlive: true,
    //         // isHideTab: true,
    //     },
    // },
];
