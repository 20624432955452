
import {Options, Vue} from 'vue-class-component';
import {getDealWithList, login, getSetting} from '@/api/miscellaneous';
import config from '@/config';
import userStoreModule from '@/store/modules/user';
import moment from 'moment';

@Options({
    name: 'BargainPrice',
})
export default class BargainPrice extends Vue {
    private getListData = {
        page: 1,
        size: 20,
        areaId: '',
        keyword: '',
    };
    private list: any[] = [];
    private listLoading = false;
    private finished = false;
    private count = 0;
    private areaList: string[][] = [];
    mounted(): void {
        // this.getList();
        // if (userStoreModule.token) {
        this.getSetting();
        // } else {
        //     this.login();
        // }
    }

    /**
     * 已在路由守卫中判断登录
     * */
    private login(): void {
        let {code} = this.$route.query;
        if (!code) {
            /**
             * 微信登录Code授权
             * 重定向页面还是这个页面，通过$roter.query.code 判断是否是重定向回来的
             * */
            const redirectUri = encodeURIComponent(`${config.webHost}${window.location.pathname}`);
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.wechat.appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        } else {
            login({code: code as string}).then(res => {
                let {token, encrypt} = res;
                userStoreModule.setToken(token);
                userStoreModule.setEncrypt(encrypt);
                this.getSetting();
            });
        }
    }

    private getSetting(): void {
        let token = userStoreModule.token;
        getSetting({token}).then(res => {
            let {regional, categoryTitle} = res;
            this.areaList = Object.entries(regional);
            this.getList();
        });
    }

    private tabChange(key: string) {
        this.getListData.areaId = key;
        this.fliter();
    }

    private fliter(): void {
        this.list = [];
        this.getListData.page = 1;
        this.getList();
    }

    private getList(): void {
        if (this.listLoading) return;
        this.listLoading = true;
        getDealWithList(this.getListData).then(res => {
            this.listLoading = false;
            this.list = [...this.list, ...res.items];
            this.count = res.totalCount;
            this.getListData.page = this.getListData.page + 1;
            this.finished = res.count == 0 ? true : this.getListData.page * this.getListData.size > res.totalCount;
        });
    }

    private timeToNow(time: number): string {
        return moment(time).fromNow();
    }
}
