import {subscribeCategory} from '@/api/miscellaneous';
import {Dialog, Toast} from 'vant';
import userStoreModule from '@/store/modules/user';
import {ref} from 'vue';

export const listSubscribe = () => {
    const isSubscribe = ref(userStoreModule.userDetail.isSubscribe);
    return {
        subscribe: (id: string) => {
            subscribeCategory({id}).then(res => {
                Dialog.alert({
                    title: '提示',
                    message: '订阅该品种成功',
                });
            });
        },
        isSubscribe,
    };
};
