import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55d610e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ListItemAd" }
const _hoisted_2 = {
  key: 0,
  class: "itemAdd"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VanImage = _resolveComponent("VanImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Object.keys($setup.props.data).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_VanImage, {
            src: $setup.props.data.bgImage,
            radius: "4",
            style: {"width":"100%","height":"100%"},
            onClick: $setup.toSellerShop
          }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}